import React, { Fragment, useState } from "react";
import DoctorImage from "src/assets/icons/Doctor.png";
import Heard from "src/assets/icons/Heard.svg";
import "./Profile.scss";
import ProfileEditIcon from "src/assets/profile-edit.svg";
import changepic from "src/assets/change-profile-img.svg";
import mobiscroll from "@mobiscroll/react";
import { Input, Textarea } from "@mobiscroll/react";
import RightPanel from "./RightPanel";
const Profile = () => {
  const [editProfile, setEditProfile] = useState(false);
  const [profileImage, setProfileImage] = useState(DoctorImage);

  const data = {
    name: "Dr. Akul Singh",
    email: "akulsingh@docg.com",
    phone: "+918245678912",
    location: "765 Folosom Ave, Suit 600, San Francisco, Cadge 94107",
    speciality: "Neurologist",
    about:
      "Specialised in neuro critical care, autonomic disorders, child neurology.",
    experience: "18",
    award: "140",
    patients: "150",
  };

  // const [name,setName] = useState(data.name);
  // const [speciality, setSpeciality] = useState(data.speciality);

  const toggleEdit = () => {
    if (editProfile !== false) {
      setEditProfile(false);
    } else {
      setEditProfile(true);
    }
  };

  const profilePicture = (event) => {
    setProfileImage(URL.createObjectURL(event.target.files[0]));
  };

  return (
    <>
      <div className="mbsc-grid ml-4">
        <div className="mbsc-row">
          <div className="mbsc-col-lg-3 mbsc-col-md-4 mbsc-col-12 profile-left-panel docg-vh-standard">
            <div
              className="mbsc-row d-flex justify-content-center"
              style={{ position: "relative" }}
            >
              <div className="profile-img-wrap">
                <img alt="" className="profile-image" src={profileImage} />
                <br />
                <label
                  for="profile-picture-change"
                  className="profile-change-icon"
                >
                  <img alt="" src={changepic} />
                </label>
              </div>
              <input
                type="file"
                id="profile-picture-change"
                onChange={profilePicture}
              />
            </div>
            <div className="d-flex text-center profile-name-row ">
              {editProfile ? (
                <div className="profile-title d-flex text-center align-items-center justify-content-center">
                  <span>
                    <input
                      defaultValue={data.name}
                      id="profile-name-main"
                      className="input-profile-page text-center"
                    />
                    <button
                      className="editButton"
                      style={{ position: "absolute" }}
                      onClick={toggleEdit}
                    >
                      <img alt="" src={Heard} />
                    </button>
                  </span>
                </div>
              ) : (
                <div className="profile-title d-flex text-center align-items-center justify-content-center">
                  <span>
                    {data.name}
                    <button
                      className="editButton ml-3"
                      style={{ position: "absolute" }}
                      onClick={toggleEdit}
                    >
                      {editProfile ? (
                        <img alt="" src={Heard} />
                      ) : (
                        <img alt="" src={ProfileEditIcon} />
                      )}
                    </button>
                  </span>
                </div>
              )}
            </div>

            <div className="profile-left-panel-details">
              <div className="mbsc-row">
                <div className="mbsc-col-12">
                  {editProfile ? (
                    <input
                      defaultValue={data.speciality}
                      id="profile-name-main"
                      className="input-profile-page text-center"
                    />
                  ) : (
                    <div className="profile-speciality">{data.speciality}</div>
                  )}
                </div>
              </div>
              {editProfile ? (
                <mobiscroll.Textarea
                  defaultValue={data.about}
                  className="profile-about"
                ></mobiscroll.Textarea>
              ) : (
                <p className="profile-about">{data.about}</p>
              )}
              <div className="mbsc-row profile-record-card">
                <div className="mbsc-col-4 wrap">
                  {editProfile ? (
                    <Input defaultValue={data.experience} />
                  ) : (
                    <span>{data.experience}</span>
                  )}
                  <p>Exp.</p>
                </div>
                <div className="mbsc-col-4 wrap">
                  {editProfile ? (
                    <Input defaultValue={data.award} />
                  ) : (
                    <span>{data.award}</span>
                  )}

                  <p>Awards</p>
                </div>
                <div className="mbsc-col-4 wrap">
                  {editProfile ? (
                    <Input defaultValue={data.patients} />
                  ) : (
                    <span>{data.patients}</span>
                  )}

                  <p>Patients</p>
                </div>
              </div>
              <hr />
              <label className="label_style">Location:</label>
              {editProfile ? (
                <Textarea
                  defaultValue={data.location}
                  readOnly={!editProfile}
                  className="textarea_style"
                />
              ) : (
                <p className="textarea_style">{data.location}</p>
              )}
              <hr />
              <label className="label_style">Email:</label>

              {editProfile ? (
                <Input
                  defaultValue={data.email}
                  readOnly={!editProfile}
                  className="input-profile-page"
                />
              ) : (
                <p className="textarea_style">{data.email}</p>
              )}
              <hr />
              <label className="label_style">Phone:</label>
              <Input
                defaultValue={data.phone}
                readOnly={!editProfile}
                className="input-profile-page"
              />
            </div>
          </div>
          <div className="mbsc-col-lg-9 mbsc-col-md-8 mbsc-col-12">
            <RightPanel />
          </div>
        </div>
      </div>
    </>
  );
};

export default Profile;
