import React, { Fragment, useState } from "react";
import DoctorImage from "../../../assets/icons/Doctor.png";
import Heard from "../../../assets/icons/Heard.svg";
import "./profileRecord.scss";
import ProfileEditIcon from "../../../assets/profile-edit.svg";
import changepic from "../../../assets/change-profile-img.svg";
import mobiscroll from "@mobiscroll/react";
import { Input, Textarea } from "@mobiscroll/react";
import plus_icon from "../../../assets/plus-circle.svg";
import { Row, Col, Card, Button, Image, Tab, Tabs } from "react-bootstrap";
import { TabNav, NavItem } from "@mobiscroll/react";
import patient from "src/assets/person.svg";

const Profile = () => {
  const { Header: CardHeader, Body: CardBody, Title: CardTitle } = Card;
  const [editProfile, setEditProfile] = useState(false);
  const [profileImage, setProfileImage] = useState(DoctorImage);
  const [editAboutMe, setEditAboutMe] = useState(false);
  const [activeField, setActiveField] = useState(null);
  const [openPopup, setOpenPopup] = useState(false);
  const [placeholder, setPlaceholder] = useState("");
  const [newInputData, setNewInputData] = useState("");
  const [data, setData] = useState({});
  const [activeTab, setActiveTab] = useState("about");

  const profilePicture = (event) => {
    setProfileImage(URL.createObjectURL(event.target.files[0]));
  };

  const toggleEditAbout = () => {
    if (editAboutMe != false) {
      setEditAboutMe(false);
    } else {
      setEditAboutMe(true);
    }
  };

  const toggleEdit = () => {
    if (editProfile != false) {
      setEditProfile(false);
    } else {
      setEditProfile(true);
    }
  };

  const handleChange = (placeholder, field) => {
    if (openPopup == false) {
      setPlaceholder(placeholder);
      setOpenPopup(true);
      setActiveField(field);
      console.log(newInputData);
    }
  };
  return (
    <>
      <div className="mbsc-grid ml-4">
        <div className="mbsc-row">
          <div className="mbsc-col-lg-3 mbsc-col-md-4 mbsc-col-12 profile-left-panel docg-vh-standard">
            <div
              className="mbsc-row d-flex justify-content-center"
              style={{ position: "relative" }}
            >
              <div className="profile-img-wrap">
                <img className="profile-image" src={profileImage} />
                <br />
                <label
                  for="profile-picture-change"
                  className="profile-change-icon"
                >
                  <img src={changepic} />
                </label>
              </div>
              <input
                type="file"
                id="profile-picture-change"
                onChange={profilePicture}
              />
            </div>
            <div className="d-flex text-center profile-name-row ">
              {editProfile ? (
                <div className="profile-title d-flex text-center align-items-center justify-content-center">
                  <span style={{ display: "flex" }}>
                    <input
                      defaultValue="Lorem Ipsum"
                      id="profile-name-main"
                      className="input-profile-page text-center"
                    />
                    <button
                      className="editButton"
                      style={{ position: "absolute", right: "0", top: "0" }}
                      onClick={toggleEdit}
                    >
                      <img src={Heard} />
                    </button>
                  </span>
                </div>
              ) : (
                <div className="profile-title d-flex text-center align-items-center justify-content-center">
                  <span>
                    {data.name}
                    <button
                      className="editButton ml-3"
                      style={{ position: "absolute" }}
                      onClick={toggleEdit}
                    >
                      {editProfile ? (
                        <img src={Heard} />
                      ) : (
                        <img src={ProfileEditIcon} />
                      )}
                    </button>
                  </span>
                </div>
              )}
            </div>

            <div className="profile-left-panel-details">
              <div className="mbsc-row">
                <div className="mbsc-col-12">
                  {editProfile ? (
                    <input
                      defaultValue={null}
                      id="profile-name-main"
                      className="input-profile-page text-center"
                    />
                  ) : (
                    <div className="profile-speciality">Test</div>
                  )}
                </div>
              </div>
              {editProfile ? (
                <mobiscroll.Textarea
                  defaultValue="Lorem Ipsum"
                  className="profile-about"
                ></mobiscroll.Textarea>
              ) : (
                <p className="profile-about">About</p>
              )}
              <div className="mbsc-row profile-record-card">
                <div className="mbsc-col-4 wrap">
                  {editProfile ? (
                    <Input defaultValue="Test" />
                  ) : (
                    <span>Test</span>
                  )}
                  <p>Exp.</p>
                </div>
                <div className="mbsc-col-4 wrap">
                  {editProfile ? (
                    <Input defaultValue="Test" />
                  ) : (
                    <span>Test</span>
                  )}

                  <p>Awards</p>
                </div>
                <div className="mbsc-col-4 wrap">
                  {editProfile ? (
                    <Input defaultValue="Test" />
                  ) : (
                    <span>Test</span>
                  )}

                  <p>Patients</p>
                </div>
              </div>
              <hr />
              <label className="label_style">Location:</label>
              {editProfile ? (
                <Textarea
                  defaultValue="Lorem Ipsum"
                  readOnly={!editProfile}
                  className="textarea_style"
                />
              ) : (
                <p className="textarea_style">Lorem Ipsum</p>
              )}
              <hr />
              <label className="label_style">Email:</label>

              {editProfile ? (
                <Input
                  defaultValue="Lorem Ipsum"
                  readOnly={!editProfile}
                  className="input-profile-page"
                />
              ) : (
                <p className="textarea_style">Lorem Ipsum</p>
              )}
              <hr />
              <label className="label_style">Phone:</label>
              <Input
                defaultValue="Lorem Ipsum"
                readOnly={!editProfile}
                className="input-profile-page"
              />
            </div>
          </div>
          <div className="mbsc-col-lg-9 mbsc-col-md-8 mbsc-col-12">
            <Fragment>
              <div className="margin-none">
                <Card className=" docg-card docg-appointment margin-none">
                  <CardBody className="m-0 p-0">
                    <TabNav
                      className="docg-tabs"
                      theme="windows"
                      context="#tab-content"
                      style={{ textAlign: "left" }}
                    >
                      <NavItem
                        selected={activeTab === "about"}
                        onClick={() => setActiveTab("about")}
                        style={{ textAlign: "left" }}
                      >
                        <img src={patient} alt="" className="mr-2" />
                        About
                      </NavItem>
                    </TabNav>
                    {activeTab === "about" && <AboutSection />}
                  </CardBody>
                </Card>
              </div>
            </Fragment>
          </div>
        </div>
      </div>
    </>
  );
};

const AboutSection = () => {
  const { Header: CardHeader, Body: CardBody, Title: CardTitle } = Card;
  const [editProfile, setEditProfile] = useState(false);
  const [profileImage, setProfileImage] = useState(DoctorImage);
  const [editAboutMe, setEditAboutMe] = useState(false);
  const [activeField, setActiveField] = useState(null);
  const [openPopup, setOpenPopup] = useState(false);
  const [placeholder, setPlaceholder] = useState("");
  const [newInputData, setNewInputData] = useState("");
  const [data, setData] = useState({});
  const [activeTab, setActiveTab] = useState("about");

  const profilePicture = (event) => {
    setProfileImage(URL.createObjectURL(event.target.files[0]));
  };

  const toggleEditAbout = () => {
    if (editAboutMe != false) {
      setEditAboutMe(false);
    } else {
      setEditAboutMe(true);
    }
  };

  const toggleEdit = () => {
    if (editProfile != false) {
      setEditProfile(false);
    } else {
      setEditProfile(true);
    }
  };

  const handleChange = (placeholder, field) => {
    if (openPopup == false) {
      setPlaceholder(placeholder);
      setOpenPopup(true);
      setActiveField(field);
      console.log(newInputData);
    }
  };
  return (
    <>
      <div
        style={{
          height: "calc(100% - 54px",
          background: "#fff",
          borderRadius: "0.25rem",
          padding: "0",
        }}
        className="mbsc-grid p-3"
      >
        <div className="mbsc-row">
          <div className="mbsc-col-11 docg-profile-text mb-3">
            <mobiscroll.Textarea
              defaultValue="Lorem Ipsum"
              readOnly={!editAboutMe}
              className=""
            ></mobiscroll.Textarea>
          </div>
          <div className="mbsc-col-1">
            <button className="editButton" onClick={toggleEditAbout}>
              {!editAboutMe ? (
                <img src={ProfileEditIcon} />
              ) : (
                <img src={Heard} />
              )}
            </button>
          </div>
        </div>
        <div className="mbsc-row horizontal-bar">
          <div className="mbsc-col-11">
            <p>Qualification</p>
          </div>
          <div className="mbsc-col-1">
            <button className="editButton" onClick={toggleEdit}>
              {editProfile ? (
                <img src={Heard} />
              ) : (
                <img src={ProfileEditIcon} />
              )}
            </button>
          </div>
        </div>
        <mobiscroll.FormGroup>
          <div className="mbsc-row right-panel-input-area">
            <div className="mbsc-col-11">
              <div className="profile-list">
                <span>Hospital Affiliations :</span>{" "}
                {/* {fields.doctorProfile.hospitalAffiliations} */}
                Lorem Ipsum
              </div>
            </div>
            <div className="mbsc-col-1">
              <button
                onClick={() => {
                  handleChange("Add New Hospital Affilitations", "hospital");
                }}
                className="editButton "
              >
                <img src={plus_icon} />
              </button>
            </div>
          </div>
          <div className="mbsc-row right-panel-input-area">
            <div className="mbsc-col-11">
              <div className="profile-list">
                {" "}
                <span> Degree*</span> :{/* {fields.degree} */}
                Lorem Ipsum
              </div>
            </div>
            <div className="mbsc-col-1">
              <button
                onClick={() => {
                  handleChange("Add New Degree", "degree");
                }}
                className="editButton"
              >
                <img src={plus_icon} />
              </button>
            </div>
          </div>
          <div className="mbsc-row right-panel-input-area">
            <div className="mbsc-col-11">
              <div className="profile-list">
                {" "}
                <span> Medical School*</span> :{" "}
                {/* {fields.doctorProfile.medicalSchool} */}
                Lorem Ipsum
              </div>
            </div>
            <div className="mbsc-col-1">
              <button
                onClick={() => {
                  handleChange("Add New Medical School", "medicalSchool");
                }}
                className="editButton "
              >
                <img src={plus_icon} />
              </button>
            </div>
          </div>
          <div className="mbsc-row right-panel-input-area">
            <div className="mbsc-col-11">
              <div className="profile-list">
                {" "}
                <span>Residency* </span> :
                {/* {fields.doctorProfile.residencies} */}
                Lorem Ipsum
              </div>
            </div>
            <div className="mbsc-col-1">
              <button
                onClick={() => {
                  handleChange("Add New Residency", "residency");
                }}
                className="editButton "
              >
                <img src={plus_icon} />
              </button>
            </div>
          </div>
          <div className="mbsc-row right-panel-input-area">
            <div className="mbsc-col-11">
              <div className="profile-list">
                {" "}
                <span> Certifications*</span> :{/* {fields.certifications} */}
                Lorem Ipsum
              </div>
            </div>
            <div className="mbsc-col-1">
              <button
                onClick={() => {
                  handleChange("Add New Certifications", "certifications");
                }}
                className="editButton "
              >
                <img src={plus_icon} />
              </button>
            </div>
          </div>
          <div className="mbsc-row right-panel-input-area">
            <div className="mbsc-col-12">
              <div className="profile-list">
                <span>Gender*</span> :{/* {fields.gender} */}
                Lorem Ipsum
              </div>
            </div>
          </div>
          <div className="mbsc-row right-panel-input-area">
            <div className="mbsc-col-12">
              <div className="profile-list">
                <span>Year of Experience*</span> :
                {/* {fields.yearsOfExperience} */}
                Lorem Ipsum
              </div>
            </div>
          </div>
          <div className="mbsc-row right-panel-input-area">
            <div className="mbsc-col-12">
              <div className="profile-list">
                <span>Awards*</span> :{/* {fields.award} */}
                Lorem Ipsum
              </div>
            </div>
          </div>
        </mobiscroll.FormGroup>
        <div className="mbsc-row">
          <div
            className="mbsc-col-lg-4"
            style={{ alignItems: "center", textAlign: "left" }}
          >
            <Button style={{ width: "200px" }} className="theme-button btn-xs">
              <span className="material-icons pr-1">upload</span> Upload Image
            </Button>
          </div>
          <div
            className="mbsc-col-lg-8"
            style={{
              display: "flex",
              alignItems: "center",
              textAlign: "right",
            }}
          >
            <Button style={{ width: "200px" }} className="theme-button btn-xs">
              <span className="material-icons pr-1">upload</span> Doctor's
              Signature
            </Button>
            <Button style={{ width: "200px" }} className="theme-button btn-xs">
              <span className="material-icons pr-1">upload</span> Logo
            </Button>
            <Button style={{ width: "200px" }} className="theme-button btn-xs">
              <span className="material-icons pr-1">save</span> Save
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Profile;
