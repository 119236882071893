import {
  Document,
  Image,
  Page,
  StyleSheet,
  Text,
  View,
} from "@react-pdf/renderer";
import { Html } from "react-pdf-html";

export default function PdfDocument({ html }) {
  // console.log("pdf props", props.data);
  return (
    <Document>
      <Page>
        {" "}
        <Html>{html}</Html>
      </Page>
    </Document>
  );
}
