export const REFRESH_SLOTS = "REFRESH_SLOTS";
export const CHANGE_CURRENT_DAY = "CHANGE_CURRENT_DAY";
export const UPDATE_DOCTOR_ID = "UPDATE_DOCTOR_ID";
export const UPDATE_CONSULTATION = "UPDATE_CONSULTATION";

export const initialState = {
  timeSlots: [],
  current: null,
  doctorId: null,
  consultationType: [],
  cashAccepted: false,
};
export const refreshSlots = (timeSlots) => ({
  type: REFRESH_SLOTS,
  timeSlots,
});

export const changeCurrentDay = (current) => ({
  type: CHANGE_CURRENT_DAY,
  current,
});
export const changeDoctorId = (doctorId) => ({
  type: UPDATE_DOCTOR_ID,
  doctorId,
});

export const updateConsultationType = (current, cashAccepted) => {
  let arr = Object.keys(current)
    .filter((type) => current[type] && type !== "Emergency")
    .map((type) => ({
      text: type === "Face2Face" ? "In person at clinic" : type,
      value: type,
    }));
  return {
    type: UPDATE_CONSULTATION,
    payload: {
      consultationType: arr,
      cashAccepted,
      emergency: current.Emergency,
    },
  };
};

export const timeSlotsReducer = (state, action, initialState) => {
  console.log(state, action, initialState);
  switch (action.type) {
    case REFRESH_SLOTS:
      return {
        ...state,
        timeSlots: action.timeSlots,
      };
    case CHANGE_CURRENT_DAY:
      return {
        ...state,
        current: action.current,
      };
    case UPDATE_DOCTOR_ID:
      return {
        ...state,
        doctorId: action.doctorId,
      };
    case UPDATE_CONSULTATION:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};
