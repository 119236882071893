import { snackbar } from "@mobiscroll/react5";
import {
  getQuery,
  getQueryWithParams,
  postMutation,
} from "src/components/ApolloConnection/ApolloProviderComponent";
import {
  CANCEL_RAZORPAY_SUBSCRIPTION,
  CREATE_RAZORPAY_SUBSCRIPTION,
  UPDATE_PROFILE,
} from "src/components/graphql/GraphQLmutation";
import {
  FETCH_SUBSCRIPTION_BY_ID,
  FETCH_SUBSCRIPTION_PLAN_BY_ID,
  GET_PROFILE,
  RAZORPAY_PLANS,
} from "src/components/graphql/GraphQLquery";

export async function cancelRazorpaySubscription(id) {
  try {
    const response = await postMutation(CANCEL_RAZORPAY_SUBSCRIPTION, id);

    if (
      typeof response === "undefined" ||
      response?.errors ||
      !(
        response?.data?.cancelRazorpaySubscription?.statusCode < 300 &&
        response?.data?.cancelRazorpaySubscription?.statusCode >= 200
      )
    ) {
      throw new Error("error", response);
    } else {
      return true;
    }
  } catch (e) {
    snackbar({
      message: "Some error occured while Cancelling subscription. Try again",
      color: "danger",
    });
    console.log("Error at createRazorpayPlan: ", e);
    return false;
  }
}
export async function listAllRazorpayPlans() {
  try {
    const response = await getQuery(RAZORPAY_PLANS);

    if (
      response &&
      response?.data.listAllRazorpayPlans.statusCode < 300 &&
      response?.data.listAllRazorpayPlans.statusCode >= 200
    ) {
      return response.data.listAllRazorpayPlans.data;
    } else {
      throw new Error(response);
    }
  } catch (e) {
    snackbar({
      message: "Some error occured while fetching plan details. Try again",
      color: "danger",
    });
    console.log("Error at add doctor fee: ", e.message);
    return [];
  }
}
export async function getClinicProfile(data) {
  try {
    const response = await getQueryWithParams(GET_PROFILE, { id: data });
    if (
      typeof response == "undefined" ||
      response?.errors ||
      !(
        response?.data?.getClinicProfile?.statusCode >= 200 &&
        response?.data?.getClinicProfile?.statusCode < 300
      )
    ) {
      throw new Error("error", response);
    } else {
      if (response?.data?.getClinicProfile?.data) {
        return response?.data?.getClinicProfile?.data;
      } else {
        throw new Error("error", response);
      }
    }
  } catch (e) {
    snackbar({
      message: "Some error occured. Try again",
      color: "danger",
    });
    console.log("Error at fetching Clinic information ", e);
    return [];
  }
}
export async function fetchSubscriptionById(id) {
  try {
    const response = await getQueryWithParams(FETCH_SUBSCRIPTION_BY_ID, id);
    if (
      typeof response == "undefined" ||
      response?.errors ||
      !(
        response?.data?.fetchSubscriptionById?.statusCode >= 200 &&
        response?.data?.fetchSubscriptionById?.statusCode < 300
      )
    ) {
      throw new Error("error", response);
    } else {
      if (response?.data?.fetchSubscriptionById?.data) {
        return response?.data?.fetchSubscriptionById?.data;
      } else {
        throw new Error(response?.data?.fetchSubscriptionById?.message);
      }
    }
  } catch (e) {
    snackbar({
      message: e?.message || "Some error occured. Try again",
      color: "danger",
    });
    console.log("Error at fetching Clinic information ", e);
    return [];
  }
}
export async function fetchSubscriptionPlanById(id) {
  try {
    const response = await getQueryWithParams(
      FETCH_SUBSCRIPTION_PLAN_BY_ID,
      id
    );
    if (
      typeof response == "undefined" ||
      response?.errors ||
      !(
        response?.data?.fetchSubscriptionPlanById?.statusCode >= 200 &&
        response?.data?.fetchSubscriptionPlanById?.statusCode < 300
      )
    ) {
      console.log(response);
      throw new Error(response?.data?.fetchSubscriptionPlanById?.message);
    } else {
      if (response?.data?.fetchSubscriptionPlanById?.data) {
        return response?.data?.fetchSubscriptionPlanById?.data;
      } else {
        throw new Error();
      }
    }
  } catch (e) {
    snackbar({
      message: e?.message || "Some error occured. Try again",
      color: "danger",
    });
    console.log("Error at fetching Clinic information ", e);
    return null;
  }
}
export async function updateProfile(data) {
  try {
    const response = await postMutation(UPDATE_PROFILE, data);

    if (typeof response === "undefined") {
      throw new Error("error", response);
    } else {
      if (
        response.data.updateClinic.statusCode < 300 &&
        response.data.updateClinic.statusCode >= 200
      ) {
        return response.data.updateClinic.data;
      } else {
        throw new Error(response);
      }
    }
  } catch (e) {
    snackbar({
      message: "Some error occured while updating profile. Try again",
      color: "danger",
    });
    console.log("Error at add doctor fee: ", e);
    return null;
  }
}

export async function createRazorpaySubscription() {
  try {
    const id = sessionStorage.getItem("clinic-id");
    const response = await postMutation(CREATE_RAZORPAY_SUBSCRIPTION, id);

    if (typeof response === "undefined") {
      throw new Error("error", response);
    } else {
      if (
        response.data.createRazorpaySubscription.statusCode < 300 &&
        response.data.createRazorpaySubscription.statusCode >= 200
      ) {
        return true;
      } else {
        throw new Error(response);
      }
    }
  } catch (e) {
    snackbar({
      message: "Some error occured while updating profile. Try again",
      color: "danger",
    });
    console.log("Error at add doctor fee: ", e);
    return null;
  }
}
