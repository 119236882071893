import { snackbar } from "@mobiscroll/react5";
import { getQuery } from "src/components/ApolloConnection/UnauthorizedConnection/ApolloProviderSignup";
import { RAZORPAY_PLANS } from "src/components/graphql/GraphQLquery";

export async function listAllRazorpayPlans() {
  try {
    const response = await getQuery(RAZORPAY_PLANS);

    if (
      response &&
      response?.data.listAllRazorpayPlans.statusCode < 300 &&
      response?.data.listAllRazorpayPlans.statusCode >= 200
    ) {
      return response.data.listAllRazorpayPlans.data;
    } else {
      throw new Error(response);
    }
  } catch (e) {
    snackbar({
      message: "Some error occured while fetching plan details. Try again",
      color: "danger",
    });
    console.log("Error at add doctor fee: ", e.message);
    return [];
  }
}
