import { NavItem, TabNav } from "@mobiscroll/react";
import { useState } from "react";
import { Card } from "react-bootstrap";
import { Helmet } from "react-helmet";
import addPatient from "src/assets/patient/Employee.svg";
import AddPatient from "./Tab2/AddPatient";

import "./addpatient.scss";
//api

const { Header: CardHeader, Body: CardBody, Title: CardTitle } = Card;

const AddPatientPage = () => {
  const [tab, setTab] = useState(2);

  const onQuickFilterChanged = () => {};

  return (
    <>
      <Helmet>
        <title>Add Patient | DOCG</title>
      </Helmet>
      <Card className="docg-card docg-appointments docg-vh-standard docg-add-patient">
        <CardHeader className="d-flex justify-content-between customCardHeader pb-0 pt-0">
          <TabNav
            style={{ width: "fit-content" }}
            className="docg-tabs d-inline-flex"
            theme="windows"
            context="#tab-content"
          >
            <NavItem selected={tab === 2} onClick={() => setTab(2)}>
              <img src={addPatient} alt="" />
              Add Employee
            </NavItem>
          </TabNav>
        </CardHeader>
        <CardBody className="docg-card-body add-employee-tab w-100 p-0 overflow-auto">
          <AddPatient />
        </CardBody>
      </Card>
    </>
  );
};

export default AddPatientPage;
