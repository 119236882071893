import React from "react";
import mobiscroll from "@mobiscroll/react";
import InputFloating from "./InputFloating";
import PasswordFloating from "./PasswordFloating";
import TextareaFloating from "./Textarea";
import RadioBtn from "./RadioBtn";
import Buttons from "./Buttons";
import Switch from "./Switch";

const components = [
  {
    title: "Input Field",
    component: InputFloating,
  },
  {
    title: "Password Field",
    component: PasswordFloating,
  },
  {
    title: "Textarea Field",
    component: TextareaFloating,
  },
  {
    title: "Radio button Field",
    component: RadioBtn,
  },
  {
    title: "Buttons",
    component: Buttons,
  },
  {
    title: "Switch",
    component: Switch,
  },
];

export default function () {
  return (
    <div>
      <br />
      <strong>mobiscroll v5</strong>
      <ul>
        {components.map((component) => {
          return (
            <>
              <li>
                <b>{component.title}</b>
              </li>
              <component.component />
              <br />
            </>
          );
        })}
      </ul>
      <br />
      <strong>mobiscroll v4</strong>
      <br />
      <i>recommended to use v5 instead</i>
      <ul>
        <li>
          {" "}
          <strong> Input field</strong>
        </li>
        <mobiscroll.Input
          inputStyle="underline"
          labelStyle="floating"
          placeholder="Text field"
        >
          Label
        </mobiscroll.Input>
        <br />
        <li>
          {" "}
          <strong> Password field</strong>
        </li>
        <mobiscroll.Input
          inputStyle="underline"
          labelStyle="floating"
          passwordToggle={true}
          placeholder="Change Password"
          name="password"
        >
          Label
        </mobiscroll.Input>
        <li>
          {" "}
          <strong> Textarea field</strong>
        </li>
        <div className="textarea-wrap">
          {/* label  */}
          <span>Personal</span>
          {/* textarea */}
          <mobiscroll.Textarea
            name="observation"
            inputStyle="underline"
            className="observation"
          ></mobiscroll.Textarea>
        </div>
      </ul>
    </div>
  );
}
