import { Textarea } from "@mobiscroll/react5";
import QRCode from "qrcode.react";
import React, { Fragment, useState } from "react";

//componentes de
import Popup from "./Popup";

import mobiscroll, { NavItem, TabNav } from "@mobiscroll/react";
import { Card, Col, Row } from "react-bootstrap";
import patient from "src/assets/person.svg";
import AccountDetail from "../../../assets/Group 87.svg";
import plus_icon from "../../../assets/plus-circle.svg";
import { MFASettings } from "./MFASettings";

const RightPanel = (props) => {
  const [activeTab, setActiveTab] = useState("about");

  return (
    <Fragment>
      <Card className=" docg-card docg-appointment docg-vh-standard margin-none">
        <Card.Body className="m-0 p-0">
          <TabNav className="docg-tabs" theme="windows" context="#tab-content">
            <NavItem
              selected={activeTab === "about"}
              onClick={() => setActiveTab("about")}
            >
              <img src={patient} alt="" className="mr-2" />
              About
            </NavItem>
            <NavItem
              selected={activeTab === "account"}
              onClick={() => setActiveTab("account")}
            >
              <img src={AccountDetail} alt="" className="mr-2" />
              Account
            </NavItem>
          </TabNav>
          {activeTab === "about" && <AboutSection {...props} />}
          {activeTab === "account" && <AccountSection {...props} />}
        </Card.Body>
      </Card>
    </Fragment>
  );
};

const AboutSection = ({
  data,
  setData,
  edit,
  setEdit,
  edited,
  setEdited,
  setTouched,
  cancelOnClick,
  editOnClick,
  _renderAction,
  reloadData,
}) => {
  const [specializationPopup, setSpecialization] = React.useState(false);

  return (
    <>
      <div
        style={{
          height: "calc(100% - 54px",
          background: "#fff",
          borderRadius: "0.25rem",
        }}
        className="mbsc-grid profile-right-panel p-3"
      >
        <div className="mbsc-row">
          <div className="mbsc-col-12 docg-profile-text">
            {edit ? (
              <Textarea
                name="About"
                inputStyle="underline"
                labelStyle="floating"
                value={edited?.about}
                onChange={(e) => {
                  setEdited((prev) => {
                    return {
                      ...prev,
                      about: e.target.value,
                    };
                  });
                  setTouched((prev) => {
                    return { ...prev, about: true };
                  });
                }}
              ></Textarea>
            ) : (
              <span>{data?.about}</span>
            )}
          </div>
        </div>
        <hr />
        <div className="mbsc-row horizontal-bar">
          <div className="mbsc-col-12">
            <p>Clinic Specialities</p>
          </div>
        </div>
        <br />
        <div className="profile-specialities">
          {data?.specialities
            .filter((el) => el != null && el != undefined)
            .join(", ")}
          {edit && (
            <button
              name="Specialization"
              onClick={(e) => {
                setSpecialization(true);
              }}
            >
              <img src={plus_icon} alt="" />
            </button>
          )}
        </div>
        <mobiscroll.FormGroup> {_renderAction()}</mobiscroll.FormGroup>
      </div>
      <Popup
        show={specializationPopup}
        closePopup={() => setSpecialization(false)}
        title="Specialization"
        data={data?.specialities}
        reloadData={reloadData}
      />
      {/* <Popupxy /> */}
    </>
  );
};

const AccountSection = ({
  data,
  handleImageChange,
  handleClinicLogoHeaderChange,
}) => {
  const clinicId = window.sessionStorage.getItem("clinic-id");
  const value = `${window.location.origin}/add-patient-by-url/${clinicId}`;
  const downloadQRCode = () => {
    // Generate download with use canvas and stream
    const canvas = document.getElementById("qr-gen");
    const pngUrl = canvas
      .toDataURL("image/png")
      .replace("image/png", "image/octet-stream");
    let downloadLink = document.createElement("a");
    downloadLink.href = pngUrl;
    downloadLink.download = `QRCode_${data.clinicName}.png`;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };
  return (
    <div
      style={{
        height: "calc(100% - 54px)",
        background: "#fff",
        borderRadius: "1.25rem",
        overflowY: "auto",
      }}
      className="mbsc-grid p-3 "
    >
      <Card className="docg-patient-form-wrapper docg-card h-standard mr-2">
        <Card.Title className="d-flex align-items-center justify-content-between">
          The below QR code, is for Patient App to login in to your clinic
        </Card.Title>
        <Card.Body className="d-flex flex-column align-items-center pb-3">
          <Row>
            <Col>
              {value && (
                <div className="d-flex flex-column align-items-center justify-content-center">
                  <QRCode
                    id="qr-gen"
                    value={value}
                    up
                    size={256}
                    level={"H"}
                    includeMargin={true}
                  />
                  <button className="docg-outline-btn" onClick={downloadQRCode}>
                    Download QR Code
                  </button>
                </div>
              )}
            </Col>
          </Row>
        </Card.Body>
      </Card>
      <hr />
      <Card className="docg-patient-form-wrapper docg-card h-standard mr-2">
        <Card.Title className="d-flex justify-content-start">
          Upload Signature
        </Card.Title>
        <Row>
          <Col className="d-flex align-items-center justify-content-center w-100">
            {data?.signature && (
              <img
                style={{
                  maxWidth: "100px",
                  maxHeight: "100px",
                  width: "100px",
                  height: "100px",
                }}
                src={data.signature}
                alt=""
              />
            )}
            <label for="sign-uplaod" className="">
              <div className="file-upload-button">Upload Signature</div>
            </label>
            <input
              type="file"
              id="sign-uplaod"
              onChange={(e) => handleImageChange("signature", e)}
              accept="image/*"
            />
          </Col>
        </Row>
      </Card>
      <hr />
      <Card className="docg-patient-form-wrapper docg-card h-standard mr-2">
        <Card.Title className="d-flex justify-content-start">
          Upload Clinic Header
        </Card.Title>
        <Row>
          <Col className="d-flex flex-column align-items-center justify-content-center w-100">
            {data?.clinicLogoHeader && (
              <img
                style={{
                  maxHeight: "100px",
                  height: "100px",
                }}
                src={data.clinicLogoHeader}
                alt=""
              />
            )}
            <Row>
              <Col>
                <label for="logo-header-uplaod" className="">
                  <div className="file-upload-button">Upload</div>
                </label>
                <input
                  type="file"
                  id="logo-header-uplaod"
                  onChange={(e) => handleClinicLogoHeaderChange(e)}
                  accept="image/*"
                />
              </Col>
              <Col className="d-flex flex-column align-items-center justify-content-center w-100">
                <button onClick={()=>handleClinicLogoHeaderChange()} className="docg-save-btn">Remove header</button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Card>
      <hr />

      <MFASettings />
    </div>
  );
};

export default RightPanel;
