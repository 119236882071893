const TEMPLATE_DRUGS = "TEMPLATE_DRUGS";

export const initialState = {};

export const signupReducer = (state, action, initialState) => {
  switch (action.type) {
    case "CUSTOM":
      return {
        ...state,
        ...action.payload,
      };
    default:
      break;
  }

  return state;
};
