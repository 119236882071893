import React from "react";

const RadioBtn = () => {
  return (
    <div>
      <label htmlFor="interval1" className="docg-radio-label ml-4">
        <input
          type="radio"
          name="hours-interval"
          id="interval1"
          defaultChecked
        />
        Radio option 1<span class="checkmark"></span>
      </label>
      <label htmlFor="interval2" className="docg-radio-label ml-4">
        <input type="radio" name="hours-interval" id="interval2" />
        Radio option 2<span class="checkmark"></span>
      </label>
    </div>
  );
};

export default RadioBtn;
