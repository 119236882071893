import Calendar from "../ClinicDashboard/Calendar/Calendar";
import Dashboard from "../ClinicDashboard/Dashboard";
import FeeWrapper from "../ClinicDashboard/Fee/FeeWrapper";
import Index from "../ClinicDashboard/MemberShip/Index";
import PatientRecord from "../ClinicDashboard/Patient Records/PatientDetail/PatientDetail";
import PatientList from "../ClinicDashboard/Patient Records/PatientList";
import Profile from "../ClinicDashboard/Profile/Profile";
// import Prescription from "../ClinicDashboard/Prescription/Prescription";
import Schedule from "../ClinicDashboard/Schedule/Schedule";
// import Email from "../ClinicDashboard/email/email";
// import AddPatient from "../ClinicDashboard/addpatient/addpatient";
import AddNewEmployees from "src/components/ClinicDashboard/AddNewEmployees/AddNewEmployee/index";
import DoctorProfile from "src/components/ClinicDashboard/AddNewEmployees/Doctor/Profile";
import EmployeeList from "src/components/ClinicDashboard/AddNewEmployees/EmployeeList";
// import PatientList from "../ClinicDashboard/PatientList/PatientList";
import Subscription from "src/components/Authentication/Subscription";
import AddPatient from "../ClinicDashboard/AddPatient";
import AddPatientByUrl from "../ClinicDashboard/AddPatientByUrl";
import EmployeeRecord from "../ClinicDashboard/Employee Records/index";
import InvitePatients from "../ClinicDashboard/InvitePatients/InvitePatients";
import CreateInvoice from "../ClinicDashboard/Invoice/CreateInvoice";
import Invoice from "../ClinicDashboard/Invoice/Invoice";
import InvoiceDetail from "../ClinicDashboard/Invoice/InvoiceDetail";
import Reporting from "../ClinicDashboard/Reporting/Reporting";
import Resources from "../ClinicDashboard/Resources/Resources";
import Symptom from "../ClinicDashboard/symptom/symptom";
import ClinicActions from "./ClinicActions";
import TnC from "./Profile/TnC";
import Failure from "./Subscription/Failure";
import History from "./Subscription/History";
import NewSubscription from "./Subscription/new";

const ClinicRoutes = [
  {
    path: "/dashboard",
    module: "clinic",
    auth: true,
    component: Dashboard,
  },
  {
    path: "/clinic-actions",
    module: "clinic",
    auth: true,
    component: ClinicActions,
  },
  {
    path: "/invite-patient",
    module: "clinic",
    auth: true,
    component: InvitePatients,
  },
  {
    path: "/subscription",
    module: "clinic",
    auth: true,
    component: Subscription,
  },
  {
    path: "/subscription/failure",
    module: "clinic",
    auth: true,
    component: Failure,
  },
  {
    path: "/subscription/new",
    module: "clinic",
    auth: true,
    component: NewSubscription,
  },
  {
    path: "/subscription/history",
    module: "clinic",
    auth: true,
    component: History,
  },

  {
    path: "/patient-list",
    module: "clinic",
    auth: true,
    component: PatientList,
  },
  {
    path: "/patient-record/:patientId",
    module: "clinic",
    auth: true,
    component: PatientRecord,
  },
  {
    path: "/calendar",
    module: "clinic",
    auth: true,
    component: Calendar,
  },
  {
    path: "/schedule",
    module: "clinic",
    auth: true,
    component: Schedule,
  },

  {
    path: "/fee",
    module: "clinic",
    auth: true,
    component: FeeWrapper,
  },
  {
    path: "/membership",
    module: "clinic",
    auth: true,
    component: Index,
  },
  {
    path: "/symptom",
    module: "clinic",
    auth: true,
    component: Symptom,
  },
  {
    path: "/add-patient",
    module: "clinic",
    auth: true,
    component: AddPatient,
  },
  {
    path: "/add-patient-by-url/:clinicId",
    module: "clinic",
    auth: false,
    component: AddPatientByUrl,
  },

  {
    path: "/employee-list",
    module: "clinic",
    auth: true,
    component: EmployeeList,
  },
  {
    path: "/add-employee",
    module: "clinic",
    auth: true,
    component: AddNewEmployees,
  },
  {
    path: "/tnc",
    module: "clinic",
    auth: true,
    component: TnC,
  },
  {
    path: "/employee-detail",
    module: "clinic",
    auth: true,
    component: DoctorProfile,
  },
  {
    path: "/employee-record",
    module: "clinic",
    auth: true,
    component: EmployeeRecord,
  },

  {
    path: "/invoice",
    module: "clinic",
    auth: true,
    component: Invoice,
  },
  {
    path: "/invoice-details/:id",
    module: "clinic",
    auth: true,
    component: InvoiceDetail,
  },

  {
    path: "/resources",
    module: "clinic",
    auth: true,
    component: Resources,
  },

  {
    path: "/create-invoice",
    module: "clinic",
    auth: true,
    component: CreateInvoice,
  },
  {
    path: "/profile",
    module: "clinic",
    auth: true,
    component: Profile,
  },
  {
    path: "/reporting",
    module: "clinic",
    auth: true,
    component: Reporting,
  },
];

export default ClinicRoutes;
