import React from "react";
import { activateDoctorFee, getDoctorFees } from "./api";
import { refreshFees } from "./store/FeeReducer";
import Loader from "src/components/ClinicDashboard/common/Loader";
import { useStore } from "./store/Store";
import { snackbar } from "@mobiscroll/react5";
const StatusCellRenderer = (params) => {
  const [loader, setLoader] = React.useState(false);
  const { globalState, dispatch } = useStore();
  const handleChange = async (e) => {
    setLoader(true);
    try {
      if(e.target.checked){
        await activateDoctorFee({
          doctorId: params.data.doctorId,
          id: params.data.id,
        });
      await fetchData(params.data.doctorId);
      }
    } catch (err) {
      snackbar({
        message: "Some error occured while updating Fee. Try again",
        color: "danger",
      });
      console.log("Error at update doctor fee: ", err);
    }
    setLoader(false);
  };
  const fetchData = async (doctorId) => {
    setLoader(true);
    const res = await getDoctorFees(doctorId);
    dispatch(refreshFees(res));
    setLoader(false);
  };
  return (
    <>
      <Loader show={loader} />
      <label className="switch">
        <input
          type="checkbox"
          checked={params.value === "Inactive" ? false : true}
          onChange={handleChange}
        />
        <span className="slider active slider-po-show round"></span>
      </label>
    </>
  );
};

export default StatusCellRenderer;
