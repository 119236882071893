import { snackbar } from "@mobiscroll/react5";
import {
  getQuery,
  getQueryWithParams,
  postMutation,
} from "src/components/ApolloConnection/ApolloProviderComponent";
import {
  ADD_SCHEDULE,
  REMOVE_SCHEDULE,
  REMOVE_SCHEDULE_GROUP,
} from "src/components/graphql/GraphQLmutation";
import {
  GET_DOCTOR_ID,
  GET_DOCTOR_SCHEDULES,
  LIST_DOCTORS,
  LIST_TODAY_APPOINTMENTS,
} from "src/components/graphql/GraphQLquery";

export async function getSchedules(doctorId, start, end) {
  try {
    const data = {
      doctorId,
      start,
      end,
    };
    console.log(data);
    let response = await getQueryWithParams(GET_DOCTOR_SCHEDULES, data);
    console.log(response);
    if (typeof response == "undefined") {
      throw new Error(response);
    } else {
      if (response.data.listSchedules.data) {
        return response.data.listSchedules.data;
      } else {
        throw new Error(response);
      }
    }
  } catch (error) {
    console.log("ERROR at list Schedules", error);
    return [];
  }
}

export async function getData(doctorId, start, end) {
  console.log(doctorId, start, end);
  try {
    const response = await getSchedules(doctorId, start, end);
    let data = [];
    if (response) {
      response.forEach((_el) => {
        const {
          duration,
          end,
          gap,
          id,
          doctorId,
          recurrence,
          start,
          relatedId,
        } = _el;
        let el = {
          duration,
          end,
          gap,
          id,
          doctorId,
          recurrence,
          relatedId,
          start,
        };
        data.push(el);
      });
      console.log(data);
    }
    return data;
  } catch (err) {
    console.log(err);
  }
}

export async function addSchedule(data) {
  try {
    const response = await postMutation(ADD_SCHEDULE, data);
    snackbar({
      message: "Successfully added",
      color: "success",
    });
    if (
      response.errors == null &&
      response?.data != null &&
      response?.data?.addSchedule?.statusCode >= 200 &&
      response?.data?.addSchedule?.statusCode < 300
    ) {
      return true;
    } else {
      throw new Error(
        response?.data?.addSchedule?.message || "Something went wrong"
      );
    }
  } catch (e) {
    snackbar({
      message: e.message,
      color: "danger",
    });
    console.log("Error at add Schedule: ", e);
    return false;
  }
}

export async function getDoctorId() {
  try {
    let response = await getQuery(GET_DOCTOR_ID);
    if (typeof response == "undefined") {
      throw new Error(response);
    } else {
      if (response.data.getMyProfile.data) {
        return response.data.getMyProfile.data.id;
      } else {
        throw new Error(response);
      }
    }
  } catch (error) {
    console.log("ERROR at list Schedules", error);
    return undefined;
  }
}

export async function removeSchedule(data) {
  try {
    let response = await postMutation(REMOVE_SCHEDULE, data);
    if (
      response.errors == null &&
      response?.data != null &&
      response?.data?.removeSchedule?.statusCode >= 200 &&
      response?.data?.removeSchedule?.statusCode < 300
    ) {
      return response;
    } else {
      throw new Error(
        response?.data?.removeSchedule?.message || "Something went wrong"
      );
    }
  } catch (error) {
    snackbar({
      message: error.message,
      color: "danger",
    });
    console.log("ERROR at list Schedules", error);
    return undefined;
  }
}
export async function removeScheduleGroup(data) {
  try {
    let response = await postMutation(REMOVE_SCHEDULE_GROUP, data);
    if (
      response.errors == null &&
      response?.data != null &&
      response?.data?.removeScheduleGroup?.statusCode >= 200 &&
      response?.data?.removeScheduleGroup?.statusCode < 300
    ) {
      return response;
    } else {
      throw new Error(
        response?.data?.removeScheduleGroup?.message || "Something went wrong"
      );
    }
  } catch (error) {
    snackbar({
      message: error.message,
      color: "danger",
    });
    console.log("ERROR at list Schedules group", error);
    return undefined;
  }
}

export async function getDoctors() {
  try {
    let response = await getQuery(LIST_DOCTORS);
    console.log(response);
    if (typeof response == "undefined") {
      throw new Error(response);
    } else {
      if (response.data.listDoctors.data) {
        return response.data.listDoctors.data;
      } else {
        throw new Error(response);
      }
    }
  } catch (error) {
    console.log("ERROR at list Schedules", error);
    return undefined;
  }
}
export async function listAppointments(doctorIds, take, start, end, filters) {
  console.log(doctorIds, take, start, end, filters);
  try {
    const response = await getQueryWithParams(
      LIST_TODAY_APPOINTMENTS(take, start, end, filters),
      doctorIds
    );
    // let response = await getQuery(GET_DOCTOR(false, "", "", ""));

    console.log(response);
    if (typeof response == "undefined") {
      throw new Error("error", response);
    } else {
      if (response?.errors) {
        throw new Error("error", response);
      } else if (
        response?.data?.listClinicAppointments &&
        response?.data?.listClinicAppointments?.data
      ) {
        snackbar({
          message: "List of Appointments fetched successfully.",
          color: "success",
        });
        return response?.data?.listClinicAppointments.data;
      } else {
        throw new Error("error", response);
      }
    }
  } catch (e) {
    // snackbar({
    //   message: "Some error occured. Try again",
    //   color: "danger",
    // });
    console.log("Error at Listing Appointments: ", e);
    return null;
  }
}
