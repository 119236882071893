import { useState } from "react";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";

function CropProfile({ src, setSrc, onSubmit }) {
  const [crop, setCrop] = useState({ aspect: 1 });
  const [image, setImage] = useState(null);
  const [output, setOutput] = useState(null);
  const [error, setError] = useState(false);

  const selectImage = (file) => {
    setSrc(URL.createObjectURL(file));
  };

  const cropImageNow = () => {
    const canvas = document.createElement("canvas");
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext("2d");

    const pixelRatio = window.devicePixelRatio;
    canvas.width = crop.width * pixelRatio;
    canvas.height = crop.height * pixelRatio;
    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx.imageSmoothingQuality = "high";

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );

    // Converting to base64
    const base64Image = canvas.toDataURL("image/jpeg");
    if (base64Image === "data:,") {
      setError(true);
    } else {
      onSubmit("logoLink", base64Image);
      setOutput(base64Image);
    }
  };

  return (
    <div className="App">
      <center>
        <input
          style={{ display: "block" }}
          type="file"
          accept="image/*"
          onChange={(e) => {
            selectImage(e.target.files[0]);
          }}
        />
        <br />
        <br />
        {src && (
          <div className="image-cropper">
            <ReactCrop
              src={src}
              onImageLoaded={setImage}
              crop={crop}
              onChange={setCrop}
            />
            <br />
            {error && (
              <div className="mbsc-ios mbsc-error-message">
                {" "}
                Please select the image area you want to crop
              </div>
            )}
            <br />
            <div className="d-flex align-items-center justify-content-center gap-2 w-100">
              <button onClick={() => setSrc(null)} className="docg-outline-btn">
                Cancel
              </button>
              <button className="docg-save-btn" onClick={cropImageNow}>
                Crop
              </button>
            </div>
            <br />
            <br />
          </div>
        )}

        <div>{output && <img src={output} />}</div>
      </center>
    </div>
  );
}

export default CropProfile;
