import { Textarea } from "@mobiscroll/react5";
import React from "react";
import Avatar from "react-avatar";
import { Card, Modal } from "react-bootstrap";
import CropProfile from "src/commons/CropProfile";
import changepic from "../../../assets/change-profile-img.svg";

const Profile = ({
  data,
  setData,
  edit,
  setEdit,
  edited,
  setEdited,
  cancelOnClick,
  editOnClick,
  _renderAction,
  handleChange,
  handleProfileChange,
  handleImageUpload,
}) => {
  const selectImage = (file) => {
    setImg(URL.createObjectURL(file));
  };
  const [img, setImg] = React.useState(null);

  console.log(img);

  return (
    <>
      <Modal
        show={img}
        onHide={() => setImg(null)}
        backdrop="static"
        keyboard={false}
        dialogClassName="docg-modal"
        size="lg"
        centered
      >
        <Modal.Header>Crop and save image</Modal.Header>
        <Modal.Body className="overflow-auto">
          <CropProfile
            src={img}
            setSrc={setImg}
            onSubmit={(keyword, file) => {
              handleImageUpload(keyword, file);
              setImg(null);
            }}
          />
        </Modal.Body>
      </Modal>
      <Card
        style={{ overflow: "auto" }}
        className="docg-card bg-white docg-vh-standard p-2 docg-profile-text"
      >
        <div className="mbsc-row">
          <div className="mbsc-col-12 d-flex justify-content-center align-items-center mt-2">
            <div className="profile-img-wrap">
              <div className="profile-image">
                {data?.logoLink && (
                  <Avatar
                    color="#c06c84"
                    round
                    size="5rem"
                    name={data?.name}
                    src={data?.logoLink}
                  />
                )}
              </div>
              <label
                for="profile-picture-change"
                className="profile-change-icon"
              >
                <img src={changepic} alt="" />
              </label>
              <input
                type="file"
                id="profile-picture-change"
                onChange={(e) => selectImage(e.target.files[0])}
                accept="image/png, image/jpeg"
              />
            </div>
          </div>
          <div className="mbsc-col-12 mt-2">
            <div className="profile-title d-flex text-center align-items-center justify-content-center">
              <span>{data?.name}</span>
            </div>
          </div>
          <div className="mbsc-col-12 mt-1">
            <div className="profile-speciality">{data?.description}</div>
          </div>
          <div className="mbsc-col-12 mt-3">
            <label className="label_style">Location:</label>
            {edit ? (
              <Textarea
                name="address"
                inputStyle="underline"
                labelStyle="floating"
                value={edited?.address}
                onChange={handleChange}
              ></Textarea>
            ) : (
              <p>{data?.address ? data.address : "-"}</p>
            )}
            <hr />
          </div>

          <div className="mbsc-col-12 mt-3">
            <label className="label_style">Medical Details:</label>
            <p>
              Registration No. :{" "}
              {data?.registrationNumber ? data.registrationNumber : "-"}
            </p>
            <hr />
          </div>
          <div className="mbsc-col-12 mt-3">
            <label className="label_style">Email:</label>
            <p>{data?.mail ? data.mail : "-"}</p>
            <hr />
          </div>
          <div className="mbsc-col-12 mt-3">
            <label className="label_style">Helpline Number:</label>
            <p>{data?.helplineNumber ? data.helplineNumber : "-"}</p>
            <hr />
          </div>
          <div className="mbsc-col-12 mt-3">
            <a
              href={data?.contract}
              target="_blank"
              className="text-pink"
              rel="noreferrer"
            >
              Signed Contract
            </a>
          </div>
        </div>
      </Card>
    </>
  );
};

export default Profile;
