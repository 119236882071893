import React, { Fragment, useState } from "react";
import { useHistory } from "react-router-dom";
import { Row, Col, Card, Button, Image } from "react-bootstrap";
import { AgGridColumn, AgGridReact } from "ag-grid-react";
import ActionCellRender from "./ActionCellRender";
import Membership from "src/assets/membership.svg";
import mobiscroll from "@mobiscroll/react";
import "@mobiscroll/react/dist/css/mobiscroll.min.css";

mobiscroll.settings = {
  theme: "ios",
  themeVariant: "light",
};

const { Header: CardHeader, Body: CardBody, Title: CardTitle } = Card;

const DataListingCard = ({ rowData = [] }) => {
  const history = useHistory();

  // eslint-disable-next-line
  const [gridApi, setGridApi] = useState(null);
  // eslint-disable-next-line
  const [gridColumnApi, setGridColumnApi] = useState(null);
  const [displayRecords, setDisplayRecords] = useState("02");

  const onGridReady = (params) => {
    console.log(params);
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
  };

  const onQuickFilterChanged = () => {
    gridApi.setQuickFilter(document.getElementById("quickFilter").value);
  };

  const onPageSizeChanged = (e) => {
    setDisplayRecords(e.valueText);
    gridApi.paginationSetPageSize(Number(e.valueText));
  };

  const defaultColDef = {
    sortable: true,
    resizable: false,
    filter: true,
    flex: 1,
    minWidth: 100,
  };

  // if we had column groups, we could provide default group items here
  const defaultColGroupDef = {};

  const routeChange = () => {
    history.push("/add-new-appointment");
  };
  return (
    <Fragment>
      <Card className="docg-card docg-appointment">
        <CardHeader className="docg-card-header">
          <Row className="d-flex justify-content-between align-items-center">
            <Col xs={12} sm={12} md={6} lg={6} xl={6}>
              <CardTitle className="docg-card-title">
                <Image src={Membership} alt="" className="mr-2" /> Membership
                Plans
              </CardTitle>
            </Col>
            <Col
              xs={12}
              sm={12}
              md={6}
              lg={6}
              xl={6}
              className="d-flex justify-content-end align-items-center"
            >
              <div className="docg-table-search">
                <input
                  type="text"
                  onInput={() => onQuickFilterChanged()}
                  id="quickFilter"
                  placeholder="Search..."
                />
              </div>
              <Button className="theme-button btn-xs" onClick={routeChange}>
                <span className="material-icons pr-1">add_circle_outline</span>{" "}
                Add
              </Button>
            </Col>
          </Row>
        </CardHeader>

        <CardBody className="docg-card-body p-0">
          {/* <div className="records-display">
            <span>Showing&nbsp;</span>
            <label className="record-selection-dropdown" htmlFor="">
              <mobiscroll.Select
                touchUi={false}
                onChange={(e) => {
                  onPageSizeChanged(e);
                }}
                value={displayRecords}
              >
                {Array.from(Array(rowData.length).keys()).map((index) => {
                  let value = index + 1 < 10 ? `0${index + 1}` : index + 1;
                  return <option value={value}>{value}</option>;
                })}
              </mobiscroll.Select>
              <i className="fas fa-chevron-down"></i>
            </label>
            <span>&nbsp;Records</span>
          </div> */}
          <Row>
            <Col>
              <div
                className="ag-theme-alpine"
                style={{
                  height: "100%",
                  width: "100%",
                }}
              >
                <AgGridReact
                  rowData={rowData}
                  defaultColDef={defaultColDef}
                  frameworkComponents={{
                    actionCellRender: ActionCellRender,
                  }}
                  defaultColGroupDef={defaultColGroupDef}
                  enableRangeSelection={true}
                  pagination={true}
                  onGridReady={onGridReady}
                  paginationPageSize={displayRecords}
                  domLayout={"autoHeight"}
                  animateRows={true}
                >
                  <AgGridColumn
                    headerName="Plan Type"
                    headerTooltip="Plan Type"
                    field="name"
                    tooltipField="name"
                    initialPinned="left"
                    initialSort="asc"
                  ></AgGridColumn>
                  <AgGridColumn
                    headerName="Price"
                    headerTooltip="Price"
                    field="price"
                    tooltipField="price"
                  ></AgGridColumn>
                  <AgGridColumn
                    headerName="Paid"
                    headerTooltip="Paid"
                    field="paymentFrequency"
                    tooltipField="paymentFrequency"
                  ></AgGridColumn>
                  <AgGridColumn
                    headerName="Visits"
                    headerTooltip="Visits"
                    field="numberOfVisits"
                    tooltipField="numberOfVisits"
                  ></AgGridColumn>
                  <AgGridColumn
                    headerName="Validity"
                    headerTooltip="Validity"
                    valueGetter={(params) =>
                      params.data.validityTime +
                      " " +
                      params.data.validityTimeUnit
                    }
                    tooltipValueGetter={(params) =>
                      params.data.validityTime +
                      " " +
                      params.data.validityTimeUnit
                    }
                  ></AgGridColumn>
                  <AgGridColumn
                    headerName="Members"
                    headerTooltip="Members"
                    field="numberOfMembers"
                    tooltipField="numberOfMembers"
                  ></AgGridColumn>
                  <AgGridColumn
                    headerName=""
                    valueGetter={(params) => {
                      return {
                        status: params.data.status,
                        uploadedFile: params?.data?.uploadedFile,
                      };
                    }}
                    tooltipValueGetter={(params) => {
                      return {
                        status: params.data.status,
                        uploadedFile: params?.data?.uploadedFile,
                      };
                    }}
                    cellRenderer="actionCellRender"
                    filter={false}
                    sortable={false}
                    minWidth={200}
                  ></AgGridColumn>
                </AgGridReact>
              </div>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </Fragment>
  );
};

export default DataListingCard;
