import {
  getQueryWithParams,
  getQuery,
  postMutation,
} from "src/components/ApolloConnection/ApolloProviderComponent";
import { LIST_ALL_NOTIFICATIONS } from "src/components/graphql/GraphQLquery";
import { snackbar } from "@mobiscroll/react5";
import { ADD_PATIENT_BY_DOCTOR } from "src/components/graphql/GraphQLmutation";

export async function addPatientByDoctor(data) {
  try {
    let response = await postMutation(ADD_PATIENT_BY_DOCTOR, data);
    if (
      response.data !== null &&
      response?.data?.addPatientByDoctor?.statusCode >= 200 &&
      response?.data?.addPatientByDoctor?.statusCode < 300
    ) {
      snackbar({
        message:
          response?.data?.addPatientByDoctor?.message ||
          "Patient Added Successfully.",
        color: "success",
      });
      return response?.data?.addPatientByDoctor?.data;
    } else {
      if (response?.data?.addPatientByDoctor.message)
        throw new Error(response?.data?.addPatientByDoctor.message);
      else
        throw new Error(
          "Some error occured while adding Patient. Try again later."
        );
    }
  } catch (e) {
    console.log(e.message);
    snackbar({
      message: e.message,
      color: "danger",
    });
    return null;
  }
}
