import React from "react";
import { Card } from "react-bootstrap";

const SmallCard = () => {
  return (
    <>
      <Card>
        <Card.Title>Card Title</Card.Title>
        <Card.Body>Card Body</Card.Body>
      </Card>
    </>
  );
};

export default SmallCard;
