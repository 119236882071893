import React from "react";
import Avatar from "react-avatar";

const DoctorNameRender = (params) => {
  return (
    <>
      <div>
        <Avatar
          color="#c06c84"
          round
          size="35"
          name={`${params.data.doctor?.firstName} ${params.data.doctor.lastName}`}
          src={params.data.doctor.profilePicture}
        />
        <span className="ml-2">
          {params.data.doctor.firstName !== undefined
            ? `${params.data.doctor.firstName} ${params.data.doctor.lastName}`
            : ""}
        </span>
      </div>
    </>
  );
};

export default DoctorNameRender;
