import { snackbar } from "@mobiscroll/react5";
import {
  getQuery,
  getQueryResponse,
  getQueryWithParams,
  postMutation,
} from "src/components/ApolloConnection/ApolloProviderComponent";
import {
  ADD_APPOINTMENT_BY_DOCTOR,
  CLOSE_TIME_SLOT,
  OPEN_TIME_SLOT,
} from "src/components/graphql/GraphQLmutation";
import {
  GET_DOCTOR_ID,
  GET_DOCTOR_POLICIES,
  LIST_CLINIC_PATIENTS,
  LIST_PATIENTS_CALENDAR,
  LIST_TIME_SLOTS,
} from "src/components/graphql/GraphQLquery";

export async function getDoctorPolicies(data) {
  try {
    let response = await getQueryWithParams(GET_DOCTOR_POLICIES, data);
    if (typeof response == "undefined") {
      throw new Error(response);
    } else {
      if (
        response.data !== null &&
        response.data &&
        response?.data?.getDoctor?.data
      ) {
        return response.data.getDoctor.data;
      } else {
        throw new Error(response);
      }
    }
  } catch (error) {
    console.log("ERROR at list Schedules", error);
    return false;
  }
}

export async function listTimeSlots(data) {
  try {
    console.log(data);
    let response = await getQueryWithParams(LIST_TIME_SLOTS, data);
    console.log(response);
    if (typeof response == "undefined") {
      throw new Error(response);
    } else {
      if (
        response.data !== null &&
        response.data &&
        response?.data?.listTimeslots?.data
      ) {
        return response.data.listTimeslots.data;
      } else {
        throw new Error(response);
      }
    }
  } catch (error) {
    console.log("ERROR at list Schedules", error);
    return false;
  }
}
export async function getTimeSlots(data) {
  try {
    console.log(data);
    const response = await listTimeSlots(data);
    console.log(response);
    if (response) {
      return storeTimeSlots(response);
    }
  } catch (err) {
    console.log("ERROR at list Time Slots", err);
    return [];
  }
}
function storeTimeSlots(response) {
  try {
    return response;
  } catch (err) {
    console.error("Error at storing time slots", err);
    return [];
  }
}
export async function getDoctorId() {
  try {
    let response = await getQuery(GET_DOCTOR_ID);
    if (typeof response == "undefined") {
      throw new Error(response);
    } else {
      if (
        response.data !== null &&
        response.data &&
        response?.data?.getMyProfile?.data &&
        response?.data?.getMyProfile?.data?.id
      ) {
        return response.data.getMyProfile.data.id;
      } else {
        throw new Error(response);
      }
    }
  } catch (error) {
    console.log("ERROR at get doctor id", error);
    return false;
  }
}

export async function openClosedSlot(data) {
  try {
    let response = await postMutation(OPEN_TIME_SLOT, data);
    console.log(response);
    if (typeof response == "undefined") {
      throw new Error(response);
    } else {
      // if (
      //   response.data !== null &&
      //   response.data &&
      //   response?.data?.openTimeslot?.data &&
      //   response?.data?.openTimeslot?.data?.status
      // ) {
      //   if (
      //     response.data.openTimeslot.data.status < 300 &&
      //     response.data.openTimeslot.data.status >= 200
      //   )
      return true;
      //   else {
      //     throw new Error(response);
      //   }
      // } else {
      //   throw new Error(response);
      // }
    }
  } catch (error) {
    console.log("ERROR at opening slot", error);
    return false;
  }
}

export async function closeOpenSlot(data) {
  try {
    let response = await postMutation(CLOSE_TIME_SLOT, data);
    console.log(response);
    if (typeof response == "undefined") {
      throw new Error(response);
    } else {
      if (response.data !== null && response.data) {
        if (
          response.data.closeTimeslot?.statusCode < 300 &&
          response.data.closeTimeslot?.statusCode >= 200
        )
          return true;
        else {
          throw new Error(response);
        }
      } else {
        throw new Error(response);
      }
    }
  } catch (error) {
    console.log("ERROR at closing slot", error);
    return false;
  }
}
export async function listPatients(doctorId, search) {
  let doctorIds = [];
  doctorIds.push(doctorId);
  try {
    let response = await getQueryResponse(LIST_PATIENTS_CALENDAR(search), {
      doctorIds,
    });
    if (
      response.data !== null &&
      response?.data?.listClinicPatients?.data !== null &&
      Array.isArray(response.data?.listClinicPatients?.data)
    ) {
      return response.data?.listClinicPatients?.data;
    } else {
      throw new Error("not a valid response");
    }
  } catch (e) {
    console.log(e);
    snackbar({
      message:
        "Some error occured while fetching patient list. Try again later.",
      color: "danger",
    });
    return [];
  }
}
export async function listClinicPatientsAll(search) {
  try {
    let response = await getQueryResponse(LIST_CLINIC_PATIENTS(search));
    if (
      response.data !== null &&
      response?.data?.listClinicPatientsAll?.data !== null &&
      Array.isArray(response.data?.listClinicPatientsAll?.data)
    ) {
      return response.data?.listClinicPatientsAll?.data;
    } else {
      throw new Error("not a valid response");
    }
  } catch (e) {
    console.log(e);
    snackbar({
      message:
        "Some error occured while fetching patient list. Try again later.",
      color: "danger",
    });
    return [];
  }
}

export async function addAppointmentByDoctor(data) {
  try {
    let response = await postMutation(ADD_APPOINTMENT_BY_DOCTOR, data);
    if (
      response.data !== null &&
      response?.data?.addAppointmentByDoctor?.statusCode >= 200 &&
      response?.data?.addAppointmentByDoctor?.statusCode < 300
    ) {
      return true;
    } else {
      throw new Error("not a valid response");
    }
  } catch (e) {
    console.log(e);
    snackbar({
      message:
        "Some error occured while fetching patient list. Try again later.",
      color: "danger",
    });
    return false;
  }
}
