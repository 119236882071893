export const STARTING_CONSULTATION = "STARTING_CONSULTATION";
export const SELECT_CONSULTATION = "SELECT_CONSULTATION";
export const CHANGE_ACTIVE_TAB = "CHANGE_ACTIVE_TAB";
export const PAST_CONSULTATION_DATE = "PAST_CONSULTATION_DATE";
export const initialState = {
  showConsultation: false,
  startingConsultation: false,
  activeTab: "vaccination",
  patientHistory: [],
};

export const isStartingConsultation = (isStartingConsultationValue) => ({
  type: STARTING_CONSULTATION,
  startingConsultation: isStartingConsultationValue,
});

export const handleConsultationPopupChange = (value) => ({
  type: SELECT_CONSULTATION,
  showConsultation: value,
});

export const isActiveTab = (value) => ({
  type: CHANGE_ACTIVE_TAB,
  activeTab: value,
});

export const setPastConsultationDate = (value) => ({
  type: PAST_CONSULTATION_DATE,
  pastConsultationDate: value,
});

export const patientDetailsReducer = (state, action, initialState) => {
  console.log(state, action, initialState);
  if (action.type === STARTING_CONSULTATION) {
    return {
      ...state,
      startingConsultation: action.startingConsultation,
    };
  }
  if (action.type === CHANGE_ACTIVE_TAB) {
    return {
      ...state,
      activeTab: action.activeTab,
    };
  }
  if (action.type === SELECT_CONSULTATION) {
    return {
      ...state,
      showConsultation: action.showConsultation,
    };
  }
  if (action.type === PAST_CONSULTATION_DATE) {
    return {
      ...state,
      pastConsultationDate: action.pastConsultationDate,
    };
  }
  if (action.type === "PATIENT_DETAILS") {
    console.log(action);
    return {
      ...state,
      patientDetails: { ...action.payload },
    };
  }
};
