import { Fragment } from "react";
import { Col, Row } from "react-bootstrap";
import CalanderDarkIcon from "src/assets/icons/CalenderDarkIcon.svg";
import TotalPaitent from "src/assets/icons/TotalPatient.svg";
import KPICard from "../Layouts/Card";
import "./Reporting.css";
import AppointmentsYear from "./chart/AppointmentsYear";
import PatientsYear from "./chart/PatientsYear";

import { getPatientsCount, getTotalNumberOfAppointments } from "./api";

const tableArr = [
  {
    icon: TotalPaitent,
    title: "Total Patients",
    Circular: "proggressBar pateintsPer",
    percentage: 100,
    footer: "Patients",
    showRupees: false,
    api: getPatientsCount,
  },
  {
    icon: CalanderDarkIcon,
    title: "Total Appointments",
    Circular: "proggressBar",
    percentage: 100,
    footer: "Appointments",
    showRupees: false,
    api: getTotalNumberOfAppointments,
  },
];

const Reporting = () => {
  return (
    <Fragment>
      <div className="mbsc-col-12 docg-dashboard docg-vh-standard d-flex flex-column">
        <div className="mbsc-row">
          <div className="d-flex mbsc-col-12">
            <KPICard cards={tableArr} />
          </div>
        </div>
        <Row className="d-flex w-100 justify-content-center align-content-center flex-row">
          <Col key={1} xs={12} sm={6} md={6} lg={6} xl={6}>
            <div className="reporting-div bg-white">
              <AppointmentsYear divId={"AppointmentsYear"} check={true} />
            </div>
          </Col>
          <Col key={2} xs={12} sm={6} md={6} lg={6} xl={6}>
            <div className="reporting-div bg-white">
              <PatientsYear divId={"PatientsYear"} check={true} />
            </div>
          </Col>
        </Row>
      </div>
    </Fragment>
  );
};
export default Reporting;
