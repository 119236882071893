import React, { useState, Fragment } from "react";
import { Input, Textarea } from "@mobiscroll/react5";
import ProfileEditIcon from "src/assets/profile-edit.svg";
import Heard from "src/assets/icons/Heard.svg";
import mobiscroll from "@mobiscroll/react";
import { Popup } from "@mobiscroll/react5";
import { TabNav, NavItem } from "@mobiscroll/react";
import plus_icon from "src/assets/plus-circle.svg";
import { Row, Col, Card } from "react-bootstrap";
import patient from "src/assets/person.svg";

const RightPanel = () => {
  const { Body: CardBody } = Card;

  const [activeTab, setActiveTab] = useState("about");

  return (
    <Fragment className="margin-none">
      <Card className=" docg-card docg-appointment m-0  docg-vh-standard">
        <CardBody className="m-0 p-0">
          <TabNav className="docg-tabs" theme="windows" context="#tab-content">
            <NavItem
              selected={activeTab === "about"}
              onClick={() => setActiveTab("about")}
            >
              <img src={patient} alt="" className="mr-2" />
              About
            </NavItem>
          </TabNav>
          {activeTab === "about" && <AboutSection />}
          {activeTab === "account" && <AccountSection />}
        </CardBody>
      </Card>
    </Fragment>
  );
};

const AboutSection = () => {
  const [editProfile, setEditProfile] = useState(false);
  const [editAboutMe, setEditAboutMe] = useState(false);
  const [fields, setFields] = useState({
    hospital: "Himalaya",
    certifications: "Certified Chiropractic sports Physician 1982",
    residency: "New York",
    medicalSchool: "Palmer Medical College",
    degree: "M.B.B.S",
    gender: "F",
    awards: ["sdffs"],
    experience: 12,
  });

  const [activeField, setActiveField] = useState(null);
  const [openPopup, setOpenPopup] = useState(false);
  const [placeholder, setPlaceholder] = useState("");
  const [newInputData, setNewInputData] = useState("");

  const closePopup = () => {
    setOpenPopup(false);
  };

  const data = {
    aboutMe:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
  };

  const toggleEditAbout = () => {
    if (editAboutMe !== false) {
      setEditAboutMe(false);
    } else {
      setEditAboutMe(true);
    }
  };

  const toggleEdit = () => {
    if (editProfile !== false) {
      setEditProfile(false);
    } else {
      setEditProfile(true);
    }
  };

  const handleChange = (placeholder, field) => {
    if (openPopup === false) {
      setPlaceholder(placeholder);
      setOpenPopup(true);
      setActiveField(field);
      console.log(newInputData);
    }
  };
  const handleSubmit = () => {
    console.log("dsf");
    setFields((prev) => {
      let data = prev;
      data[activeField] = data[activeField] + ", " + newInputData;
      return data;
    });
    setOpenPopup(false);
  };
  const newDataChange = (event) => {
    let data = event.target.value;
    setNewInputData(data);
  };

  return (
    <>
      <div
        style={{
          height: "calc(100% - 54px",
          background: "#fff",
          borderRadius: "0.25rem",
        }}
        className="mbsc-grid p-3"
      >
        <div className="mbsc-row">
          <div className="mbsc-col-11 docg-profile-text mb-3">
            <Textarea
              defaultValue={data.aboutMe}
              readOnly={!editAboutMe}
              className=""
            ></Textarea>
          </div>
          <div className="mbsc-col-1">
            <button className="editButton" onClick={toggleEditAbout}>
              {!editAboutMe ? (
                <img alt="" src={ProfileEditIcon} />
              ) : (
                <img alt="" src={Heard} />
              )}
            </button>
          </div>
        </div>
        <div className="mbsc-row horizontal-bar">
          <div className="mbsc-col-11">
            <p>Qualifications</p>
          </div>
          <div className="mbsc-col-1">
            <button className="editButton" onClick={toggleEdit}>
              {editProfile ? (
                <img alt="" src={Heard} />
              ) : (
                <img alt="" src={ProfileEditIcon} />
              )}
            </button>
          </div>
        </div>
        <mobiscroll.FormGroup>
          <div className="mbsc-row right-panel-input-area">
            <div className="mbsc-col-11">
              <div className="profile-list">
                <span>Hospital Affiliations :</span> {fields.hospital}
              </div>
            </div>
            <div className="mbsc-col-1">
              <button
                onClick={() => {
                  handleChange("Add New Hospital Affilitations", "hospital");
                }}
                className="editButton "
              >
                <img alt="" src={plus_icon} />
              </button>
            </div>
          </div>
          <div className="mbsc-row right-panel-input-area">
            <div className="mbsc-col-11">
              <div className="profile-list">
                {" "}
                <span> Degree*</span> : {fields.degree}
              </div>
            </div>
            <div className="mbsc-col-1">
              <button
                onClick={() => {
                  handleChange("Add New Degree", "degree");
                }}
                className="editButton"
              >
                <img alt="" src={plus_icon} />
              </button>
            </div>
          </div>
          <div className="mbsc-row right-panel-input-area">
            <div className="mbsc-col-11">
              <div className="profile-list">
                {" "}
                <span> Medical School*</span> : {fields.medicalSchool}
              </div>
            </div>
            <div className="mbsc-col-1">
              <button
                onClick={() => {
                  handleChange("Add New Medical School", "medicalSchool");
                }}
                className="editButton "
              >
                <img alt="" src={plus_icon} />
              </button>
            </div>
          </div>
          <div className="mbsc-row right-panel-input-area">
            <div className="mbsc-col-11">
              <div className="profile-list">
                {" "}
                <span>Residency* </span> : {fields.residency}
              </div>
            </div>
            <div className="mbsc-col-1">
              <button
                onClick={() => {
                  handleChange("Add New Residency", "residency");
                }}
                className="editButton "
              >
                <img alt="" src={plus_icon} />
              </button>
            </div>
          </div>
          <div className="mbsc-row right-panel-input-area">
            <div className="mbsc-col-11">
              <div className="profile-list">
                {" "}
                <span> Certifications*</span> : {fields.certifications}
              </div>
            </div>
            <div className="mbsc-col-1">
              <button
                onClick={() => {
                  handleChange("Add New Certifications", "certifications");
                }}
                className="editButton "
              >
                <img alt="" src={plus_icon} />
              </button>
            </div>
          </div>
          <div className="mbsc-row right-panel-input-area">
            <div className="mbsc-col-12">
              <div className="profile-list">
                <span>Gender*</span> : {fields.gender}
              </div>
            </div>
          </div>
          <div className="mbsc-row right-panel-input-area">
            <div className="mbsc-col-12">
              <div className="profile-list">
                <span>Year of Experience*</span> : {fields.experience}
              </div>
            </div>
          </div>
          <div className="mbsc-row right-panel-input-area">
            <div className="mbsc-col-12">
              <div className="profile-list">
                <span>Awards*</span> : {fields.awards}
              </div>
            </div>
          </div>
        </mobiscroll.FormGroup>
      </div>
      {/* <Popupxy /> */}

      <Popup
        isOpen={openPopup}
        display="center"
        fullScreen={false}
        onClose={closePopup}
        anchor={null}
        headerText={placeholder}
        style={{ background: "white" }}
      >
        <mobiscroll.Input
          placeholder={placeholder}
          onChange={newDataChange}
          name="new-data"
        ></mobiscroll.Input>
        <button className="file-upload-button" onClick={handleSubmit}>
          Add
        </button>
      </Popup>
    </>
  );
};

const AccountSection = () => {
  return (
    <div
      style={{
        height: "calc(100% - 54px",
        background: "#fff",
        borderRadius: "1.25rem",
      }}
      className="mbsc-grid p-3 "
    >
      <Card className="docg-patient-form-wrapper docg-card h-standard mr-2">
        <Card.Title className="d-flex justify-content-start">
          Change Password
        </Card.Title>
        <Card.Body className="d-flex flex-column align-items-center pb-3">
          <Row>
            <Col lg={6}>
              <Input
                value={"aloksingh123"}
                placeholder="Username"
                labelStyle="floating"
                readOnly
              />
            </Col>
            <Col>
              <button className="file-upload-button">Change Password</button>
            </Col>
          </Row>
        </Card.Body>
      </Card>
      <hr />
      <Card className="docg-patient-form-wrapper docg-card h-standard mr-2">
        <Card.Title className="d-flex justify-content-start">
          Upload/Edit Images
        </Card.Title>
        <Row>
          <Col className="mbsc-col-lg-6 mbsc-col-md-6 mbsc-col-xs-6">
            <label for="sign-uplaod" className="file-upload-button">
              Upload Signature
            </label>
          </Col>
          <Col>
            <button className="file-upload-button">Save</button>
          </Col>
        </Row>
      </Card>
    </div>
  );
};

export default RightPanel;
