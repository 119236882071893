import React, { lazy, useCallback, useState } from "react";
import { isMobileOnly } from "react-device-detect";

import {
  CalendarNav,
  CalendarNext,
  CalendarPrev,
  CalendarToday,
  Eventcalendar,
  SegmentedGroup,
  SegmentedItem,
  momentTimezone,
  setOptions,
  snackbar,
} from "@mobiscroll/react5";
import { API, graphqlOperation } from "aws-amplify";
import moment from "moment-timezone";
import { Card, OverlayTrigger, Tooltip } from "react-bootstrap";
import Loader from "src/components/ClinicDashboard/common/Loader";
import calendar from "../../../assets/calendar.svg";
import "../Schedule/schedule.scss";
import "./Calendar.scss";
import { getDoctorPolicies, getTimeSlots } from "./api";
import StatusBookedModal from "./statusBooked/StatusBookedModal";
import StatusClosedModal from "./statusClosed/StatusClosedModal";
import StatusOpenModal from "./statusOpen/StatusOpenModal";
import {
  changeCurrentDay,
  refreshSlots,
  updateConsultationType,
} from "./store/CalendarReducer";
import { useStore } from "./store/Store";
//subscribeListTimeslotBeep
import { BsInfoCircleFill } from "react-icons/bs";
import { subscribeListTimeslotBeep } from "src/components/graphql/GraphQLsubscription";
import { useHistory } from "react-router-dom";
import { subscription } from "src/components/ApolloConnection/ApolloProviderComponent";

setOptions({
  theme: "ios",
  themeVariant: "light",
});
const defaultEvents = [];

const OpenModal = lazy(() => import("./statusOpen/OpenModal"));
momentTimezone.moment = moment;

function Calendar() {
  const { globalState, dispatch } = useStore();
  const history = useHistory();

  const [calendarDays, setCalendarDays] = useState(null);
  const [view, setView] = React.useState("day");
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenClosedModal, setIsOpenClosedModal] = useState(false);
  const [isOpenBookedModal, setIsOpenBookedModal] = useState(false);
  const [data, setData] = useState({});
  const [loader, setLoader] = useState(false);

  const closeModal = () => {
    setIsOpen(false);
  };
  const openModal = () => {
    setIsOpen(true);
  };
  const openClosedModal = () => {
    setIsOpenClosedModal(true);
  };
  const closeClosedModal = () => {
    setIsOpenClosedModal(false);
  };
  const openBookedModal = () => {
    setIsOpenBookedModal(true);
  };
  const closeBookedModal = () => {
    setIsOpenBookedModal(false);
  };

  const onEventClick = React.useCallback((event) => {
    let currentEvent = event.event;
    setData(currentEvent);
    console.log("URRENT EVENTTTTTTT", isMobileOnly, currentEvent);
    // if status is open
    if (currentEvent.status === "Active") {
      openModal();
    } else if (currentEvent.status === "Closed") {
      openClosedModal();
    } else {
      if (isMobileOnly) {
        const LINK = `/patient-record/${currentEvent.patient.id}?appointmentId=${currentEvent.id}`;
        history.push(LINK);
        console.log(currentEvent);
      } else openBookedModal();
    }
  }, []);

  React.useEffect(async () => {
    setLoader(true);
    const doctorId = globalState.doctorId;
    if (
      doctorId !== null &&
      globalState.current.start &&
      globalState.current.end
    ) {
      const data = {
        doctorId,
        start: globalState.current.start,
        end: globalState.current.end,
      };
      const _data = await getTimeSlots(data);
      dispatch(refreshSlots([..._data]));
      const policies = await updatePolicies({ id: doctorId });
      console.log(policies);
      dispatch(
        updateConsultationType(policies.consultationType, policies.cashAccepted)
      );
    }
    setLoader(false);
  }, [globalState.doctorId]);

  const updatePolicies = React.useCallback(
    async (data) => {
      const res = await getDoctorPolicies(data);
      const policies = res?.doctorProfile.policy;
      const cashAccepted = policies?.Face2Face?.cashPaymentAccepted
        ? true
        : false;
      if (policies == null) {
        snackbar({ message: "No Fee Policies found!", color: "danger" });
        return { consultationType: {}, cashAccepted: false };
      }

      delete policies?.__typename;
      policies.Emergency = policies?.Emergency
        ? policies?.Emergency?.status == "Active"
          ? true
          : false
        : false;
      policies.Face2Face = policies?.Face2Face
        ? policies?.Face2Face?.status == "Active"
          ? true
          : false
        : false;
      policies.Online = policies?.Online
        ? policies?.Online?.status == "Active"
          ? true
          : false
        : false;
      return { consultationType: { ...policies }, cashAccepted };
    },
    [getDoctorPolicies]
  );

  React.useEffect(() => {
    if (
      globalState?.doctorId !== null &&
      globalState?.current?.start &&
      globalState?.current?.end
    ) {
      const doctorId = globalState.doctorId;
      subscription(
        subscribeListTimeslotBeep,
        { doctorId },
        async (response) => {
          if (
            !(
              response.value.data.subscribeListTimeslotBeep.statusCode >= 200 &&
              response.value.data.subscribeListTimeslotBeep.statusCode < 300
            ) ||
            response.value.data.subscribeListTimeslotBeep.errors != null
          ) {
            return;
          }
          setLoader(true);
          try {
            const _data = await getTimeSlots({
              doctorId,
              start: globalState?.current?.start,
              end: globalState?.current?.end,
            });
            dispatch(refreshSlots([..._data]));
          } catch (err) {
            console.log(err);
          }
          setLoader(false);

          //fetch notifications
        },
        (error) => {
          console.log(error);
        }
      );
    }
  }, [globalState.doctorId]);

  const [calView, setCalView] = React.useState({
    schedule: {
      type: "day",
      timeCellStep: 15,
      dayNames: [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
      ],
    },
  });

  const changeView = (event) => {
    let calView;

    switch (event.target.value) {
      case "month":
        calView = {
          calendar: { labels: true },
        };
        break;
      case "week":
        calView = {
          schedule: { type: "week", timeCellStep: 15 },
        };
        break;
      case "day":
        calView = {
          schedule: { type: "day", timeCellStep: 15 },
        };
        break;
    }

    setView(event.target.value);
    setCalView(calView);
  };
  const customWithNavButtons = () => {
    return (
      <React.Fragment>
        <Card.Header
          className="d-flex justify-content-between customCardHeader w-100"
          style={{ backgroundColor: "#dee2e6" }}
        >
          <Card.Title className="customCardTitle">
            <img
              style={{ maxWidth: "20px", maxHeight: "20px" }}
              src={calendar}
              className="mr-2"
              alt=""
            />{" "}
            Calendar&nbsp;
            <OverlayTrigger
              key="bottom"
              placement="bottom"
              overlay={
                <Tooltip id="tooltip-bottom">
                  <p className="d-flex align-items-center justify-content-start">
                    <span
                      style={{
                        background: "#1aab73",
                        width: "10px",
                        height: "20px",
                      }}
                    >
                      &nbsp; &nbsp;
                    </span>
                    &nbsp; - Active Slot
                  </p>
                  <p className="d-flex align-items-center justify-content-start">
                    <span
                      style={{
                        background: "#d88146",
                        width: "10px",
                        height: "20px",
                      }}
                    >
                      &nbsp;
                    </span>
                    &nbsp; - PreBooked Slot
                  </p>
                  <p className="d-flex align-items-center justify-content-start">
                    <span
                      style={{
                        background: "#c06c84",
                        width: "10px",
                        height: "20px",
                      }}
                    >
                      &nbsp; &nbsp;
                    </span>
                    &nbsp; - Booked Slot
                  </p>
                  <p className="d-flex align-items-center justify-content-start">
                    <span
                      style={{
                        background: "#757575",
                        width: "10px",
                        height: "20px",
                      }}
                    >
                      &nbsp; &nbsp;
                    </span>
                    &nbsp; - Closed Slot
                  </p>
                </Tooltip>
              }
            >
              <BsInfoCircleFill />
            </OverlayTrigger>
          </Card.Title>
          <div className="cal-header-picker d-flex align-items-center">
            <CalendarNav className="cal-header-nav" />
            <SegmentedGroup
              className="cal-header-view"
              value={view}
              onChange={changeView}
            >
              <SegmentedItem value="day">Day</SegmentedItem>
              <SegmentedItem value="week">Week</SegmentedItem>
              <SegmentedItem value="month">Month</SegmentedItem>
            </SegmentedGroup>
          </div>
          <div className="">
            <CalendarPrev className="cal-header-prev" />
            <CalendarToday className="cal-header-today" />
            <CalendarNext className="cal-header-next" />
          </div>
        </Card.Header>
      </React.Fragment>
    );
  };

  const renderScheduleEvent = useCallback((data) => {
    let color = "#757575";
    if (data.original.status === "Active") {
      color = "#1aab73";
    } else if (data.original.status === "PreBooked") {
      color = "#d88146";
    } else if (data.original.status === "Booked") {
      color = "#c06c84 ";
    } else {
      color = "#757575";
    }
    return (
      <div
        className="docg-custom-schedule-event d-flex"
        style={{
          background: color,
          color: "#fff",
        }}
      >
        <div className="docg-custom-event">{data?.original?.description}</div>
        <div className="docg-custom-event-time">
          {data?.original?.status.toLowerCase() === "prebooked" ||
          data?.original?.status.toLowerCase() === "booked" ? (
            <>
              {data?.original?.patient?.firstName}{" "}
              {data?.original.patient?.lastName}{" "}
              <span>
                {data.start} to {data.end}
              </span>
            </>
          ) : (
            `${data.start} to ${data.end}`
          )}
        </div>
      </div>
    );
  });
  const renderLabel = useCallback((data) => {
    let color = "#757575";
    if (data.original.status === "Active") {
      color = "#1aab73";
    } else if (data.original.status === "PreBooked") {
      color = "#d88146";
    } else if (data.original.status === "Booked") {
      color = "#c06c84 ";
    } else {
      color = "#757575";
    }
    return (
      <div
        className="docg-custom-schedule-event d-flex"
        style={{
          background: color,
          color: "#fff",
        }}
      >
        <div className="docg-custom-event">{data?.original?.description}</div>
        <div className="docg-custom-event-time">
          {data?.original?.status.toLowerCase() === "prebooked" ||
          data?.original?.status.toLowerCase() === "booked" ? (
            <>
              {data?.original?.patient?.firstName}{" "}
              {data?.original.patient?.lastName}
            </>
          ) : (
            `${data.start} to ${data.end}`
          )}
        </div>
      </div>
    );
  });

  async function onPageLoading(event, inst) {
    const start = new Date(event.firstDay).toISOString();
    const end = new Date(event.lastDay).toISOString();
    console.log(start, end);
    dispatch(changeCurrentDay({ start, end }));
    setLoader(true);
    const doctorId = globalState.doctorId;
    if (doctorId !== null) {
      const data = {
        doctorId,
        start,
        end,
      };
      const _data = await getTimeSlots(data);
      dispatch(refreshSlots([..._data]));
    }
    setLoader(false);
  }

  return (
    <>
      <Loader show={loader} />
      <Card className="docg-schedule-card w-100 ml-3 mr-3">
        <Eventcalendar
          renderHeader={customWithNavButtons}
          renderScheduleEvent={renderScheduleEvent}
          displayTimezone="local"
          timezonePlugin={momentTimezone}
          renderLabel={renderLabel}
          renderEventContent={renderLabel}
          clickToCreate={false}
          dragToCreate={false}
          dragToMove={false}
          dragToResize={false}
          data={globalState.timeSlots}
          view={calView}
          onEventClick={onEventClick}
          cssClass="md-switching-view-cont"
          onPageLoading={onPageLoading}
        />
      </Card>
      <StatusOpenModal isOpen={isOpen} closeModal={closeModal} data={data} />
      <StatusClosedModal
        isOpen={isOpenClosedModal}
        closeModal={closeClosedModal}
        data={data}
      />
      <StatusBookedModal
        isOpen={isOpenBookedModal}
        closeModal={closeBookedModal}
        data={data}
      />
    </>
  );
}

export default Calendar;
