import { createBrowserHistory } from "history";
import React, { Suspense, useState } from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import { Router } from "react-router-dom";
import "./App.scss";
import "./global.scss";
import Routers from "./navigators/Routers";
import { globalReducer, initialState } from "./store/GlobalReducer";
import { StoreProvider } from "./store/Store";
// yrdy

import { ReactQueryDevtools } from "react-query/devtools";
import { Modal } from "react-bootstrap";
import { Amplify } from "aws-amplify";

const App = () => {
  React.useEffect(() => {
    const appHeight = () => {
      const doc = document.documentElement;
      doc.style.setProperty("--app-height", `${window.innerHeight - 1}px`);
    };
    window.addEventListener("resize", appHeight);
    appHeight();
  }, []);

  const history = createBrowserHistory();
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        refetchOnMount: true,
      },
    },
  });

  Amplify.configure({
    API: {
      GRAPHQL: {
        endpoint: process.env.REACT_APP_AWS_APPSYNC_GRAPHQL_ENDPOINT,
        region: process.env.REACT_APP_REGION,
        defaultAuthMode: "userPool",
      },
    },
    Auth: {
      Cognito: {
        identityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID,
        userPoolId: process.env.REACT_APP_USERPOOL_ID,
        userPoolClientId: process.env.REACT_APP_USERPOOL_WEB_CLIENT,
        loginWith: {
          email: true,
          username: false,
        },
        mfa: {
          totpEnabled: false,
          smsEnabled: false,
        },
      },
    },
  });

  return (
    <>
      <QueryClientProvider client={queryClient}>
        <StoreProvider initialState={initialState} reducer={globalReducer}>
          <Suspense fallback={"Loading..."}>
            <Router history={history}>
              <Routers />

              <ReactQueryDevtools
                initialIsOpen={false}
                toggleButtonProps={{ style: { opacity: 0.2 } }}
              />
            </Router>
          </Suspense>
        </StoreProvider>
      </QueryClientProvider>
    </>
  );
};

export default App;
