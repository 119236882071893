import React, { useState } from "react";
import { useFormik } from "formik";
import { Card } from "react-bootstrap";
import { Input, snackbar } from "@mobiscroll/react5";
import Loader from "src/components/ClinicDashboard/common/Loader";
import * as Yup from "yup";
import { postMutation } from "src/components/ApolloConnection/ApolloProviderComponent";
import { SEND_MAIL_INVITE } from "src/components/graphql/GraphQLmutation";

const initialValues = {
  name: "",
  email: "",
  phoneNumber: "",
};

let message = "This field is required";

const validationSchema = Yup.object().shape({
  name: Yup.string().required(message),
  email: Yup.string().email("Enter valid Email").required(message),
  phoneNumber: Yup.string()
    .matches(
      /^[6789][0-9]{9}$/,
      "Enter Valid 10 digit Mobile Number without country code"
    )
    .required(message),
});

const SingleInvite = () => {
  const [loader, setLoader] = React.useState(false);
  const [file, setFile] = useState(null);

  const {
    values,
    touched,
    errors,
    handleSubmit,
    handleChange,
    handleBlur,
    setFieldValue,
    handleReset,
    isValid,
  } = useFormik({
    initialValues,
    onSubmit: async (values) => {
      setLoader(true);
      try {
        const res = await postMutation(SEND_MAIL_INVITE, {
          ...values,
          phoneNumber: "+91" + values.phoneNumber,
        });
        if (
          res?.data &&
          res?.data?.sendMailInvite?.statusCode >= 200 &&
          res?.data?.sendMailInvite?.statusCode <= 300
        ) {
          snackbar({
            message: "Invite sent successfully",
            color: "success",
          });
          handleReset();
        } else {
          throw new Error(res?.data?.sendMailInvite?.message);
        }
      } catch (err) {
        let msg = err.message ?? "Something went wrong";
        snackbar({
          message: msg,
          color: "danger",
        });
      }
      setLoader(false);
    },
    validationSchema,
  });

  return (
    <>
      <Loader show={loader} />
      <Card className="docg-card docg-appointment patient-wrapper card-wrapper m-0">
        <Card.Header className="d-flex justify-content-between cardHeaderUsed">
          <Card.Title className="cardTitleUsed">
            Invite Individual Patient
          </Card.Title>
        </Card.Header>
        <Card.Body className="docg-card-body docg-h-standard">
          <form action="post" onSubmit={handleSubmit} className="">
            <div className="row">
              <div className="col-12">
                <Input
                  inputStyle="underline"
                  labelStyle="floating"
                  label="Patient Name*"
                  type="text"
                  name="name"
                  value={values.name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.name && errors.name}
                  errorMessage={touched.name ? errors.name : ""}
                />
              </div>
              <div className="col-12">
                <Input
                  inputStyle="underline"
                  labelStyle="floating"
                  label="Email*"
                  type="text"
                  name="email"
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.email && errors.email}
                  errorMessage={touched.email ? errors.email : ""}
                />
              </div>
              <div className="col-12">
                <Input
                  inputStyle="underline"
                  labelStyle="floating"
                  label="Phone number*"
                  type="number"
                  name="phoneNumber"
                  value={values.phoneNumber}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.phoneNumber && errors.phoneNumber}
                  errorMessage={touched.phoneNumber ? errors.phoneNumber : ""}
                />
              </div>
            </div>

            <button
              disabled={!isValid}
              type="submit"
              className={`docg-save-btn ${!isValid && "docg-disabled mt-2"}`}
            >
              Submit
            </button>
          </form>
        </Card.Body>
      </Card>
    </>
  );
};

export default SingleInvite;
