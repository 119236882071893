import { Card, CardContent, NavItem, TabNav } from "@mobiscroll/react";
import { Fragment, Suspense, lazy, useEffect, useMemo } from "react";
import { useLocation } from "react-router-dom";
import Note from "src/assets/icons/Note.svg";
import Vaccination from "src/assets/icons/Vaccination.svg";
import "./patient.scss";
import { isActiveTab } from "./store/PatientDetailsReducer";
import { useStore } from "./store/Store";

const NotesTab = lazy(() => import("./Tabs/notes/NotesTab"));
const VaccinationTab = lazy(() => import("./Tabs/vaccination/VaccinationTab"));

function useQuery() {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
}

const PatientTabs = (props) => {
  const { globalState, dispatch } = useStore();
  let query = useQuery();
  const appointmentId = query.get("appointmentId");

  useEffect(() => {
    if (appointmentId) dispatch(isActiveTab("notes"));
  }, []);
  return (
    <Fragment>
      <Card className="docg-card docg-patient-card m-0 mb-1">
        <CardContent className="docg-card-content docg-patient-bar">
          <TabNav className="docg-tabs" theme="windows" context="#tab-content">
            {!!appointmentId && (
              <NavItem
                selected={globalState.activeTab === "notes"}
                onClick={() => dispatch(isActiveTab("notes"))}
              >
                <img src={Note} alt="" />
                Notes
              </NavItem>
            )}
            <NavItem
              selected={globalState.activeTab === "vaccination"}
              onClick={() => dispatch(isActiveTab("vaccination"))}
            >
              <img src={Vaccination} alt="" />
              Vaccination
            </NavItem>
          </TabNav>
          <div className="docg-patient-body">
            {globalState.activeTab === "notes" && !!appointmentId && (
              <Suspense fallback={<div>Loading....</div>}>
                <NotesTab {...props} />
              </Suspense>
            )}

            {globalState.activeTab === "vaccination" && (
              <Suspense fallback={<div>Loading....</div>}>
                <VaccinationTab {...props} />
              </Suspense>
            )}
          </div>
        </CardContent>
      </Card>
    </Fragment>
  );
};

export default PatientTabs;
