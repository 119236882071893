export const ADD_SCHEDULE = "ADD_SCHEDULE";
const CHANGE_CURRENT_DAY = "CHANGE_CURRENT_DAY";
const UPDATE_DOCTOR_ID = "UPDATE_DOCTOR_ID";

export const initialState = {
  schedules: [],
  doctorId: null,
};
export const replaceSchedule = (schedule) => ({
  type: ADD_SCHEDULE,
  schedule,
});

export const changeCurrentDay = (current) => ({
  type: CHANGE_CURRENT_DAY,
  current,
});
export const changeDoctorId = (doctorId) => ({
  type: UPDATE_DOCTOR_ID,
  doctorId,
});

export const scheduleReducer = (state, action, initialState) => {
  console.log(state, action, initialState);
  if (action.type === ADD_SCHEDULE) {
    return {
      ...state,
      schedules: action.schedule,
    };
  }
  if (action.type === CHANGE_CURRENT_DAY) {
    return {
      ...state,
      current: action.current,
    };
  }
  if (action.type === UPDATE_DOCTOR_ID) {
    return {
      ...state,
      doctorId: action.doctorId,
    };
  }
};
