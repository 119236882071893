import mobiscroll from "@mobiscroll/react";
import {
  CalendarNav,
  CalendarNext,
  CalendarPrev,
  CalendarToday,
  Checkbox,
  Datepicker,
  Eventcalendar,
  Input,
  Radio,
  RadioGroup,
  SegmentedGroup,
  SegmentedItem,
  Select,
  formatDate,
  momentTimezone,
  setOptions,
  snackbar,
} from "@mobiscroll/react5";
import moment from "moment-timezone";
import React, { useCallback } from "react";
import { Card, Modal } from "react-bootstrap";
import Loader from "src/components/ClinicDashboard/common/Loader";
import cancel from "../../../assets/cancel.svg";
import AddIcon from "../../../assets/icons/plusIcon.svg";
import AddWhiteIcon from "../../../assets/icons/plusWhite.svg";
import schedule from "../../../assets/icons/scheduleDark.svg";
import Recurring from "./ScheduleHandler";
import { addSchedule, getData } from "./api/index";
import CancelScheduleCurrent from "./deleteSchedule/StatusBookedModal";
import CancelScheduleRecurring from "./deleteScheduleRecurring/StatusBookedModal";
import "./schedule.scss";
import { changeCurrentDay, replaceSchedule } from "./store/ScheduleReducer";
import { useStore } from "./store/Store";
import { isMobileOnly } from "react-device-detect";

setOptions({
  theme: "ios",
  themeVariant: "light",
});

const defaultEvents = [];

// setup Mobiscroll Moment plugin
momentTimezone.moment = moment;
const responsivePopup = {
  medium: {
    display: "anchored",
    width: 600,
    maxHeight: 370,
    fullScreen: false,
    touchUi: false,
  },
};
const selectResponsive = {
  xsmall: {
    touchUi: false,
  },
  small: {
    touchUi: false,
  },
};

const days = [
  {
    name: "Sun",
    value: "SU",
    checked: true,
  },
  {
    name: "Mon",
    value: "MO",
    checked: false,
  },
  {
    name: "Tue",
    value: "TU",
    checked: false,
  },
  {
    name: "Wed",
    value: "WE",
    checked: false,
  },
  {
    name: "Thu",
    value: "TH",
    checked: false,
  },
  {
    name: "Fri",
    value: "FR",
    checked: false,
  },
  {
    name: "Sat",
    value: "ST",
    checked: false,
  },
];

const dateInputProps = {
  className: "custom-repeat-input custom-specific-date",
  inputStyle: "outline",
};

function App() {
  const { globalState, dispatch } = useStore();
  const [loader, setLoader] = React.useState(false);
  const [tempEvent, setTempEvent] = React.useState(null);
  const [isOpen, setOpen] = React.useState(false);
  const [isEdit, setEdit] = React.useState(false);
  const [anchor, setAnchor] = React.useState(null);
  const [start, startRef] = React.useState(null);
  const [end, endRef] = React.useState(null);
  const [popupEventTitle, setTitle] = React.useState("Open Schedule");
  const [popupSlotDuration, setSlot] = React.useState("");
  const [popupGap, setGap] = React.useState("");
  const [popupEventDate, setDate] = React.useState([]);
  const [mySelectedDate, setSelectedDate] = React.useState(new Date());
  const [view, setView] = React.useState("day");
  const [recurring, setRecurring] = React.useState(false);
  const [cancelSchedule, setCancelSchedule] = React.useState(false);
  const [cancelScheduleRecurring, setCancelScheduleRecurring] =
    React.useState(false);
  const [invalidData, setInvalidData] = React.useState([]);

  // recurring editor data
  const [repeatData, setRepeatData] = React.useState([
    {
      value: "norepeat",
      text: "Does not repeat",
    },
    {
      value: "custom",
      text: "Custom",
    },
  ]);
  const [selectedRepeat, setSelectedRepeat] = React.useState("norepeat");
  const [repeatType, setRepeatType] = React.useState("daily");
  const [repeatNr, setRepeatNr] = React.useState(1);
  const [condition, setCondition] = React.useState("until");
  const [untilDate, setUntilDate] = React.useState();
  const [selectedMonth, setMonth] = React.useState(1);
  const [weekDays, setWeekDays] = React.useState([...days]);

  const blockPastDate = React.useCallback((start) => {
    setInvalidData([
      {
        start: start,
        end: new Date().toISOString(),
        allDay: false,
        title: "Blocked",
      },
    ]);
  }, []);

  React.useEffect(() => {
    console.log(globalState);
    if (globalState.current) {
      if (globalState.doctorId !== null) {
        blockPastDate(globalState.current.start);
      } else {
        setInvalidData([
          {
            start: globalState.current.start,
            end: globalState.current.end,
            allDay: false,
            title: "Blocked",
          },
        ]);
      }
    }
  }, [globalState]);

  // set custom values to default
  const resetCustomValues = React.useCallback(
    (recurring) => {
      setRepeatType("daily");
      setRepeatNr(1);
      setCondition("until");
      setMonth(1);
      const newWeekDays = [...days];
      for (let i = 0; i < newWeekDays.length; i++) {
        const day = newWeekDays[i];
        day.checked = day.value === "SU";
      }
      setWeekDays(newWeekDays);
      setSelectedRepeat("norepeat");
      setRepeatData(repeatData.filter((item) => item.value !== "custom-value"));
    },
    [repeatData]
  );

  const navigateTo = React.useCallback(() => {
    const rec = tempEvent.recurrence;
    const d = new Date(tempEvent.start);
    // navigate the calendar to the correct view
    if (rec && rec.repeat === "yearly") {
    } else {
      setSelectedDate(d);
    }
  }, [tempEvent]);

  const repeatChange = React.useCallback((ev) => {
    setSelectedRepeat(ev.value);
  }, []);

  const repeatTypeChange = React.useCallback((ev) => {
    setRepeatType(ev.target.value);
  }, []);

  const repeatNrChange = React.useCallback((ev) => {
    setRepeatNr(ev.target.value);
  }, []);

  const weekDayChange = React.useCallback(
    (ev) => {
      weekDays.find((i) => i.value === ev.target.value).checked =
        ev.target.checked;
      setWeekDays([...weekDays]);
    },
    [weekDays]
  );

  const conditionChange = React.useCallback((ev) => {
    setCondition(ev.target.value);
  }, []);

  const untilDateChange = React.useCallback((ev) => {
    setUntilDate(ev.value);
  }, []);

  const [calView, setCalView] = React.useState({
    schedule: {
      type: "day",
      timeCellStep: 15,
      dayNames: [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
      ],
      monthNames: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
    },
  });

  const changeView = (event) => {
    let calView;

    switch (event.target.value) {
      case "month":
        calView = {
          calendar: { labels: true },
        };
        break;
      case "week":
        calView = {
          schedule: { type: "week", timeCellStep: 15 },
        };
        break;
      case "day":
        calView = {
          schedule: {
            type: "day",
            timeCellStep: 15,
            monthNames: [
              "Jan",
              "Feb",
              "Mar",
              "Apr",
              "May",
              "Jun",
              "Jul",
              "Aug",
              "Sep",
              "Oct",
              "Nov",
              "Dec",
            ],
          },
        };
        break;
    }

    setView(event.target.value);
    setCalView(calView);
  };
  const customWithNavButtons = () => {
    return (
      <React.Fragment>
        <Card.Header
          className="d-flex justify-content-between customCardHeader w-100"
          style={{ backgroundColor: "#dee2e6" }}
        >
          <div className="d-flex">
            <Card.Title className="customCardTitle d-flex align-items-center gap-3">
              <img
                style={{ maxWidth: "20px", maxHeight: "20px" }}
                src={schedule}
                className="mr-2"
                alt=""
              />{" "}
              Schedule
            </Card.Title>
            {!!globalState?.doctorId && (
              <button
                onClick={() => {
                  setEdit(false);
                  setOpen(true);
                  const startDate = new Date();
                  const endDate = new Date();
                  endDate.setHours(endDate.getHours() + 1);
                  setSlot(5);
                  setGap(0);
                  setDate([startDate, endDate]);
                  setUntilDate(
                    formatDate(
                      "YYYY-MM-DD",
                      new Date(
                        startDate.getFullYear(),
                        startDate.getMonth(),
                        startDate.getDate() + 1
                      )
                    )
                  );
                }}
                // className="docg-outline-btn"
                style={{
                  padding: "3px",
                  fontSize: "12px",
                  borderRadius: "100%",
                }}
              >
                <img className="" src={AddIcon} alt="" />
              </button>
            )}
          </div>
          <div className="cal-header-picker d-flex align-items-center">
            <CalendarNav className="cal-header-nav" />
            {isMobileOnly ? (
              <></>
            ) : (
              <>
                <SegmentedGroup value={view} onChange={changeView}>
                  <SegmentedItem value="day">Day</SegmentedItem>

                  <SegmentedItem value="week">Week</SegmentedItem>
                  <SegmentedItem value="month">Month</SegmentedItem>
                </SegmentedGroup>
              </>
            )}
          </div>
          <div className="">
            <CalendarPrev className="cal-header-prev" />
            <CalendarToday className="cal-header-today" />
            <CalendarNext className="cal-header-next" />
          </div>
        </Card.Header>
      </React.Fragment>
    );
  };

  const renderScheduleEvent = useCallback((data) => {
    return (
      <div
        className="docg-custom-schedule-event d-flex"
        style={{
          background: "#1AAB73",
          color: "#fff",
        }}
      >
        <div className="docg-custom-event-title">Open Schedule</div>
        <div className="docg-custom-event-time">
          {data.start} to {data.end}
        </div>
      </div>
    );
  });
  const renderLabel = useCallback((data) => {
    return (
      <div
        className="docg-custom-schedule-event d-flex"
        style={{
          background: "#1AAB73",
          color: "#fff",
        }}
      >
        <div className="docg-custom-event-title">Open Schedule</div>
      </div>
    );
  });

  const saveEvent = React.useCallback(async () => {
    setOpen(false);
    setLoader(true);
    let recurringRule;
    switch (selectedRepeat) {
      case "custom":
      case "custom-value":
        recurringRule = {
          repeat: repeatType,
          interval: repeatNr,
        };

        switch (repeatType) {
          case "weekly":
            let _weekDays = [];
            weekDays
              .filter((i) => i.checked)
              .map((i) => {
                _weekDays.push(i.value);
                return i.value;
              });
            recurringRule.weekDays = _weekDays;

            break;

          default:
        }

        switch (condition) {
          case "until":
            recurringRule.until = new Date(untilDate).toISOString();
            break;
          default:
        }
        break;
      default:
    }

    const newEvent = {
      start: popupEventDate[0].toISOString(),
      end: popupEventDate[1].toISOString(),
      duration: popupSlotDuration,
      gap: popupGap,
      recurrence: typeof recurringRule == "undefined" ? null : recurringRule,
    };
    if (isEdit) {
      // ...
    } else {
      setLoader(true);
      if (globalState.doctorId != null) {
        const doctorId = globalState.doctorId;
        const res = await addSchedule({ ...newEvent, doctorId });
        if (res) {
          setLoader(true);
          const data = await getData(
            globalState.doctorId,
            globalState?.current?.start,
            globalState?.current?.end
          );
          dispatch(replaceSchedule([...data]));
          console.log("over", data);
          console.log(start, end);
          setLoader(false);
        }
        setLoader(false);
      } else {
        dispatch(replaceSchedule([...globalState.schedules]));
        setLoader(false);
      }
    }

    // navigate the calendar
    navigateTo();
    // close the popup
  }, [
    isEdit,
    globalState.schedules,
    globalState,
    popupEventDate,
    popupEventTitle,
    popupSlotDuration,
    popupGap,
    tempEvent,
    navigateTo,
    condition,
    repeatNr,
    repeatType,
    selectedMonth,
    selectedRepeat,
    untilDate,
    weekDays,
  ]);

  const deleteEvent = React.useCallback(
    (event) => {
      //   setglobalState.schedules(myEvents.filter((item) => item.id !== event.id));
      setTimeout(() => {
        snackbar({
          button: {
            action: () => {
              //   setMyEvents((prevEvents) => [...prevEvents, event]);
            },
            text: "Undo",
          },
          message: "Event deleted",
        });
      });
    },
    [globalState.schedules]
  );
  const loadPopupForm = React.useCallback(
    (event) => {
      const startDate = new Date(event.start);
      const endDate = new Date(event.end);
      setSlot(event.duration);
      setGap(event.gap);
      setDate([startDate, endDate]);
      setUntilDate(
        formatDate(
          "YYYY-MM-DD",
          new Date(
            startDate.getFullYear(),
            startDate.getMonth(),
            startDate.getDate() + 1
          )
        )
      );
      const d = new Date(event.start);
      const weekday = d.getDay();
      const monthday = d.getDate();
      const newData = repeatData.slice(0);

      // update select texts by selected date
      for (let i = 0; i < newData.length; ++i) {
        var item = newData[i];
        switch (item.value) {
          case "weekly":
            item.text = "Weekly on " + days[weekday].name;
            break;
          case "monthly":
            item.text = "Monthly on day " + monthday;
            break;

          default:
        }
      }

      setRepeatData(newData);

      const rec = event.recurrence;

      if (rec) {
        setRepeatType(rec.repeat);
        if (rec.interval) {
          // set custom text
          let customText = "";
          const nr = rec.interval;
          setRepeatNr(nr);

          switch (rec.repeat) {
            case "weekly":
              const newWeekDays = [...days];
              const weekD = rec.weekDays;
              for (let i = 0; i < newWeekDays.length; i++) {
                const day = newWeekDays[i];
                day.checked = weekD.includes(day.value);
              }

              setWeekDays(newWeekDays);
              customText = nr > 1 ? "Every " + nr + " weeks" : "Weekly";
              customText +=
                " on " +
                newWeekDays
                  .filter((i) => i.checked)
                  .map((i) => i.name)
                  .join(", ");
              break;
            default:
          }

          if (rec.until) {
            setCondition("until");
            setUntilDate(rec.until);
            customText +=
              " until " + formatDate("MMMM D, YYYY", new Date(rec.until));
          }

          // add custom value
          setRepeatData([
            ...repeatData,
            { value: "custom-value", text: customText },
          ]);
          // set custom value
          setSelectedRepeat("custom-value");
        } else if (rec.weekDays === "MO,TU,WE,TH,FR") {
          setSelectedRepeat("weekday");
        } else {
          setSelectedRepeat(rec.repeat);
        }
      } else {
        resetCustomValues();
      }
    },
    [repeatData, resetCustomValues, globalState]
  );

  // handle popup form changes

  const titleChange = React.useCallback((ev) => {
    setTitle(ev.target.value);
  }, []);

  const slotChange = React.useCallback((ev) => {
    setSlot(ev.target.value);
  });
  const gapChange = React.useCallback((ev) => {
    setGap(ev.target.value);
  });

  const dateChange = React.useCallback((args) => {
    setDate(args.value);
  }, []);

  //   const DeleteEvent = React.useCallback(() => {
  //     deleteEvent(tempEvent);
  //     setOpen(false);
  //   }, [deleteEvent, tempEvent]);

  const closeRecurring = React.useCallback(() => {
    setRecurring(false);
  });

  const openRecurring = React.useCallback(() => {
    setRecurring(true);
  });
  const openCancelSchedule = React.useCallback(() => {
    setCancelSchedule(true);
  });
  const closeCancelSchedule = React.useCallback(() => {
    setCancelSchedule(false);
  });
  const openCancelScheduleRecurring = React.useCallback(() => {
    setCancelScheduleRecurring(true);
  });
  const closeCancelScheduleRecurring = React.useCallback(() => {
    setCancelScheduleRecurring(false);
  });

  const onDeleteClick = React.useCallback(() => {
    onClose();
    if (tempEvent.recurrence === null) {
      deleteCurrent();
    } else {
      openRecurring();
    }
  });

  const deleteRecurring = React.useCallback(() => {
    closeRecurring();
    openCancelScheduleRecurring();
  });
  const deleteCurrent = React.useCallback(() => {
    closeRecurring();
    openCancelSchedule();
  });

  // scheduler options

  const onSelectedDateChange = React.useCallback((event) => {
    setSelectedDate(event.date);
  }, []);

  const onEventClick = React.useCallback(
    (args) => {
      setEdit(true);
      setTempEvent({ ...args.event });
      console.log(args.event);
      // fill popup form with event data
      loadPopupForm(args.event);
      setAnchor(args.domEvent.target);
      if (globalState.doctorId !== null) {
        setOpen(true);
      } else {
        return false;
      }
    },
    [loadPopupForm]
  );

  const onEventCreated = React.useCallback(
    (args) => {
      setEdit(false);
      setTempEvent(args.event);
      // fill popup form with event data
      loadPopupForm(args.event);
      setAnchor(args.target);
      // open the popup
      if (globalState.doctorId !== null) {
        setOpen(true);
      } else {
        return false;
      }
    },
    [loadPopupForm, resetCustomValues]
  );

  const onEventDeleted = React.useCallback(
    (args) => {
      deleteEvent(args.event);
    },
    [deleteEvent]
  );

  const onEventUpdated = React.useCallback((args) => {
    // here you can update the event in your storage as well, after drag & drop or resize
    // ...
  }, []);

  // datepicker options

  const responsiveOptions = {
    medium: {
      controls: ["calendar", "time"],
      touchUi: false,
    },
  };

  // popup options
  const headerText = React.useMemo(
    () => (isEdit ? "View Schedule" : "New Schedule"),
    [isEdit]
  );
  const onClose = React.useCallback(() => {
    setRepeatData(repeatData.filter((item) => item.value !== "custom-value"));
    dispatch(replaceSchedule([...globalState.schedules]));
    setOpen(false);
  }, [isEdit, globalState.schedules, repeatData]);

  const newEventData = () => ({
    gap: 0,
    duration: 10,
  });

  // React.useEffect(async () => {
  //   setLoader(true);
  //   const data = await getData();
  //   dispatch(replaceSchedule([...data]));
  //   console.log("over", data);
  //   setLoader(false);
  // }, []);
  async function onPageLoading(event, inst) {
    console.log(event, inst);
    const start = new Date(event.firstDay).toISOString();
    const end = new Date(event.lastDay).toISOString();
    //eslint-disable-next-line
    start < new Date().toISOString() ? blockPastDate(start) : null;
    dispatch(changeCurrentDay({ start, end }));
    if (globalState.doctorId !== null) {
      setLoader(true);
      const data = await getData(globalState.doctorId, start, end);
      dispatch(replaceSchedule([...data]));
      console.log("over", data);
      console.log(start, end);
    }
    setLoader(false);
  }
  const onEventCreateFailed = React.useCallback(() => {
    if (globalState.doctorId !== null) {
      mobiscroll.toast({
        message: "Can't create event on this date",
      });
    } else {
      mobiscroll.toast({
        message: "Please select Doctor before creating Schedule.",
      });
    }
  });

  const onEventUpdateFailed = React.useCallback(() => {
    mobiscroll.toast({
      message: "Can't add event on this date",
    });
  });

  React.useEffect(async () => {
    if (globalState.doctorId !== null) {
      setLoader(true);
      const data = await getData(
        globalState.doctorId,
        globalState.current.start,
        globalState.current.end
      );
      dispatch(replaceSchedule([...data]));
      console.log("over", data);
      console.log(start, end);
      setLoader(false);
    } else {
      dispatch(replaceSchedule([]));
    }
  }, [globalState.doctorId]);
  return (
    <Card className="docg-schedule-card">
      <Loader show={loader} />
      <Eventcalendar
        onPageLoading={onPageLoading}
        renderHeader={customWithNavButtons}
        renderScheduleEvent={renderScheduleEvent}
        renderLabel={renderLabel}
        renderEventContent={renderLabel}
        extendDefaultEvent={newEventData}
        displayTimezone="local"
        timezonePlugin={momentTimezone}
        view={calView}
        data={globalState.schedules}
        dayNames={[
          "Sunday",
          "Monday",
          "Tuesday",
          "Wednesday",
          "Thursday",
          "Friday",
          "Saturday",
        ]}
        cssClass="md-switching-view-cont"
        clickToCreate="double"
        dragToCreate={true}
        dragToMove={true}
        dragToResize={true}
        selectedDate={mySelectedDate}
        onSelectedDateChange={onSelectedDateChange}
        onEventClick={onEventClick}
        onEventCreated={onEventCreated}
        onEventDeleted={onEventDeleted}
        onEventUpdated={onEventUpdated}
        invalidateEvent="strict"
        invalid={invalidData}
        onEventCreateFailed={onEventCreateFailed}
        onEventUpdateFailed={onEventUpdateFailed}
      />
      {/* <Popup
        display="inline"
        fullScreen={false}
        closeOnEsc={false}
        closeOnOverlayClick={false}
        contentPadding={false}
        anchor={anchor}
        scrollLock={false}
        isOpen={isOpen}
        onClose={onClose}
        responsive={responsivePopup}
        cssClass="docg-schedule-popup"
      > */}
      <Modal
        show={isOpen}
        onHide={onClose}
        backdrop="static"
        keyboard={false}
        dialogClassName="docg-modal docg-schedule-popup"
        size="lg"
        centered
      >
        <Modal.Header className="docg-schedule-popup-header">
          <button onClick={onClose} className="docg-save-btn">
            <img src={cancel} alt="" />
            Cancel
          </button>
          <div className="header-title">{headerText}</div>
          <button onClick={saveEvent} className="docg-save-btn">
            <img src={AddWhiteIcon} alt="" />
            {isEdit ? "Save" : "Add"}
          </button>
        </Modal.Header>
        <Modal.Body className="mbsc-form-group overflow-auto">
          <Input
            label="Schedule Name"
            value={popupEventTitle}
            readOnly
            onChange={titleChange}
          />

          <Input
            ref={startRef}
            readOnly={isEdit}
            label="Start Time"
            touchUi={false}
          />
          <Input
            ref={endRef}
            readOnly={isEdit}
            label="End Time"
            touchUi={false}
          />
          <Datepicker
            select="range"
            controls={["datetime"]}
            touchUi={false}
            disabled={isEdit}
            startInput={start}
            endInput={end}
            showRangeLabels={false}
            responsive={responsiveOptions}
            onChange={dateChange}
            value={popupEventDate}
          />
          <Input
            type="number"
            readOnly={isEdit}
            value={popupSlotDuration}
            onChange={slotChange}
            label="Slot Duration (mins)"
          />

          <Input
            type="number"
            readOnly={isEdit}
            value={popupGap}
            onChange={gapChange}
            label="Gap between Slots (mins)   "
          />
          <Select
            className="mt-2"
            readOnly={isEdit}
            data={repeatData}
            label="Repeats"
            value={selectedRepeat}
            responsive={selectResponsive}
            onChange={repeatChange}
          />
          {(selectedRepeat === "custom" ||
            selectedRepeat === "custom-value") && (
            <div className="mt-2">
              <div>
                <SegmentedGroup disabled={isEdit} onChange={repeatTypeChange}>
                  <SegmentedItem value="daily" checked={repeatType === "daily"}>
                    Daily
                  </SegmentedItem>
                  <SegmentedItem
                    value="weekly"
                    checked={repeatType === "weekly"}
                  >
                    Weekly
                  </SegmentedItem>
                </SegmentedGroup>

                <div className="custom-repeat-settings">
                  Repeat every
                  <Input
                    readOnly={isEdit}
                    className="custom-repeat-input"
                    min="1"
                    value={repeatNr}
                    onChange={repeatNrChange}
                    inputStyle="outline"
                  />
                  {repeatType === "daily" && <span>days</span>}
                  {repeatType === "weekly" && <span>weeks</span>}
                  {repeatType === "daily" && (
                    <p className="custom-repeat-desc">
                      The event will be repeated every day or every x days,
                      depending on the value
                    </p>
                  )}
                  {repeatType === "weekly" && (
                    <p className="custom-repeat-desc">
                      The event will be repeated every x weeks on specific
                      weekdays
                    </p>
                  )}
                  {repeatType === "monthly" && (
                    <p className="custom-repeat-desc">
                      The event will be repeated every x month on specific day
                      of the month
                    </p>
                  )}
                  {repeatType === "weekly" && (
                    <div className="custom-repeat-checkbox-cont">
                      {days.map((day) => {
                        return (
                          <Checkbox
                            value={day.value}
                            disabled={isEdit}
                            key={day.value}
                            checked={day.checked}
                            onChange={weekDayChange}
                          >
                            {day.name}
                          </Checkbox>
                        );
                      })}
                    </div>
                  )}
                  <div>Stop condition</div>
                  <div className="custom-condition-cont">
                    <RadioGroup>
                      <Radio
                        checked={condition === "until"}
                        onChange={conditionChange}
                        value="until"
                      >
                        Run until a specific date{" "}
                        <Datepicker
                          inputProps={dateInputProps}
                          controls={["calendar"]}
                          display="anchored"
                          touchUi={false}
                          disabled={isEdit}
                          dateFormat="YYYY-MM-DD"
                          returnFormat="iso8601"
                          value={untilDate}
                          onChange={untilDateChange}
                          onOpen={() => setCondition("until")}
                        />
                        <span className="mbsc-description">
                          The event will run until it reaches a specific date
                        </span>
                      </Radio>
                    </RadioGroup>
                  </div>
                </div>
              </div>
            </div>
          )}

          {isEdit && (
            <button className="docg-save-btn mt-3" onClick={onDeleteClick}>
              <img src={cancel} alt="" />
              Delete Event
            </button>
          )}
        </Modal.Body>
      </Modal>

      <Recurring
        isOpen={recurring}
        closeModal={closeRecurring}
        deleteRecurring={deleteRecurring}
        deleteCurrent={deleteCurrent}
      />
      <CancelScheduleCurrent
        isOpen={cancelSchedule}
        closeModal={closeCancelSchedule}
        data={tempEvent}
      />
      <CancelScheduleRecurring
        isOpen={cancelScheduleRecurring}
        closeModal={closeCancelScheduleRecurring}
        data={tempEvent}
      />
    </Card>
  );
}

export default App;
