import { Route, BrowserRouter as Router, Switch } from "react-router-dom";
import ClinicTemplate from "../commons/ClinicTemplate";
import OpenRoute from "../commons/OpenRoute";
import PrivateRoute from "../commons/PrivateRoute";
import AuthRoutes from "../components/Authentication/AuthRoutes";
import ClinicRoutes from "../components/ClinicDashboard/ClinicRoutes";

// standards
import Standards from "src/components/standards";

const Routers = () => {
  const routes = [...AuthRoutes, ...ClinicRoutes];
  const authRoutes = routes.filter((res) => res.auth);
  const normalRoutes = routes.filter((res) => !res.auth);
  return (
    <Router>
      <Switch>
        {normalRoutes.map((route, i) => (
          <OpenRoute
            exact
            key={route.path}
            path={route.path}
            module={route.module}
            component={route.component}
          />
        ))}
        {/* <Route exact path="/subscription">
          <Subscription />
        </Route> */}
        <ClinicTemplate>
          {authRoutes.map((route, i) => (
            <PrivateRoute
              exact
              key={route.path}
              path={route.path}
              module={route.module}
              component={route.component}
            />
          ))}
        </ClinicTemplate>

        <Route exact path="/standards">
          <Standards />
        </Route>
        <Route render={() => <p>Page Not Found.</p>} />
      </Switch>
    </Router>
  );
};

export default Routers;
