import React, { Fragment, useState } from "react";
import "../Reporting.css";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import am4themes_material from "@amcharts/amcharts4/themes/material";
import ReportingModals from "../../../../shared/Modals/ReportingModals";
import arrowImg from "../../../../assets/purple.svg";
import { listPatientsYearByYear } from "../api";

const PatientsYear = (props) => {
  const [lgShow, setLgShow] = useState(false);
  const [data, setData] = useState([]);
  const chartRef = React.useRef(null);

  React.useEffect(() => {
    am4core.ready(function () {
      // Themes begin
      am4core.useTheme(am4themes_material);
      am4core.useTheme(am4themes_animated);
      am4core.addLicense("ch-custom-attribution");

      // Themes end

      // Create chart instance
      var chart = am4core.create(props?.divId, am4charts.XYChart);

      chart.scrollbarX = new am4core.Scrollbar();

      // Add data
      chart.data = [];

      // Create axes
      var categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
      categoryAxis.dataFields.category = "year";
      categoryAxis.renderer.minGridDistance = 10;
      categoryAxis.renderer.grid.template.location = 0;

      var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
      valueAxis.tooltip.disabled = true;

      // Create series
      var lineSeries = chart.series.push(new am4charts.ColumnSeries());
      lineSeries.dataFields.valueY = "value";
      lineSeries.dataFields.categoryX = "year";
      lineSeries.name = "Total Patients";
      lineSeries.strokeWidth = 3;
      lineSeries.tooltipText = "{categoryX}: {valueY}";

      chart.cursor = new am4charts.XYCursor();
      chart.cursor.lineY.disabled = true;
      chart.cursor.lineX.disabled = true;

      chartRef.current = chart;
    }); // end am4core.ready()
    // eslint-disable-next-line
  }, []);

  React.useEffect(async () => {
    if (props.check) {
      const res = await listPatientsYearByYear();
      console.log(res);
      chartRef.current.data = res;
      setData(res);
    } else {
      chartRef.current.data = props.data;
    }
  }, []);

  return (
    <Fragment>
      {props?.check && (
        <div
          onClick={(e) => {
            props?.check ? setLgShow(true) : e.preventDefault();
          }}
          className=""
        >
          <span className="patient_style">
            Patients year by year
            <img src={arrowImg} alt="" className="arrow-img ml-2" />
          </span>
        </div>
      )}
      <div id={props?.divId}></div>
      {setLgShow && props.check ? (
        <ReportingModals
          setLgShow={setLgShow}
          lgShow={lgShow}
          show={2}
          title={"Patients year by year"}
          data={data}
        />
      ) : (
        ""
      )}
    </Fragment>
  );
};
export default PatientsYear;
