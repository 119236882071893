import React, { useState } from "react";
import CalendarContent from "./CalendarContent";
import { Helmet } from "react-helmet";
import { StoreProvider } from "./store/Store";
import { initialState, timeSlotsReducer } from "./store/CalendarReducer";
import DoctorList from "./doctorList/DoctorList";
import { isMobileOnly } from "react-device-detect";
import { Modal } from "react-bootstrap";
const Schedule = () => {
  const [view, setView] = useState(false);
  return (
    <>
      <Helmet>
        <title>Doctor Calendar | DOCG</title>
      </Helmet>
      <StoreProvider initialState={initialState} reducer={timeSlotsReducer}>
        {isMobileOnly ? (
          <>
            <button
              onClick={() => setView(true)}
              className="docg-save-btn mb-2"
            >
              View Doctors
            </button>
          </>
        ) : (
          <></>
        )}
        <Modal
          size="lg"
          show={view}
          onHide={() => setView(false)}
          dialogClassName="docg-modal"
          aria-labelledby="example-custom-modal-styling-title"
          centered
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>Doctor List</Modal.Header>
          <Modal.Body className="p-0">
            <DoctorList />
          </Modal.Body>
        </Modal>
        <div className="mbsc-col-12 calendar-docg mbsc-col-md-9 pr-3">
          <CalendarContent />
        </div>
        {!isMobileOnly && (
          <div className="mbsc-col-0 mbsc-col-md-3">
            <DoctorList />
          </div>
        )}
      </StoreProvider>
    </>
  );
};

export default Schedule;
