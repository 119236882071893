import * as Yup from "yup";

const createMembershipSchema = Yup.object({
  description: Yup.string().required(),
  price: Yup.number().typeError("Price not valid").nullable(true).required(),
  paymentFrequency: Yup.string()
    .typeError("Price not valid")
    .nullable(true)
    .required(),
  validityTimeUnit: Yup.string()
    .typeError("Validity is not valid")
    .nullable(true)
    .required(),
  validityTime: Yup.number().typeError("Not valid").nullable(true).required(),
  name: Yup.string().required("name of Plan Type is required"),
  numberOfVisits: Yup.number().min(1).required(),
  numberOfMembers: Yup.number().min(1).required(),
});

export default createMembershipSchema;
