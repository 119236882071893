import React, { Suspense, useCallback, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { useHistory } from "react-router-dom";
import HistoryDialog from "../dialogs/HistoryDialog";
import { useStore } from "../store/Store";

const options = {
  year: "numeric",
  month: "short",
  day: "numeric",
};
const HistoryCard = ({
  title = "",
  doctorName = "",
  data = null,
  date,
  key,
  showDiagnosis = false,
}) => {
  console.log(data);
  const history = useHistory();
  const { globalState } = useStore();

  const [isHistoryDialog, setHistoryDialog] = useState(false);
  const [followUpPopup, setFollowUpPopup] = React.useState(false);

  const openHistoryDialog = useCallback(() => {
    setHistoryDialog(true);
  }, []);

  const closeHistoryDialog = useCallback(() => {
    setHistoryDialog(false);
  }, []);

  const openFollowUp = useCallback(() => {
    setHistoryDialog(false);
    setFollowUpPopup(true);
  }, []);

  return (
    <>
      <button
        onClick={openHistoryDialog}
        className="mbsc-row my-2 p-2 justify-content-between past-diagnosis-card d-flex"
      >
        <span>
          {data?.appointmentDetails?.start &&
            new Date(data?.appointmentDetails?.start).toLocaleDateString(
              "en-US",
              options
            )}
        </span>
        <span>
          {[
            ...(data?.appointmentDetails?.note?.diagnosis?.diagnosisName ?? []),
            ...(data?.appointmentDetails?.note?.diagnosis?.message
              ? [data?.appointmentDetails?.note?.diagnosis?.message]
              : []),
          ].join(", ") || "NA"}
        </span>
      </button>

      <Modal
        show={isHistoryDialog}
        onHide={closeHistoryDialog}
        dialogClassName="history-dialog-modal"
        aria-labelledby="example-custom-modal-styling-title"
        backdrop="static"
        keyboard={false}
      >
        <Modal.Body>
          <Suspense fallback={<div>Loading...</div>}>
            <HistoryDialog
              followUpPopup={followUpPopup}
              openFollowUp={openFollowUp}
              onClose={closeHistoryDialog}
              data={data}
            />
          </Suspense>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default HistoryCard;
