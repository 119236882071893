export default function downloadFilefromURL(uri, name) {
  var link = document.createElement("a");
  link.download = name;
  link.target = "_blank";
  link.href = uri;
  document.body.appendChild(link);
  link.click();
  link.remove();
  document.body.removeChild(link);
  // delete link;
}
