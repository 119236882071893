import { NavItem, TabNav } from "@mobiscroll/react";
import { SegmentedGroup, SegmentedItem } from "@mobiscroll/react5";
import { AgGridColumn, AgGridReact } from "ag-grid-react";
import React, { Fragment } from "react";
import Avatar from "react-avatar";
import { Link, useHistory } from "react-router-dom";
import Loader from "src/components/ClinicDashboard/common/Loader";
import allInvoice from "../../../assets/all-invoice.svg";
import ActionCellRender from "../Layouts/ActionCellRender";
import "../Layouts/Appointments.scss";
import "./Invoice.scss";

import { Card, Col, Image, Row } from "react-bootstrap";
import UserTwo from "../../../assets/icons/UserTwo.png";

import { useState } from "react";
import InvoiceDetail from "./InvoiceDetail";

//api
import { getDoctors } from "src/components/api/index";
import { listInvoices } from "./api";
import { isMobileOnly } from "react-device-detect";

const PatientNameCellRenderer = (params) => {
  return (
    <>
      <Link
        className="docg-app-patient-name align-items-start w-100"
        to={`invoice-details/${params.value.invoiceId}`}
      >
        <Image
          src={
            params.value.patientProfilePicture
              ? params.value.patientProfilePicture
              : UserTwo
          }
          alt="Images"
          roundedCircle
          style={{ width: 35, height: 35 }}
        />{" "}
        {params.value.patientName}
      </Link>
    </>
  );
};

function Invoice(props) {
  const onQuickFilterChanged = () => {
    gridApi.setQuickFilter(document.getElementById("quickFilter").value);
  };

  // const history = useHistory();

  const [displayRecords, setDisplayRecords] = useState("10");
  const [totalRecords, setTotalRecords] = useState(0);
  const [loader, setLoader] = useState(false);
  const [doctorData, setDoctorData] = useState([]);
  const [rowData, setRowData] = useState([]);
  const [selectedDoctor, setSelectedDoctor] = useState(null);
  const { Header: CardHeader, Body: CardBody, Title: CardTitle } = Card;
  const [gridApi, setGridApi] = useState(null);
  const [gridColumnApi, setGridColumnApi] = useState(null);
  const [activeTab, setActiveTab] = useState("allinvoice");
  const [data, setData] = useState([]);

  React.useEffect(async () => {
    let res = JSON.parse(sessionStorage.getItem("doctors-list"));
    if (!res) {
      res = await getDoctors();
    }
    res = res.map((item) => {
      return { ...item, checked: false };
    });
    setDoctorData(res);
  }, []);

  const fetchData = async () => {
    setLoader(true);
    try {
      if (selectedDoctor) {
        const res = await listInvoices(
          Number(displayRecords),
          `doctorId=${selectedDoctor}`
        );
        setRowData(res?.data);
        if (res?.data.length > 0) {
          setTotalRecords(res?.totalCount);
          if (Number(displayRecords) > res?.totalCount) {
            setDisplayRecords(
              res?.totalCount < 10 ? `0${res?.totalCount}` : res?.totalCount
            );
          }
          setData((prevProps) => {
            Array.from(Array(totalRecords).keys()).map((index) => {
              let value = index + 1 < 10 ? `0${index + 1}` : index + 1;
              return value;
            });
          });
        }
      }
    } catch (err) {
      console.log(err);
    }
    setLoader(false);
  };

  React.useEffect(() => {
    fetchData();
  }, [doctorData, displayRecords]);

  const handleChange = (e) => {
    let res = doctorData;
    const doctor_data = JSON.parse(e.target.value);
    res = res.map((doctor) => {
      return doctor.id === doctor_data.id
        ? { ...doctor, checked: e.target.checked }
        : { ...doctor, checked: false };
    });
    setSelectedDoctor(doctor_data.id);
    setDoctorData(res);
  };

  // if we had column groups, we could provide default group items here
  const defaultColGroupDef = {};

  const onGridReady = (params) => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
  };

  const history = useHistory();

  const defaultColDef = {
    sortable: true,
    resizable: false,
    filter: true,
    flex: 1,
    minWidth: 100,
  };

  const AmountRenderer = (params) => {
    let amount = +params?.data?.amount;
    return <span>&#8377; {amount?.toLocaleString("en-IN")}</span>;
  };

  return activeTab === "allinvoice" ? (
    <Fragment>
      <Loader show={loader} />
      {/* Main Content start Here  */}
      <Card className="docg-card docg-appointments docg-vh-standard">
        <CardHeader className="d-flex justify-content-between customCardHeader pb-0 pt-0">
          <TabNav
            className="docg-tabs h-100"
            theme="windows"
            context="#tab-content"
          >
            <NavItem selected={activeTab === "allinvoice"}>
              <img src={allInvoice} alt="" />
              All Invoice
            </NavItem>
            {/* <NavItem
                  selected={activeTab === "invoice-detail"}
                  onClick={() => setActiveTab("invoice-detail")}
                >
                  <img src={invoiceDetailSvg} alt="" />
                  Invoice Detail
                </NavItem> */}
          </TabNav>
          <div className="h-100 ml-2"></div>
          <SegmentedGroup
            className="doctor-list d-flex mr-3 ml-3"
            style={{ padding: "0 2px" }}
            onChange={handleChange}
          >
            {doctorData.map((doctor) => {
              return (
                <SegmentedItem
                  className="doctor-detail"
                  name={doctor.id}
                  id={doctor.id}
                  value={JSON.stringify(doctor)}
                  checked={doctor?.checked}
                  style={{ padding: "0", margin: "0" }}
                >
                  <Avatar
                    color="#c06c84"
                    round
                    size="35"
                    name={`${doctor?.firstName} ${doctor.lastName}`}
                    src={doctor.profilePicture}
                  />
                  <span className="md-header-filter-name md-header-filter-name-1 ml-1">
                    {doctor.firstName} {doctor.lastName}
                  </span>
                </SegmentedItem>
              );
            })}
          </SegmentedGroup>
          {!isMobileOnly && (
            <div className="d-flex align-items-center justify-content-center">
              <div className="docg-table-search">
                <input
                  type="text"
                  onInput={() => onQuickFilterChanged()}
                  id="quickFilter"
                  placeholder="Search..."
                />
              </div>
            </div>
          )}
        </CardHeader>

        <CardBody className="docg-card-body p-0">
          <Row className="invoice-data">
            <Col>
              <div
                className="ag-theme-alpine custom-ag"
                style={{
                  height: "100%",
                  width: "100%",
                }}
              >
                {/* <div className="records-display">
                  <span>Showing&nbsp;</span>
                  <label className="record-selection-dropdown" htmlFor="">
                    <Select
                      touchUi={false}
                      onChange={onPageSizeChanged}
                      value={displayRecords}
                      data={data}
                    />
                    <Select
                      onChange={(e) => {
                        onPageSizeChanged(e);
                      }}
                      touchUi={false}
                      value={displayRecords}
                    >
                      {Array.from(Array(totalRecords).keys()).map((index) => {
                        let value =
                          index + 1 < 10 ? `0${index + 1}` : index + 1;
                        return (
                          <option value={value} key={value}>
                            {value}
                          </option>
                        );
                      })}
                    </Select>
                    <i className="fas fa-chevron-down"></i>
                  </label>
                  <span>&nbsp;Records</span>
                </div> */}
                <AgGridReact
                  rowData={rowData}
                  defaultColDef={defaultColDef}
                  frameworkComponents={{
                    actionCellRender: ActionCellRender,
                    patientNameCellRenderer: PatientNameCellRenderer,
                    amountRenderer: AmountRenderer,
                  }}
                  valueFormatter={(params) => {
                    return {
                      patientId: params.data.patientId,
                      id: params.data.id,
                    };
                  }}
                  defaultColGroupDef={defaultColGroupDef}
                  enableRangeSelection={true}
                  pagination={true}
                  onGridReady={onGridReady}
                  paginationPageSize={displayRecords}
                  domLayout={"autoHeight"}
                  animateRows={true}
                >
                  <AgGridColumn
                    headerName="Invoice No."
                    headerTooltip="Invoice No."
                    tooltipField="uhid"
                    field="uhid"
                    // valueFormatter={(params) => params.value.slice(-6)}
                  ></AgGridColumn>
                  <AgGridColumn
                    headerName="Patient Name"
                    headerTooltip="Patient Name"
                    tooltipField="to"
                    field="to"
                    initialPinned="left"
                    initialSort="asc"
                    cellRenderer="patientNameCellRenderer"
                    className="align-items-start"
                    valueGetter={(params) => {
                      return {
                        patientName: params.data.to,
                        patientProfilePicture:
                          params.data.patientProfilePicture,
                        invoiceId: params.data.id,
                      };
                    }}
                  ></AgGridColumn>
                  <AgGridColumn
                    headerName="Invoice Date"
                    headerTooltip="Invoice Date"
                    field="createdAt"
                    valueFormatter={(params) => {
                      return new Date(params.value).toLocaleTimeString([], {
                        hour: "2-digit",
                        minute: "2-digit",
                        month: "short",
                        day: "numeric",
                      });
                    }}
                    tooltipValueGetter={(params) => {
                      return new Date(params.value).toLocaleTimeString([], {
                        hour: "2-digit",
                        minute: "2-digit",
                        month: "short",
                        day: "numeric",
                      });
                    }}
                  ></AgGridColumn>
                  <AgGridColumn
                    headerName="Bill Amount"
                    headerTooltip="Bill Amount"
                    tooltipField="amount"
                    field="amount"
                    cellRenderer="amountRenderer"
                  ></AgGridColumn>
                  <AgGridColumn
                    headerName="Status"
                    tooltipField="status"
                    headerTooltip="Status"
                    field="status"
                  ></AgGridColumn>
                  <AgGridColumn
                    headerName="Payment Mode"
                    headerTooltip="Payment Mode"
                    field="paymentMethod"
                    tooltipField="paymentMethod"
                  ></AgGridColumn>
                  <AgGridColumn
                    headerName="Reason"
                    headerTooltip="Reason"
                    field="reason"
                    tooltipField="reason"
                  ></AgGridColumn>
                </AgGridReact>
              </div>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </Fragment>
  ) : (
    <InvoiceDetail />
  );
}

export default Invoice;
