import React from "react";
import send from "src/assets/send.svg";

const Buttons = () => {
  return (
    <div>
      <button className="docg-save-btn">
        <img src={send} alt="" /> Save Button
      </button>
      <br />
      <button className="docg-save-btn">Simple Button</button>
    </div>
  );
};

export default Buttons;
