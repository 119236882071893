import React, { Fragment, useState } from "react";
import invoice from "src/assets/invoice-purple.svg";
import { useHistory } from "react-router-dom";
import "./Invoice.scss";
import downloadIcon from "src/assets/download.svg";
import printIcon from "src/assets/print.svg";
import InvoiceMsgIcon from "src/assets/invoice-msg.svg";
import plus from "src/assets/icons/plusWhite.svg";
import sendIcon from "src/assets/send.svg";
import cancel from "src/assets/cancel.svg";
import { useForm, Controller } from "react-hook-form";
import mobiscroll from "@mobiscroll/react";
import { Input, Datepicker, Select } from "@mobiscroll/react5";
import createInvoiceSchema from "./validationSchema/createInvoiceSchema";
import { Row, Col, Card, Button, Image } from "react-bootstrap";
import Item from "./Item";

const initialItem = {
  invoiceTo: "",
};

function CreateInvoice(props) {
  const history = useHistory();
  const patientDetails = [
    {
      id: "1",
      firstName: "John",
      lastName: "Peter",
      address: "XXX STREET, YYY PLACE",
      phone: "901234567",
    },
    {
      id: "2",
      firstName: "Kate",
      lastName: "William",
      address: "YYY STREET, ZZZ PLACE",
      phone: "901234567",
    },
    {
      id: "3",
      firstName: "Drake",
      lastName: "Tyson",
      address: "SSS STREET, TTT PLACE",
      phone: "901234567",
    },
    {
      id: "4",
      firstName: "Will",
      lastName: "Byers",
      address: "DDD STREET, EEE PLACE",
      phone: "901234567",
    },
    {
      id: "5",
      firstName: "Willy",
      lastName: "Byer",
      address: "XXX STREET, YYY PLACE",
      phone: "901234567",
    },
  ];
  const { register, handleSubmit, control, getValues, setValue } = useForm();
  const [inputField, setInputField] = useState([1]);
  const [listItem, setListItem] = useState([initialItem]);
  const [subTotal, setSubTotal] = useState(0);
  const [address, setAddress] = useState("");
  const [name, setName] = useState("");
  const [paymentMode, setPaymentMode] = useState("Offline");
  const [errors, setErrors] = useState({});
  const [focus, setFocus] = useState({});

  React.useEffect(() => {
    console.log(props?.match?.params?.id, "id");
  }, []);

  const onSubmit = (data) => {
    console.log(data);
  };

  const addItem = () => {
    console.log("called");
    setListItem([...listItem, initialItem]);
  };

  const handleChange = (i) => {
    console.log("Hadnling Chage");
    let error = {};
    try {
      createInvoiceSchema.validateSync(
        {
          invoiceto: document.getElementById("invoiceto").value,
          address: address,
          description: document.getElementById(`fieldName${i}.description`)
            .value,
          qty: document.getElementById(`fieldName${i}.qty`).value,
          totalamount: document.getElementById(`fieldName${i}.totalamount`)
            .value,
          paymentMode: paymentMode,
        },
        { abortEarly: false }
      );

      setErrors({});

      // setInputField((previous) => [...previous, 1]);
    } catch (err) {
      console.log(err);
      for (var j = 0; j < err.errors.length; j++) {
        error[`fieldName${i}.${err.errors[j].split(" ")[0]}`] = err.errors[j];
      }

      console.log(error);
      setErrors(error);
    }
  };

  const addDescription = async () => {
    let error = {};
    for (var i = 0; i < inputField.length; i++) {
      try {
        createInvoiceSchema.validateSync(
          {
            invoiceto: document.getElementById("invoiceto").value,
            address: address,
            description: document.getElementById(`fieldName${i}.description`)
              .value,
            qty: document.getElementById(`fieldName${i}.qty`).value,
            totalamount: document.getElementById(`fieldName${i}.totalamount`)
              .value,
            paymentMode: paymentMode,
          },
          { abortEarly: false }
        );
        if (i === inputField.length - 1) {
          setInputField((previous) => [...previous, 1]);
        }
      } catch (err) {
        console.log(err.errors);
        for (var j = 0; j < err.errors.length; j++) {
          error[`fieldName${i}.${err.errors[j].split(" ")[0]}`] = err.errors[j];
        }

        if (i === inputField.length - 1) {
          console.log(error);
          setErrors(error);
        }
      }
    }
  };
  const handlePaymentModeChange = (e) => {
    setPaymentMode(e.valueText);
  };

  const updateTotal = (data, index) => {
    handleChange(index);
    setValue(`list[${index}].totalAmount`, data.target.value);
    let { list } = getValues();
    let updateTotalAmount = 0;
    list.forEach((value) => {
      if (value.totalAmount === "") {
        updateTotalAmount += 0;
      } else {
        updateTotalAmount += parseFloat(value.totalAmount);
      }
    });
    setSubTotal(updateTotalAmount);
  };

  const updateAddress = (e) => {
    handleChange(0);
    let expectedPatient = {};
    for (var i = 0; i < patientDetails.length; i++) {
      if (
        e.target.value.split(" ")[0] === patientDetails[i].firstName &&
        e.target.value.split(" ")[1] === patientDetails[i].lastName
      ) {
        expectedPatient = patientDetails[i];
        break;
      }
    }

    console.log(expectedPatient);

    if (expectedPatient.address) {
      setAddress(expectedPatient.address);
    } else {
      setAddress("");
    }
    setName(e.target.value);
  };

  const handleChangePage = () => {
    history.push("/invoice");
  };

  return (
    <Fragment>
      <Card className="docg-card docg-patient-form-wrapper docg-vh-standard">
        <Card.Title className="">
          <Row className="d-flex justify-content-between align-items-center w-100">
            <Col xs={12} sm={12} md={6} lg={6} xl={6}>
              <Image
                style={{
                  maxHeight: "1.75rem",
                  maxWidth: "1.75rem",
                  height: "1.75rem",
                  width: "auto",
                }}
                src={invoice}
                alt=""
                className="mr-2"
              />{" "}
              Create New Invoice
            </Col>

            <Col
              xs={12}
              sm={12}
              md={6}
              lg={6}
              xl={6}
              className="d-flex justify-content-end align-items-center"
            >
              <img
                // onClick={window.print}
                src={printIcon}
                style={{
                  maxHeight: "1.75rem",
                  maxWidth: "1.75rem",
                  height: "1.75rem",
                  width: "auto",
                }}
                className="mr-3"
                alt=""
              />
              <img
                style={{
                  maxHeight: "1.75rem",
                  maxWidth: "1.75rem",
                  height: "1.75rem",
                  width: "auto",
                }}
                // onClick={savePdf}
                src={downloadIcon}
                className="mr-3"
                alt=""
              />
              <img
                style={{
                  maxHeight: "1.75rem",
                  maxWidth: "auto",
                  height: "1.75rem",
                  width: "auto",
                }}
                src={InvoiceMsgIcon}
                className="mr-3"
                alt=""
              />
            </Col>
          </Row>
        </Card.Title>
        <Card.Body className="docg-card-body p-0 docg-h-standard">
          <Row className="">
            <Col xs={12} sm={12} md={6} lg={6} xl={6}>
              <Input
                inputStyle="underline"
                labelStyle="floating"
                label="Invoice To*"
                name="invoiceTo"
              />
            </Col>
            <Col xs={12} sm={12} md={6} lg={6} xl={6}>
              <Input
                inputStyle="underline"
                labelStyle="floating"
                label="Invoice Number"
                name="username"
              />
            </Col>
          </Row>
          <Row className="">
            <Col xs={12} sm={12} md={6} lg={6} xl={6}>
              <Input
                inputStyle="underline"
                labelStyle="floating"
                label="Address"
                name="invoiceTo"
              />
            </Col>
            <Col xs={12} sm={12} md={6} lg={6} xl={6}>
              <Datepicker
                theme="ios"
                themeVariant="light"
                dateFormat="DD-MM-YYYY"
                className="pop-up-input"
                controls={["date"]}
                touchUi={true}
                display="bubble"
                label="Date"
                labelStyle="floating"
              />
            </Col>
            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
              <div className="textarea-wrap">
                <span>Item</span>
                {listItem.map(() => (
                  <Item />
                ))}
              </div>
            </Col>
            <Row className="w-100">
              <Col xs={12} sm={5} md={7} lg={7} xl={8}>
                <button className="docg-save-btn" onClick={addItem}>
                  <img
                    style={{
                      maxHeight: "1rem",
                      maxWidth: "1rem",
                      width: "1rem",
                      height: "1rem",
                    }}
                    src={plus}
                    alt="df"
                  />{" "}
                  Add new Item
                </button>
              </Col>
              <Col
                className="d-flex justify-content-end flex-column"
                xs={12}
                sm={7}
                md={5}
                lg={5}
                xl={4}
              >
                <div className="d-flex align-items-center mb-2">
                  <p className="total-tax">Sub Total :</p>
                  <p className="total-amount-tax">Rs.1200</p>
                </div>
                <div className="d-flex align-items-center mb-2">
                  <p className="total-tax">Tax :</p>
                  <p className="total-amount-tax">2%</p>
                </div>
                <div className="d-flex align-items-center total-amount-wrapper">
                  <p className="total-tax text-white">Total :</p>
                  <p className="total-amount-tax text-white">Rs. 1224</p>
                </div>
                <div className="d-flex align-items-center justify-content-center mb-2 mt-4">
                  <button
                    onClick={handleChangePage}
                    className="docg-save-btn mr-3"
                  >
                    <img src={cancel} alt="" /> Back
                  </button>
                  <button className="docg-save-btn">
                    <img src={sendIcon} alt="" /> Save &amp; Send
                  </button>
                </div>
              </Col>
            </Row>
          </Row>
        </Card.Body>
      </Card>
    </Fragment>
  );
}

export default CreateInvoice;
