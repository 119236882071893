import React, { useState, Suspense, useRef } from "react";
import Loader from "src/components/ClinicDashboard/common/Loader";
import { Tab, Tabs } from "react-bootstrap";
import { Helmet } from "react-helmet";
import _ from "lodash";

const AddPatientGeneralInfo = React.lazy(() =>
  import("./Tabs/AddPatientGeneralInfo")
);
const AddPatientMedicalInfo = React.lazy(() =>
  import("./Tabs/AddPatientMedicalInfo")
);

const initialValues = {
  title: undefined,
  firstName: undefined,
  middleName: undefined,
  lastName: undefined,
  joiningDate: undefined,
  roles: undefined,
  gender: undefined,
  dob: undefined,
  address: undefined,
  email: undefined,
  phoneNumber: undefined,
  associatedClinics: undefined,
  specialization: undefined,
  training: undefined,
  award: undefined,
  yearsOfExperience: undefined,
  profilePicture: undefined,
  signature: undefined,
};

const AddPatient = () => {
  const [formValues, setValues] = React.useState({});
  const [key, setKey] = React.useState(1);

  const tab1ref = useRef();
  const tab2ref = useRef();

  const handlePageChange = (key) => {
    if (key == 2) tab1ref.current.onSubmit();
    else setKey(key);
  };

  return (
    <>
      <Helmet>
        <title>Add Patient | DOCG</title>
      </Helmet>
      <Tabs
        activeKey={key}
        onSelect={handlePageChange}
        id="controlled-tab-example"
        className="pb-2"
      >
        <Tab
          eventKey={1}
          title={
            <>
              <div className="left-line-tab">
                <div className="tab-number">
                  <p>General Information</p>
                </div>
              </div>
              <br />
            </>
          }
        >
          <Suspense fallback={<div>Loading...</div>}>
            <AddPatientGeneralInfo
              formValues={formValues}
              setValues={setValues}
              setKey={setKey}
              ref={tab1ref}
            />
          </Suspense>
          {/* <AddPatientTab onChangeTab={() => setKey("medical")} /> */}
          {/* <AccountDetails goNext ={()=>NextStep()}  /> */}
        </Tab>
        <Tab
          eventKey={2}
          title={
            <>
              <div className="left-line-tab">
                <div className="tab-number">
                  <p>Medical information</p>
                </div>
              </div>
              <br />
            </>
          }
        >
          <Suspense fallback={<div>Loading...</div>}>
            <AddPatientMedicalInfo
              formValues={formValues}
              setValues={setValues}
              handlePageChange={handlePageChange}
              ref={tab2ref}
            />
          </Suspense>
        </Tab>
      </Tabs>
    </>
  );
};

export default AddPatient;
