import React from "react";
import SmallCard from "./SmallCard";
import DataListingCard from "./DataListingCard/DataListingCard";
import InputFloating from "./InputFields";

const list = [
  {
    title: "Small Card",
    useCases: "Dashboard",
    component: SmallCard,
  },
  {
    title: "Data Listing Card",
    useCases: "Dashboard",
    component: DataListingCard,
  },
  {
    title: "Input Floating",
    useCases: "Dashboard",
    component: InputFloating,
  },
];

const index = () => {
  return (
    <div>
      <ul>
        {list.map((item) => {
          return (
            <li>
              <div>{item.title}</div>
              <div>{item.useCases}</div>
              <item.component />
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default index;
