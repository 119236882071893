import {
  getQueryWithParams,
  getQuery,
  postMutation,
} from "src/components/ApolloConnection/ApolloProviderComponent";
import { LIST_EMPLOYEES_CLINIC } from "src/components/graphql/GraphQLquery";
import {
  ACTIVATE_EMPLOYEE,
  DEACTIVATE_EMPLOYEE,
} from "src/components/graphql/GraphQLmutation";
import { snackbar } from "@mobiscroll/react5";

export async function listEmployeesClinic(take, search) {
  try {
    const response = await getQueryWithParams(
      LIST_EMPLOYEES_CLINIC(take, search)
    );
    if (typeof response == "undefined") {
      throw new Error("error", response);
    } else {
      snackbar({
        message: "List of Employees fetched successfully.",
        color: "success",
      });
      if (response?.errors) {
        throw new Error("error", response);
      } else if (response?.data?.listEmployeesClinic?.data) {
        console.log(response?.data?.listEmployeesClinic?.data);
        return response?.data?.listEmployeesClinic;
      } else {
        throw new Error("error", response);
      }
    }
  } catch (e) {
    snackbar({
      message: "Some error occured while fetching doctor list, Please Refresh.",
      color: "danger",
    });
    console.log("Error at Employee List: ", e);
    return null;
  }
}

export async function activateEmployee(data) {
  try {
    const response = await postMutation(ACTIVATE_EMPLOYEE, data);
    if (typeof response == "undefined" || response.errors) {
      throw new Error("error", response);
    } else {
      if (
        !(
          response?.data?.activateEmployee?.statusCode >= 200 &&
          response?.data?.activateEmployee?.statusCode < 300
        ) ||
        response?.data?.activateEmployee?.errors
      ) {
        throw new Error("error", response?.data?.activateEmployee.errors);
      }
      snackbar({
        message: "Employee Activated successfully.",
        color: "success",
      });
      return true;
    }
  } catch (e) {
    let message =
      "Some error occured while activating employee. Please try again later.";
    if (e.message.length < 200) {
      message = e.message;
    }
    snackbar({
      message,
      color: "danger",
    });
    console.log("Error at Employee List: ", e);
    return false;
  }
}
export async function deactivateEmployee(data) {
  try {
    const response = await postMutation(DEACTIVATE_EMPLOYEE, data);
    if (typeof response == "undefined" || response.errors) {
      throw new Error("error", response);
    } else {
      if (
        !(
          response?.data?.deactivateEmployee?.statusCode >= 200 &&
          response?.data?.deactivateEmployee?.statusCode < 300
        ) ||
        response?.data?.deactivateEmployee?.errors
      ) {
        throw new Error("error", response?.data?.deactivateEmployee.errors);
      }
      snackbar({
        message: "Employee Deactivated successfully.",
        color: "success",
      });
      return true;
    }
  } catch (e) {
    let message =
      "Some error occured while deactivating employee. Please try again later.";
    if (e.message.length < 200) {
      message = e.message;
    }
    snackbar({
      message,
      color: "danger",
    });
    console.log("Error at Employee List: ", e);
    return false;
  }
}
