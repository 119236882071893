import {
  getQueryWithParams,
  getQuery,
  postMutation,
} from "src/components/ApolloConnection/ApolloProviderComponent";
import {
  LIST_CLINIC_PATIENT,
  GET_PATIENT,
} from "src/components/graphql/GraphQLquery";
import { snackbar } from "@mobiscroll/react5";

export async function listClinicPatients(take, search, doctorIds) {
  try {
    console.log(doctorIds);
    const response = await getQueryWithParams(
      LIST_CLINIC_PATIENT(take, search),
      doctorIds
    );
    if (typeof response == "undefined") {
      throw new Error("error", response);
    } else {
      snackbar({
        message: "List of Doctors fetched successfully.",
        color: "success",
      });
      if (response?.errors) {
        throw new Error("error", response);
      } else if (response?.data?.listClinicPatients?.data) {
        console.log(response?.data?.listClinicPatients?.data);
        return response?.data?.listClinicPatients;
      } else {
        throw new Error("error", response);
      }
    }
  } catch (e) {
    snackbar({
      message: "Some error occured while fetching doctor list, Please Refresh.",
      color: "danger",
    });
    console.log("Error at List Patients: ", e);
    return [];
  }
}

export async function getPatientBasicDetails(data) {
  try {
    const response = await getQueryWithParams(GET_PATIENT, { id: data });
    if (
      typeof response == "undefined" ||
      response?.errors ||
      !(
        response?.data?.getPatient?.statusCode >= 200 &&
        response?.data?.getPatient?.statusCode < 300
      )
    ) {
      throw new Error("error", response);
    } else {
      snackbar({
        message: "Patient Information fetched successfully.",
        color: "success",
      });
      if (response?.errors) {
        throw new Error("error", response);
      } else if (response?.data?.getPatient?.data) {
        console.log(response?.data?.getPatient?.data);
        return response?.data?.getPatient?.data;
      } else {
        throw new Error("error", response);
      }
    }
  } catch (e) {
    snackbar({
      message: "Some error occured. Try again",
      color: "danger",
    });
    console.log("Error at fetching patient information ", e);
    return [];
  }
}
