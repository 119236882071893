import ChangePassword from "./ChangePassword";
import ForgotPassword from "./ForgotPassword";
import OtpVerification from "./OtpVerification";
import ResetPassword from "./ResetPassword";
import SignIn from "./SignIn";
import SignUp from "./signup/SignUp";

import "./AuthResponsive.scss";

const AuthRoutes = [
  {
    path: "/",
    module: "auth",
    auth: false,
    strict: false,
    component: SignIn,
  },
  {
    path: "/sign-in",
    module: "auth",
    auth: false,
    strict: true,
    component: SignIn,
  },

  {
    path: "/otp-verification",
    module: "auth",
    auth: false,
    strict: false,
    component: OtpVerification,
  },
  {
    path: "/change-password",
    module: "auth",
    auth: false,
    strict: false,
    component: ChangePassword,
  },
  {
    path: "/sign-up",
    module: "auth",
    auth: false,
    strict: false,
    component: SignUp,
  },
  {
    path: "/forgot-password",
    module: "auth",
    auth: false,
    strict: false,
    component: ForgotPassword,
  },
  {
    path: "/reset-password",
    module: "auth",
    auth: false,
    strict: false,
    component: ResetPassword,
  },
];

export default AuthRoutes;
