import mobiscroll from "@mobiscroll/react";
import { Input, SegmentedGroup, SegmentedItem } from "@mobiscroll/react5";
import React from "react";
import Avatar from "react-avatar";
import { Card } from "react-bootstrap";
import { getDoctors } from "../../Schedule/api/index";
import { changeDoctorId } from "../store/CalendarReducer";
import { useStore } from "../store/Store";
import { isMobileOnly } from "react-device-detect";

mobiscroll.settings = {
  theme: "ios",
  themeVariant: "light",
};

const ListItem = (props) => {
  return (
    <>
      <SegmentedItem value={props.item.id}>
        <div
          style={{ textAlign: "left" }}
          className="d-flex w-100 justify-content-start gap-6"
        >
          <Avatar
            color="#c06c84"
            round
            size="35"
            name={`${props?.item?.firstName} ${props?.item?.lastName}`}
            src={props.item.profilePicture}
            style={{ display: "contents" }}
          />
          <span className="d-flex flex-column">
            <span className="doctor-name">
              {props?.item?.firstName} {props?.item.lastName}
            </span>
            <span className="doctor-speciality">
              {props.item.specialization.join(", ")}
            </span>
          </span>
        </div>
      </SegmentedItem>
      <br />
    </>
  );
};
const DoctorListWrapper = ({ children }) => {
  return isMobileOnly ? (
    <>{children}</>
  ) : (
    <>
      <Card className="docg-vh-standard docg-card docg-patient-form-wrapper m-0">
        <Card.Header
          className="d-flex justify-content-between customCardHeader"
          style={{ backgroundColor: "#dee2e6" }}
        >
          <Card.Title className="customCardTitle">Doctor List</Card.Title>
        </Card.Header>
        <Card.Body className="docg-h-standard">{children} </Card.Body>
      </Card>
    </>
  );
};
const DoctorList = () => {
  const [data, setData] = React.useState([]);
  const { globalState, dispatch } = useStore();

  const doctorChange = (e) => {
    dispatch(changeDoctorId(e.target.value));
  };

  const handleSearchDoctor = React.useCallback(
    (e) => {
      try {
        let data = JSON.parse(sessionStorage.getItem("doctors-list"));
        let filteredDoctors = data.filter((el) => {
          let name = el.firstName + " " + el.lastName;
          return name.toLowerCase().includes(e.target.value.toLowerCase());
        });
        setData(filteredDoctors);
      } catch (err) {
        console.log(err);
      }
    },
    [sessionStorage]
  );

  React.useEffect(async () => {
    try {
      let res = JSON.parse(sessionStorage.getItem("doctors-list"));
      if (!res) {
        res = await getDoctors();
        sessionStorage.setItem("doctors-list", JSON.stringify(res));
      }
      setData(res);
      console.log(res);
    } catch (err) {
      console.log(err);
    }
  }, []);

  return (
    <DoctorListWrapper>
      <div className="mbsc-row">
        <div className="mbsc-col-12">
          <Input
            labelStyle="floating"
            inputStyle="underline"
            label="Search Doctor"
            onChange={handleSearchDoctor}
          />
        </div>
        <div
          className="mbsc-empty"
          style={{ display: !data.length ? "block" : "none" }}
        >
          <h5>No Doctors Found</h5>
        </div>
        <div className="mbsc-col-12">
          <div className="schedule-doctor-list">
            <SegmentedGroup onChange={doctorChange}>
              {data.map((item) => (
                <ListItem key={item.id} item={item} />
              ))}
            </SegmentedGroup>
          </div>
        </div>
      </div>
    </DoctorListWrapper>
  );
};

export default DoctorList;
