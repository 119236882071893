import * as Yup from "yup";

let message = "This field is required";

const step1ValidationSchema = Yup.object().shape({
  title: Yup.string().required(message),
  firstName: Yup.string().required(message),
  lastName: Yup.string().required(message),
  registrationNumber: Yup.string().required(message),
  phoneNumber: Yup.string()
    .matches(
      /^[6789][0-9]{9}$/,
      "Enter Valid 10 digit Mobile Number without country code"
    )
    .required(message),
  email: Yup.string().email("Enter valid Email").required(message),
  clinicName: Yup.string()
    .matches(
      /^(?!\d+$)(?:[a-zA-Z0-9][a-zA-Z0-9 -]*)?$/,
      "Special Characters not allowed"
    )
    .required(message),
  clinicAddress: Yup.string()
    .min(5, "Clinic Address should have minimum 5 characters")
    .required(message),
  registrationNumber: Yup.string().required(message),
  pinCode: Yup.number().required(message),
  district: Yup.string().required(message),
  state: Yup.string().required(message),
  termsAndConditionAccepted: Yup.bool()
    .required(message)
    .test("termsAndConditionAccepted", "Must be checked", (value) => value),
});

export default step1ValidationSchema;
