import React from "react";
import Avatar from "react-avatar";
import Image from "react-bootstrap/Image";
import { Link } from "react-router-dom";
import defaultDoctorIcon from "src/assets/defaultDoctor.svg";

const color = (value) => {
  switch (value) {
    case "Red":
      return "#dc3545";
    case "Yellow":
      return "#ffc107";
    case "Grey":
      return "#6c757d";
    case "Green":
      return "#1aab73";
    default:
      return;
  }
};

const PatientNameCellRenderer = (params) => {
  const LINK = `patient-record/${params.data.patient.id}?appointmentId=${params.data.id}`;
  return (
    <>
      <Link className="docg-app-patient-name w-100" to={LINK}>
        <div
          style={{
            background: color(params?.data.patientStatus),
            width: "0.5rem",
            height: "0.5rem",
            margin: "auto 0.5rem",
            borderRadius: "50%",
            display: "inline-block",
          }}
        >
          &nbsp;
        </div>
        <Avatar
          color="#c06c84"
          round
          size="35"
          name={`${params.data.patient?.firstName} ${params.data.patient.lastName}`}
          src={params.data.patient.profilePicture}
        />
        <span className="ml-2">
          {params.data.patient.firstName} {params.data.patient.lastName}
        </span>
      </Link>
    </>
  );
};

export default PatientNameCellRenderer;
