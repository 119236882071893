import "@mobiscroll/react/dist/css/mobiscroll.min.css";
import { snackbar } from "@mobiscroll/react5";
import axios from "axios";
import { useEffect, useState } from "react";
import { Card, Modal } from "react-bootstrap";
import { useQuery } from "react-query";
import upload from "src/assets/upload.svg";
import {
  getQuery,
  postMutation,
} from "src/components/ApolloConnection/ApolloProviderComponent";
import Loader from "src/components/ClinicDashboard/common/Loader";
import { UPLOAD_FILE_FOR_CLINIC } from "src/components/graphql/GraphQLmutation";
import { GET_FILE_FOR_CLINIC } from "src/components/graphql/GraphQLquery";
import downloadFilefromURL from "src/lib/downloadFilefromURL";
import { getClinicProfile } from "../Subscription/api";
import { updateProfile } from "./api";

export async function uploadCustomizedPolicyClinic(fileType) {
  try {
    const response = await postMutation(
      UPLOAD_FILE_FOR_CLINIC,
      { fileType },
      true
    );

    if (
      typeof response === "undefined" ||
      response?.errors ||
      !(
        response?.data?.uploadCustomizedPolicyClinic?.statusCode < 300 &&
        response?.data?.uploadCustomizedPolicyClinic?.statusCode >= 200
      )
    ) {
      throw new Error(response?.data?.uploadCustomizedPolicyClinic?.message);
    } else {
      return response?.data?.uploadCustomizedPolicyClinic?.data ?? true;
    }
  } catch (e) {
    snackbar({
      message: e.message ?? "Some error occured while uploading file",
      color: "danger",
    });
    console.log("Error at uploading file: ", e);
    return false;
  }
}

export async function getGenericTermsFileForClinic(fileType) {
  const response = await getQuery(GET_FILE_FOR_CLINIC, { fileType }, true);
  try {
    if (
      typeof response === "undefined" ||
      response?.errors ||
      !(
        response?.data?.getGenericTermsFileForClinic?.statusCode < 300 &&
        response?.data?.getGenericTermsFileForClinic?.statusCode >= 200
      )
    ) {
      throw new Error(response?.data?.getGenericTermsFileForClinic?.message);
    } else {
      snackbar({
        message: "File Fetched Successfully",
        color: "success",
      });
      return response?.data?.getGenericTermsFileForClinic?.data;
    }
  } catch (e) {
    if (response?.data?.getGenericTermsFileForClinic?.statusCode !== 404)
      snackbar({
        message: e.message ?? "Some error occured while fetching file",
        color: "danger",
      });
    console.log("Error at createRazorpayPlan: ", e);
    return false;
  }
}

function TnC({ show }) {
  //   const closeAll = () => {
  //     mbsc_ref?.current?.instance.hide();
  //     upgrade_ref?.current?.instance.hide();
  //     usage_ref?.current?.instance.hide();
  //     payment_ref?.current?.instance.hide();
  //   };

  //   const toggleLast = () => {
  //     payment_ref?.current?.instance.toggle();
  //   };

  const [fileType, setFileType] = useState("ClinicTermsAndConditionsForApp");
  const [loader, setLoader] = useState(false);
  const [showDisclaimer, setDisclaimer] = useState(false);

  const uploadFile = async (file, fileType) => {
    const res = await uploadCustomizedPolicyClinic(fileType);
    await axios.put(res.url, file, {
      headers: {
        "Content-Type": "application/pdf",
        "Access-Control-Allow-Origin": "*",
      },
    });
    // setFile(e.target.files[0]);
    return true;
  };

  const clinic_id = sessionStorage.getItem("clinic-id");

  const {
    data: files,
    refetch,
    loading,
  } = useQuery(
    ["clinic files", clinic_id],
    async function ({ queryKey }) {
      try {
        const res = await getClinicProfile(queryKey[1]);
        return res;
      } catch (err) {
        console.log(err);
        return null;
      }
    },
    {
      enabled: !!clinic_id,
    }
  );

  useEffect(() => {
    if (show) {
      setDisclaimer(true);
    }
  }, []);

  const closeModal = () => setDisclaimer(false);

  return (
    <>
      <Card className="docg-card">
        <Card.Header className="d-flex justify-content-between customCardHeader">
          <Card.Title className="docg-card-title customCardTitle">
            Templates
          </Card.Title>
        </Card.Header>
        <Card.Body className="docg-card-body">
          <div title="Terms and Conditions" className="py-2 w-100">
            {/* <mobiscroll.Note className="mbsc-align-center" color="primary">
        Use the accordion on forms to open only one panel at a time.
      </mobiscroll.Note> */}
            <Loader show={loader} />

            <div className="mbsc-row mt-4">
              <div className="mbsc-col-12 d-flex flex-column gap-4 mb-4">
                <TncCard
                  uploadFile={uploadFile}
                  fileType="ClinicTermsAndConditionsForApp"
                  label="Clinic Terms And Conditions for Patient(s)"
                  title="From Clinics - Web"
                  data={{
                    file: files?.clinicTermsAndConditionsForApp?.file,
                    fileName: files?.clinicTermsAndConditionsForApp?.fileName,
                    default:
                      files?.clinicTermsAndConditionsForApp?.genericAccepted,
                  }}
                  default_key="clinicTermsAndConditionsforAppGeneric"
                  refetch={refetch}
                />
              </div>
            </div>
          </div>
        </Card.Body>
      </Card>
      <Modal
        size="md"
        show={showDisclaimer}
        onHide={closeModal}
        dialogClassName="docg-modal"
        aria-labelledby="example-custom-modal-styling-title"
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg" className="ml-3">
            <span className="ModalTitle"> Note:</span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          "To start clinic please confirm the terms and conditions and privacy
          policies for the clinic. A default terms and conditions were uploaded,
          by your service provider for clinic to use. By just clicking on accept
          default terms and conditions click can continue to use default
          uploaded terms and conditions and privacy policy. By doing this the
          default terms and conditions will be visible for patients. Or there is
          option for clinic to upload their own terms and conditions and privacy
          policy or any other policy if they have any. In this case, the ones
          uploaded by clinic will be visible to patients. To upload, click on
          the upload new button.
        </Modal.Body>
      </Modal>
    </>
  );
}

export default TnC;

const TncCard = ({
  fileType,
  default_key,
  uploadFile,
  data,
  refetch,
  label,
}) => {
  const [uploaded_file, setFile] = useState(null);
  const [loader, setLoader] = useState(false);
  const [checked, setChecked] = useState(false);
  // const [fileType, setFileType] = useState(null);

  const { data: default_file, loading } = useQuery(
    ["docg files", fileType],
    async function ({ queryKey }) {
      try {
        const res = await getGenericTermsFileForClinic(queryKey[1]);
        return res;
      } catch (err) {
        console.log(err);
        return null;
      }
    },
    {
      enabled: !!fileType,
    }
  );

  return (
    <>
      <Card className="docg-card">
        <Loader show={loading || loader} />
        <Card.Header className="d-flex justify-content-between customCardHeader">
          <Card.Title className="docg-card-title customCardTitle">
            {label}
          </Card.Title>
        </Card.Header>
        <Card.Body className="docg-card-body">
          <div className="mbsc-row">
            {
              fileType ? (
                <div className="mbsc-col-12">
                  <div className="mbsc-row gap-4 justify-content-between d-flex mt-4">
                    <div
                      style={{
                        border: "2px dashed #c06c84",
                        borderRadius: "16px",
                      }}
                      className="mbsc-col-12 mbsc-col-lg-5 d-flex flex-column overflow-hidden align-items-center p-4"
                    >
                      <h6 className="purple-bold">STEP 1: Download Template</h6>
                      <div className="text-truncate mt-2">
                        {default_file?.name}
                      </div>
                      {!default_file ? (
                        <div className="mbsc-ios mbsc-error-message">
                          No default file found. Please contact admin to upload
                          customized file
                        </div>
                      ) : null}
                      <div className="d-flex align-items-end justify-content-end mt-2 flex-grow-1">
                        <button
                          onClick={async (e) => {
                            setChecked(true);
                            downloadFilefromURL(
                              default_file?.url,
                              "tnc_template"
                            );
                          }}
                          className="docg-save-btn bg-purple"
                        >
                          Download Template
                        </button>
                      </div>
                    </div>
                    <div
                      style={{
                        border: "2px dashed #c06c84",
                        borderRadius: "16px",
                        position: "relative",
                      }}
                      className="mbsc-col-12 mbsc-col-lg-5 d-flex flex-column align-items-center overflow-hidden p-4"
                    >
                      {!checked && <div className="docg-blocker" />}
                      <div className=" d-flex text-center align-items-center justify-content-center">
                        <h6 className="purple-bold">STEP 2: Upload new File</h6>
                      </div>

                      <div className="">
                        <div className="docg-signup-upload col-12 col-md-6 col-lg-12 mb-2">
                          <div className="upload-btn  purple-button">
                            <img src={upload} alt="" />
                            Upload File
                          </div>
                          <input
                            id="check-upload"
                            type="file"
                            accept={
                              fileType == "ClinicContract"
                                ? ".txt"
                                : "application/pdf"
                            }
                            style={{ display: "block" }}
                            onChange={(e) => {
                              setFile(e.target.files[0]);
                            }}
                            file={uploaded_file}
                          />
                        </div>
                      </div>
                      {uploaded_file ? (
                        <>
                          <div className=" text-center align-items-center justify-content-center gap-4 mb-3 mt-2">
                            {uploaded_file?.name}
                          </div>
                        </>
                      ) : data.file ? (
                        <>
                          <div className=" text-center align-items-center justify-content-center gap-4 mb-3 mt-2">
                            <a
                              href={data.file}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              {data?.fileName}
                            </a>
                          </div>
                        </>
                      ) : null}

                      <div className="flex-grow-1 d-flex text-center align-items-end justify-content-center mt-3">
                        <button
                          onClick={async (e) => {
                            setLoader(true);
                            await uploadFile(uploaded_file, fileType);
                            await updateProfile({
                              [default_key]: false,
                            });
                            setFile(null);
                            refetch();
                            setLoader(false);
                          }}
                          disabled={!uploaded_file}
                          className={
                            !(data.file && !uploaded_file)
                              ? "docg-save-btn bg-purple"
                              : "docg-save-btn bg-success"
                          }
                        >
                          {!(data.file && !uploaded_file)
                            ? "Accept & publish"
                            : "Accepted & published"}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              ) : null
              // <div className="mbsc-error-message mbsc-ios">
              //   Please select file type
              // </div>
            }
          </div>
        </Card.Body>
      </Card>
    </>
  );
};
