import React from "react";
import { Button, Image, Overlay, Tooltip } from "react-bootstrap";
import { Switch } from "@mobiscroll/react";
import Clock from "src/assets/icons/Clock.svg";
import RedCross from "src/assets/Red-cross.svg";
import Attachment from "src/assets/attachement.svg";

const ActionCellRender = (params) => {
  console.log(params);
  console.log(params);
  const [show, setShow] = React.useState(false);
  const target = React.useRef(null);
  return (
    <div className="docg-table-action">
      {params.value.uploadedFile === null ? (
        <>
          <Image
            ref={target}
            onClick={() => setShow(!show)}
            src={Attachment}
            alt="Patient Detail"
          />
          <Overlay target={target.current} show={show} placement="right">
            {(props) => (
              <Tooltip id="overlay-example" {...props}>
                No Attachments.
              </Tooltip>
            )}
          </Overlay>
        </>
      ) : (
        <a href={`${params.value.uploadedFile}`} target="_blank">
          <Image src={Attachment} alt="Patient Detail" />
        </a>
      )}
      <Switch
        className="docg-table-switch"
        checked={params.value.status === "Active"}
        theme="auto"
        position="start"
        onChange={(ev) => console.log(ev)}
        color="primary"
      />
    </div>
  );
};

export default ActionCellRender;
