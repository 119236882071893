import React, { Fragment, useState } from "react";
import { snackbar } from "@mobiscroll/react5";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import "./Auth.scss";
import mobiscroll from "@mobiscroll/react";
import OtpInput from "react-otp-input";
import grid from "src/assets/grid.svg";
import signin_circle from "src/assets/signin-circle.svg";
import doctor from "../../assets/login page doctors 02.svg";
import line from "../../assets/Lines.svg";
import { passwordValidation } from "./hooks/usePasswordValidation";
import { getQuery } from "../../components/ApolloConnection/ApolloProviderComponent";
import { USER_DATA } from "../graphql/GraphQLquery";

import { useHistory } from "react-router-dom";
import { confirmResetPassword } from "aws-amplify/auth";

function ResetPassword({ username }) {
  const history = useHistory();
  const [errorMessage, setErrorMessage] = useState("");
  const [formError, setFormError] = useState("");
  const [otp, setOtp] = useState("");
  const [otpError, setOtpError] = useState("");
  const [validation, setValidation] = useState({
    validLength: false,
    hasNumber: false,
    upperCase: false,
    lowerCase: false,
    match: false,
    specialChar: false,
  });

  const [password, setPassword] = useState({
    firstPassword: "",
    secondPassword: "",
  });

  const setFirst = (event) => {
    setPassword({ ...password, firstPassword: event.target.value });
    const _validation = passwordValidation({
      firstPassword: event.target.value,
      secondPassword: password.secondPassword,
    });
    const { validLength, hasNumber, upperCase, lowerCase, match, specialChar } =
      _validation;
    setValidation(_validation);
    console.log(_validation);
    if (validLength == false) {
      setErrorMessage("Password must be 8+ characters long");
    } else if (lowerCase == false) {
      setErrorMessage("Password must have 1 lowercase character");
    } else if (upperCase == false) {
      setErrorMessage("Password must have 1 uppercase character");
    } else if (hasNumber == false) {
      setErrorMessage("Password must have 1 numeric character");
    } else if (specialChar == false) {
      setErrorMessage("Password must have 1 special character");
    } else {
      setErrorMessage("");
    }
  };
  const setSecond = (event) => {
    setPassword({ ...password, secondPassword: event.target.value });
    const _validation = passwordValidation({
      firstPassword: password.firstPassword,
      secondPassword: event.target.value,
    });
    const { validLength, hasNumber, upperCase, lowerCase, match, specialChar } =
      _validation;
    setValidation(_validation);
    if (match == false) {
      setFormError("Password don't match");
    } else {
      setFormError("");
      setErrorMessage("");
    }
  };
  const handleOTPChange = (e) => {
    const value = e;
    setOtp(value);
    if (value.length != 6) {
      setOtpError("Invalid OTP, OTP must be 6 digits");
    } else {
      setOtpError("");
    }
  };
  const passwordChange = () => {
    if (otp.length != 6) {
      console.log("here");
      setOtpError("Invalid OTP, OTP must be 6 digits");
    } else if (
      validation.match == true &&
      validation.validLength == true &&
      otpError.length == 0
    ) {
      console.log("here");
      ChangePassword(username, otp, password.firstPassword);
    } else {
      console.log("here");
      setFormError("Error in password");
      setErrorMessage("Error in password");
    }
  };

  async function ChangePassword(username, otp, newPassword) {
    console.log("here");
    confirmResetPassword({
      username,
      newPassword,
      confirmationCode: otp,
    })
      .then((data) => {
        if (data == "SUCCESS") {
          // window.location="/"
          snackbar({
            message: "Password Changed Successfully.",
            color: "success",
          });
          setTimeout(() => {
            history.push("/");
          }, 3000);
        }
      })
      .catch((err) => console.log(err));
  }

  return (
    <Fragment>
      <Helmet>
        <title>Reset Password | DOCG</title>
      </Helmet>
      <div className="mbsc-grid">
        <div className="mbsc-card main-card-wrapper">
          <div className="signup-wrapper">
            <div className="mbsc-row">
              <div className="mbsc-col-xl-6 doctor-images-left mbsc-col-12">
                <div className="left-signup-div" id="reset-password-page"></div>
                <img src={doctor} className="left-signup-img left-steps-img" />
              </div>
              <div className="signup-right mbsc-col-xl-6 mbsc-col-12">
                <img src={grid} className="grid-top" />
                <img src={signin_circle} className="signin-circle" />
                <div className="bottom-circle" />
                <div className="top-circle" />
                <div className="right-signup-div">
                  <div className="get-started-wrapper">
                    <p className="signup-link">Step 2/2</p>
                    <p className="get-txt mt-4">Reset Password</p>

                    <div className="signup-wrapper-inner">
                      <p style={{ fontSize: "16px" }} className="get-txt mt-4">
                        Enter OTP
                      </p>
                      <br />
                      <OtpInput
                        onChange={handleOTPChange}
                        value={otp}
                        numInputs={6}
                        hasErrored={otpError}
                        errorStyle={"otp-input-error"}
                        separator={<span>&emsp;</span>}
                        containerStyle={"otp-container"}
                        inputStyle={"form-input-otp"}
                        isInputNum={true}
                      />
                      <br />
                      {otpError ? (
                        <span className="otp-error">{otpError}</span>
                      ) : (
                        ""
                      )}
                      <br />
                      <mobiscroll.Input
                        inputStyle="underline"
                        labelStyle="floating"
                        passwordToggle={true}
                        placeholder="New Password"
                        onChange={setFirst}
                        valid={!errorMessage}
                        errorMessage={errorMessage}
                        name="newPassword"
                      ></mobiscroll.Input>
                      <div className="password-check-div">
                        <div className="mbsc-grid">
                          {/* <div className="mbsc-row">
                            {validLength ? (
                              <div className="mbsc-col-3 mbsc-col-md-3 mbsc-col-lg-3 complete-section"></div>
                            ) : (
                              <div className="mbsc-col-3 mbsc-col-md-3 mbsc-col-lg-3 incomplete-section"></div>
                            )}
                            {upperCase ? (
                              <div className="mbsc-col-3 mbsc-col-md-3 mbsc-col-lg-3 complete-section"></div>
                            ) : (
                              <div className="mbsc-col-3 mbsc-col-md-3 mbsc-col-lg-3 incomplete-section"></div>
                            )}
                            {specialChar ? (
                              <div className="mbsc-col-3 mbsc-col-md-3 mbsc-col-lg-3 complete-section"></div>
                            ) : (
                              <div className="mbsc-col-3 mbsc-col-md-3 mbsc-col-lg-3 incomplete-section"></div>
                            )}
                            {hasNumber ? (
                              <div className="mbsc-col-3 mbsc-col-md-3 mbsc-col-lg-3 complete-section"></div>
                            ) : (
                              <div className="mbsc-col-3 mbsc-col-md-3 mbsc-col-lg-3 incomplete-section"></div>
                            )}
                          </div> */}
                          <br />
                          <div className="mbsc-row">
                            <div className="mbsc-col-6">
                              <p className="password-criteria-check">
                                {" "}
                                - 8+ Characters
                              </p>
                            </div>
                            <div className="mbsc-col-6">
                              <p className="password-criteria-check">
                                - 1 Uppercase
                              </p>
                            </div>
                            <div className="mbsc-col-6">
                              <p className="password-criteria-check">
                                - 1 Symbol
                              </p>
                            </div>
                            <div className="mbsc-col-6">
                              <p className="password-criteria-check">
                                - 1 Number
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <mobiscroll.Input
                        inputStyle="underline"
                        labelStyle="floating"
                        passwordToggle={false}
                        placeholder="Confirm Password"
                        onChange={setSecond}
                        valid={!formError}
                        name="confirmNewPassword"
                        errorMessage={formError}
                      ></mobiscroll.Input>
                      <button
                        className="purple-button"
                        onClick={passwordChange}
                      >
                        Reset Password
                      </button>
                      <div className="mbsc-col-md-2 mbsc-col-lg-2 mbsc-col-12"></div>
                    </div>
                  </div>
                </div>
                <img src={line} className="lines-bottom" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default ResetPassword;
