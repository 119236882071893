import React, { Fragment } from "react";
import { Helmet } from "react-helmet";
import "src/components/ClinicDashboard/Layouts/Card.css";
import "../../ClinicDashboard/Layouts/Card.css";
import "../Auth.scss";
import { initialState, signupReducer } from "./store/SignupReducer";
import { StoreProvider } from "./store/Store";

import { Step1, Step2, Step3, Step4 } from "./SignupSteps";

const Signup = () => {
  const [currentStep, setCurrentStep] = React.useState(0);
  const steps = [
    <Step1 setCurrentStep={setCurrentStep} />,
    <Step2 setCurrentStep={setCurrentStep} />,
    <Step3 setCurrentStep={setCurrentStep} />,
    <Step4 setCurrentStep={setCurrentStep} />,
  ];

  return (
    <Fragment>
      <Helmet>
        <title>Register | DOCG</title>
      </Helmet>
      <StoreProvider initialState={initialState} reducer={signupReducer}>
        {/* <button
          style={{
            position: "absolute",
            right: "20px",
            top: "20px",
          }}
          onClick={() => {
            if (currentStep === 4) {
              setCurrentStep(0);
            } else {
              setCurrentStep(currentStep + 1);
            }
          }}
        >
          change step
        </button> */}
        {steps[currentStep]}
      </StoreProvider>
    </Fragment>
  );
};

export default Signup;
