import React from "react";
import oopsImage from "src/assets/oops.svg";

const Failure = () => {
  return (
    <div className="d-flex justify-content-center align-items-center flex-column w-100 docg-vh-standard gap-3">
      <img style={{ maxWidth: 600 }} src={oopsImage} alt="" />
      <br />
      <p className="get-txt">
        {" "}
        Your subscription has been expired or cancelled!
      </p>

      <a href="/subscription/new" className="docg-save-btn">
        Create new subscription
      </a>
    </div>
  );
};

export default Failure;
