import React, { Suspense } from "react";
import { Button, Image, Modal } from "react-bootstrap";
import RedCross from "src/assets/Red-cross.svg";
import Clock from "src/assets/icons/Clock.svg";

const RescheduleAppointment = React.lazy(() =>
  import("./RescheduleAppointment/")
);

const CancelAppointment = React.lazy(() => import("./CancelAppointment"));

const ActionCellRender = (props) => {
  const [reschedulePopup, setReschedulePopup] = React.useState(false);
  const [cancelPopup, setCancelPopup] = React.useState(false);

  const toggleRescheduleAppointment = React.useCallback(() => {
    setReschedulePopup(!reschedulePopup);
  }, [reschedulePopup]);
  return (
    <>
      <div className="docg-table-action">
        <Button
          onClick={toggleRescheduleAppointment}
          variant="link"
          title="Reschedule Appointment"
        >
          <Image src={Clock} alt="Patient Detail" />
        </Button>

        <Button onClick={() => setCancelPopup(true)} variant="link" title="">
          <Image src={RedCross} alt="Patient" title="Cancel Appointment" />
        </Button>
      </div>
      <Suspense fallback={<div>Loading...</div>}>
        <RescheduleAppointment
          doctorId={props?.data?.doctorId}
          closePopup={() => setReschedulePopup(false)}
          isOpen={reschedulePopup}
          data={props?.data}
        />
      </Suspense>
      <Suspense fallback={<div>Loading...</div>}>
        <CancelAppointment
          closePopup={() => setCancelPopup(false)}
          data={props.data}
          isOpen={cancelPopup}
        />
      </Suspense>
    </>
  );
};

export default ActionCellRender;
