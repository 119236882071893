import { Checkbox, Input, Snackbar, snackbar } from "@mobiscroll/react5";
import { GET_CLINIC_DELETION_REQUEST } from "src/components/graphql/GraphQLquery";
import { Suspense, useState } from "react";
import { Card, Tab, Tabs } from "react-bootstrap";
import { useQuery } from "react-query";
import Loader from "src/commons/Loader";
import {
  getQuery,
  postMutation,
} from "src/components/ApolloConnection/ApolloProviderComponent";
import { isMobileOnly } from "react-device-detect";
import {
  EXPORT_DATA_REQUEST,
  RequestToDeleteClinic,
} from "src/components/graphql/GraphQLmutation";

export default function ClinicActions() {
  const [key, setKey] = useState(1);

  const handlePageChange = (key) => {
    setKey(key);
  };

  return (
    <>
      <div className="d-flex flex-column gap-4 w-100">
        <Card className="docg-card w-100">
          <Card.Header className="customCardHeader d-flex justify-content-between">
            <Card.Title className="docg-card-title customCardTitle">
              Clinic Data Download
            </Card.Title>
          </Card.Header>
          <Card.Body className="docg-card-body add-employee-tab w-100 p-0 overflow-auto">
            <Tabs
              activeKey={key}
              id="controlled-tab-example"
              onSelect={handlePageChange}
              className="pb-2"
            >
              <Tab
                eventKey={1}
                title={
                  <>
                    <div className="left-line-tab">
                      <div className="tab-number">
                        <p>Step 1{!isMobileOnly && ": Request for deletion"}</p>
                      </div>
                    </div>
                    <br />
                  </>
                }
              >
                {
                  (key = 1 && (
                    <Suspense fallback={<div>Loading...</div>}>
                      <div className="p-4">
                        <DownloadClinic />
                      </div>
                    </Suspense>
                  ))
                }
                {/* <AddPatientTab onChangeTab={() => setKey("medical")} /> */}
                {/* <AccountDetails goNext ={()=>NextStep()}  /> */}
              </Tab>
              <Tab
                eventKey={2}
                title={
                  <>
                    <div className="left-line-tab">
                      <div className="tab-number">
                        <p>Step 2{!isMobileOnly && ": Data Download"}</p>
                      </div>
                    </div>
                    <br />
                  </>
                }
              >
                {key == 2 && (
                  <Suspense fallback={<div>Loading...</div>}>
                    <div className="p-4">
                      <DeleteClinic />
                    </div>
                  </Suspense>
                )}
              </Tab>
            </Tabs>
          </Card.Body>
        </Card>
      </div>
    </>
  );
}

function DownloadClinic() {
  const [message, setMessage] = useState("");
  const [checked, setChecked] = useState(false);

  const onSubmit = async () => {
    try {
      const clinicId = sessionStorage.getItem("clinic-id");
      const res = await postMutation(RequestToDeleteClinic, {
        clinicId,
        notes: message,
        removalContractAccepted: checked,
        requestedIP: "12.2.1.1",
      });
      if (
        res.errors == null &&
        res?.data != null &&
        res?.data?.requestToDeleteClinic?.statusCode >= 200 &&
        res?.data?.requestToDeleteClinic?.statusCode < 300
      )
        snackbar({
          message: "Deletion Request done successfully. Proceed to Step 2",
          color: "success",
        });
      else {
        throw new Error(res?.data?.requestToDeleteClinic?.message);
      }
    } catch (err) {
      snackbar({
        message:
          err.message || "Some error occured while deleting clinic. Try again",
        color: "danger",
      });
    }
  };

  return (
    <>
      <div className="w-100">
        {isMobileOnly && "Request for deletion"}
        <Input
          inputStyle="underline"
          labelStyle="floating"
          label=""
          placeholder="Add reason to download data"
          name=""
          onChange={(e) => setMessage(e.target.value)}
          value={message}
        />
        <div className="custom-repeat-checkbox-cont">
          <Checkbox
            checked={checked}
            onChange={(e) => setChecked(e.target.checked)}
          >
            I Confirmed I have generated the request to delete clinic and
            request to download all the data.
          </Checkbox>
        </div>

        <button
          disabled={!checked || !message}
          onClick={onSubmit}
          className="docg-save-btn"
        >
          Request submit
        </button>
      </div>
    </>
  );
}

function DeleteClinic() {
  const [message, setMessage] = useState("");
  const [checked, setChecked] = useState(false);
  const [loading, setLoading] = useState(false);
  const clinicId = sessionStorage.getItem("clinic-id");

  const onSubmit = async () => {
    setLoading(true);
    try {
      const clinicId = sessionStorage.getItem("clinic-id");
      const res = await postMutation(RequestToDeleteClinic, {
        clinicId,
        notes: message,
        removalContractAccepted: checked,
        requestedIP: "12.2.1.1",
      });
      const data = res?.data?.requestToDeleteClinic;
      if (data?.statusCode >= 200 && data?.statusCode < 300) {
        snackbar({
          message: "Request generated successfully",
          color: "success",
        });
      } else {
        throw new Error(data?.message || "Something went wrong");
      }
    } catch (error) {}
  };

  const exportClinicData = async () => {
    try {
      let response = await postMutation(EXPORT_DATA_REQUEST, clinicId);
      if (
        response.errors == null &&
        response?.data != null &&
        response?.data?.exportClinicData?.statusCode >= 200 &&
        response?.data?.exportClinicData?.statusCode < 300
      ) {
        return response?.data?.exportClinicData?.data?.url;
      } else {
        throw new Error(
          response?.data?.exportClinicData?.message ||
            "Some error occured while exporting clinic data. Try again later."
        );
      }
    } catch (e) {
      snackbar({
        message: e.message,
        color: "danger",
      });
      return null;
    }
  };

  const getClinicDeletionRequest = async () => {
    try {
      const response = await getQuery(
        GET_CLINIC_DELETION_REQUEST,
        { clinicId },
        true
      );
      console.log(response);
      if (
        response.errors == null &&
        response?.data != null &&
        response?.data?.getClinicDeletionRequest?.statusCode >= 200 &&
        response?.data?.getClinicDeletionRequest?.statusCode < 300
      ) {
        if (response?.data?.getClinicDeletionRequest?.data?.dataDownloadUrl) {
          return response?.data?.listAllNotifications.data?.dataDownloadUrl;
        } else {
          let link = await exportClinicData();
          console.log(link);
          return link;
        }
      } else {
        throw new Error(
          response?.data?.exportClinicData?.message ||
            "Some error occured while exporting clinic data. Try again later."
        );
      }
    } catch (e) {
      console.log(e);
      snackbar({
        message: e.message,
        color: "danger",
      });
      return null;
    }
  };
  const { data: url } = useQuery(
    ["Clinic download "],
    getClinicDeletionRequest
  );

  console.log(url);
  return (
    <>
      <Loader isLoading={loading} />
      <div className="w-100">
        {isMobileOnly && "Data Download"}
        <a href={url} className="docg-save-btn">
          Download link
        </a>
        <div className="custom-repeat-checkbox-cont">
          <Checkbox
            checked={checked}
            onChange={(e) => setChecked(e.target.checked)}
          >
            I Confirmed I have downloaded all the data for which request is
            being raised once deletion request is unfinished there is no process
            for retried of data, DocG will no response.
          </Checkbox>
        </div>

        <button
          disabled={!checked || !message}
          onClick={onSubmit}
          className="docg-save-btn"
        >
          Procees with deletion
        </button>
      </div>
    </>
  );
}
