import { snackbar } from "@mobiscroll/react";
import { Checkbox } from "@mobiscroll/react5";
import axios from "axios";
import React, { useState } from "react";
import { useQuery } from "react-query";
import submit_icon from "src/assets/Submit icon.svg";
import cancelSign from "src/assets/close.svg";
import grid from "src/assets/grid.svg";
import signin_circle from "src/assets/signin-circle.svg";
import upload from "src/assets/upload.svg";
import {
  getQuery as getQueryUnAuth,
  postMutationUnAuth,
} from "src/components/ApolloConnection/UnauthorizedConnection/ApolloProviderSignup";
import "src/components/ClinicDashboard/Layouts/Card.css";
import VerificationPopup from "src/components/ClinicDashboard/Layouts/VerificationPopup";
import Loader from "src/components/ClinicDashboard/common/Loader";
import { CLINIC_CREATION } from "src/components/graphql/GraphQLmutation";
import {
  GET_FILE_FOR_DOCG,
  UPLOAD_CANCELLED_CHECK,
  UPLOAD_CLINIC_CONTRACT,
} from "src/components/graphql/GraphQLquery";
import fileToBase64 from "src/lib/fileToBase64";
import ContractPopup from "../../ContractPopup";
import { useStore } from "../store/Store";
import { isMobileOnly } from "react-device-detect";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";

const Step5 = (props) => {
  const [logo, setLogo] = React.useState(undefined);
  const [sign, setSign] = React.useState(undefined);
  const { globalState, dispatch } = useStore();

  const [contractAccepted, setContractAccepted] = React.useState(false);
  const [contractSigned, setContractSigned] = React.useState(null);
  const [openContract, setOpenContract] = React.useState(false);
  const [error, setError] = React.useState("");

  const [viewPopup, setViewPopup] = useState(false);
  const [loader, setLoader] = useState(false);

  React.useEffect(() => {
    if (contractSigned != null) setContractAccepted(true);
  }, [contractSigned]);

  const removeLogo = (e) => {
    setLogo(null);
    document.getElementById("logo-upload").value = null;
  };

  const removeSign = (e) => {
    setSign(null);
    setContractSigned(null);
    document.getElementById("sign-upload").value = null;
  };
  const signUpload = (event) => {
    if (event.target.files[0]) {
      setError("");
      let file = event.target.files[0];
      let file_size = event.target.files[0].size;
      if (file_size > 500000) {
        snackbar({
          message: "File Size should be less than 500Kb.",
          color: "danger",
        });
        setSign(null);
      } else {
        fileToBase64(file)
          .then(async (result) => {
            const s = result.split(",");
            file["base64"] = result;
            console.log(result);
            setSign(result);
          })
          .catch((err) => {
            console.log(err);
          });
      }
    }
  };
  const contractUpload = (event) => {
    if (event.target.files[0]) {
      setError("");
      let file = event.target.files[0];
      let file_size = event.target.files[0].size;
      if (file_size > 500000 && false) {
        snackbar({
          message: "File Size should be less than 500Kb.",
          color: "danger",
        });
        setSign(null);
      } else {
        setContractSigned(file);
      }
    }
  };
  const logoUpload = (event) => {
    if (event.target.files.length) {
      let file = event.target.files[0];
      let file_size = event.target.files[0].size;
      if (file_size > 500000) {
        snackbar({
          message: "File Size should be less than 500Kb.",
          color: "danger",
        });
        setLogo(null);
      } else {
        fileToBase64(file)
          .then(async (result) => {
            const s = result.split(",");
            file["base64"] = result;
            setLogo(result);
          })
          .catch((err) => {
            console.log(err);
          });
      }
    }
  };
  const FinalSubmit = async () => {
    try {
      if (contractAccepted) {
        setError("");
        const data = {
          logoLink: logo ? logo.split(",")[1] : undefined,
          signature: sign ? sign.split(",")[1] : undefined,
          contractAccepted,
          // planId: "plan_KdAyby28NZQdQa",
        };
        let _val = globalState;
        delete _val.date;
        setLoader(true);
        postMutationUnAuth(CLINIC_CREATION, {
          ...data,
          ..._val,
          cancelledCheque: undefined,
        })
          .then(async (res) => {
            const data = res.data.addClinicCreationRequest;
            console.log(data);
            if (data.statusCode >= 200 && data.statusCode < 300) {
              const _contractLink = await getQueryUnAuth(
                UPLOAD_CLINIC_CONTRACT,
                {
                  requestId: data.data.id,
                }
              );
              const url = _contractLink.data?.uploadClinicContract?.data?.url;
              const _res1 = await axios.put(url, contractSigned, {
                headers: {
                  "Content-Type": "application/pdf",
                  "Access-Control-Allow-Origin": "*",
                },
              });
              let _res2 = true;
              if (globalState?.cancelledCheque) {
                let dot = globalState.cancelledCheque.name.lastIndexOf(".");
                let ext = globalState.cancelledCheque.name.slice(dot);
                const _chequeLink = await getQueryUnAuth(
                  UPLOAD_CANCELLED_CHECK,
                  {
                    requestId: data.data.id,
                    ext,
                  }
                );
                const chequeurl =
                  _chequeLink.data?.uploadCancelledCheque?.data?.url;

                _res2 = await axios.put(
                  chequeurl,
                  globalState.cancelledCheque,
                  {
                    headers: {
                      "Content-Type": "application/pdf",
                      "Access-Control-Allow-Origin": "*",
                    },
                  }
                );
              }
              console.log(_res1, _res2);
              if (_res1 && _res2) {
                snackbar({
                  message:
                    "Clinic Creation Request has been sent to Admin. You'll recieve acceptance/rejection mail soon.",
                  color: "success",
                });
                setViewPopup(true);
              }
            } else {
              throw new Error();
            }
          })
          .catch((err) => {
            console.log(err);
            snackbar({
              message: "Some error occured while clinic creation",
              color: "danger",
            });
          })
          .finally(() => {
            setLoader(false);
          });
      } else {
        setError("Please sign the contract");
      }
    } catch (error) {
      console.log(error);
      snackbar({
        message: "Some error occured while clinic creation",
        color: "danger",
      });
    }
  };

  const { data: contractText } = useQuery(
    ["get docg file contract"],
    async function () {
      try {
        const res = await getQueryUnAuth(GET_FILE_FOR_DOCG, {
          fileType: "ClinicContract",
        });
        const _res = await axios(res?.data?.getFileForDocg?.data?.url);
        console.log(_res);
        return _res?.data;
      } catch (err) {
        console.log(err);
        return null;
      }
    }
  );

  return (
    <>
      <VerificationPopup show={viewPopup} onHide={() => setViewPopup(false)} />
      <ContractPopup
        data={globalState}
        setContractSigned={setContractSigned}
        show={openContract}
        setShow={setOpenContract}
        contractText={contractText}
        sign={sign}
      />
      <Loader show={loader} />

      <div className="mbsc-grid">
        <div className="mbsc-card main-card-wrapper">
          <div className="signup-wrapper">
            <div className="mbsc-row">
              {/* <div className="mbsc-col-xl-4 doctor-images-left mbsc-col-12">
                <div className="left-signup-div" id="reset-password-page"></div>
                <img
                  src={step2_doctor}
                  className="left-signup-img left-steps-img"
                />
              </div> */}
              <div className="mbsc-col-xl-12 mbsc-col-12 signup-right ">
                <img src={grid} className="grid-top" />
                <img src={signin_circle} className="signin-circle" />
                <div className="bottom-circle" />
                <div className="top-circle" />
                <div className="right-signup-div">
                  <div className="get-started-wrapper">
                    <p className="signup-link">Step 4/4</p>
                    <p
                      style={{ color: "#757575" }}
                      className="signup-link mt-4"
                    >
                      Please provide following details:
                    </p>
                    <div className="signup-wrapper-inner justify-content-center">
                      <div
                        className={isMobileOnly ? "mbsc-row gap-6" : "mbsc-row"}
                      >
                        <div className="mbsc-col-12 mbsc-col-md-6">
                          <div className="textarea-wrap m-0 h-100 py-4">
                            <span>Step 1: Upload Logo and Signature</span>
                            <div className="row">
                              <div className=" col-12 col-md-6 col-lg-12">
                                <div className="docg-signup-upload  mb-2">
                                  <div className="upload-btn">
                                    <img src={upload} alt="" />
                                    Upload Logo&nbsp;{" "}
                                    <span style={{ fontSize: 10 }}>
                                      *(max size is 500kb)
                                    </span>
                                  </div>
                                  <input
                                    accept="image/*"
                                    onChange={(e) => {
                                      logoUpload(e);
                                    }}
                                    type="file"
                                    id="logo-upload"
                                    style={{ display: "block" }}
                                  />
                                </div>
                              </div>

                              <div className="docg-signup-upload col-12 col-md-6 col-lg-12 mb-2">
                                <div className="upload-btn">
                                  <img src={upload} alt="" />
                                  Upload Signature&nbsp;{" "}
                                  <span style={{ fontSize: 10 }}>
                                    *(max size is 500kb)
                                  </span>
                                </div>
                                <input
                                  accept="image/*"
                                  onChange={signUpload}
                                  id="sign-upload"
                                  type="file"
                                  style={{ display: "block" }}
                                />
                              </div>
                            </div>
                            <div className="row mt-2">
                              <div className="col-lg-6 col-md-6">
                                {logo != null ? (
                                  <div className="logo-upload-area">
                                    <img src={logo} />
                                    <span
                                      className="close"
                                      onClick={removeLogo}
                                    >
                                      <img src={cancelSign} />
                                    </span>
                                  </div>
                                ) : (
                                  ""
                                )}{" "}
                              </div>
                              <div className="col-lg-6 col-md-6 col-">
                                {sign != null ? (
                                  <div className="logo-upload-area">
                                    <img src={sign} />
                                    <span
                                      className="close"
                                      onClick={removeSign}
                                    >
                                      <img src={cancelSign} />
                                    </span>
                                  </div>
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="mbsc-col-12 mbsc-col-md-6">
                          <div
                            className={`textarea-wrap m-0 h-100 py-4 position-relative`}
                          >
                            {sign && logo ? null : (
                              <div className="docg-backdrop"></div>
                            )}

                            <span>Step 2: Sign/Upload Contract</span>
                            <div className="d-flex align-items-center justify-content-center gap-6 w-100">
                              {contractSigned ? (
                                <>
                                  <button
                                    onClick={() => {
                                      var blobURL =
                                        URL.createObjectURL(contractSigned);
                                      window.open(blobURL, "_blank");
                                    }}
                                    className="docg-outline-btn flex-fill justify-content-center"
                                  >
                                    View Contract
                                  </button>
                                  <h6 className="text-pink m-0"> OR</h6>
                                  <button
                                    onClick={() => {
                                      setContractSigned(null);
                                    }}
                                    className="docg-outline-btn flex-fill justify-content-center"
                                  >
                                    Delete Contract
                                  </button>
                                </>
                              ) : (
                                <div className="d-flex align-items-center justify-content-center gap-6 w-100">
                                  <button
                                    onClick={() => {
                                      if (sign) {
                                        setError("");
                                        setOpenContract(true);
                                      } else {
                                        setError(
                                          "Please upload signature before signing the contract"
                                        );
                                      }
                                    }}
                                    className="docg-save-btn flex-fill justify-content-center"
                                  >
                                    Sign Contract
                                  </button>
                                  <h6 className="text-pink m-0"> OR</h6>
                                  <div className="docg-signup-upload flex-fill">
                                    <div className="upload-btn">
                                      <img src={upload} alt="" />
                                      Upload Contract&nbsp;{" "}
                                      <span style={{ fontSize: 10 }}></span>
                                    </div>
                                    <input
                                      accept="application/pdf"
                                      onChange={contractUpload}
                                      id="contract-upload"
                                      type="file"
                                      style={{ display: "block" }}
                                    />
                                  </div>
                                </div>
                              )}{" "}
                            </div>

                            <div className="mbsc-row d-flex align-items-center justify-content-center mt-2">
                              <br />
                              <div className="custom-repeat-checkbox-cont">
                                <Checkbox checked={contractAccepted}>
                                  Signed DocG Contract
                                </Checkbox>
                              </div>
                              <br />
                              <div className="text-danger">{error}</div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <button
                        className="docg-save-btn mb-2 mt-2"
                        style={{ width: "fit-content", alignSelf: "center" }}
                        onClick={FinalSubmit}
                        disabled={!sign || !logo}
                      >
                        <img src={submit_icon} />
                        Submit
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Step5;
