import { Input, snackbar } from "@mobiscroll/react5";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import React from "react";
import { Modal } from "react-bootstrap";
import Contract from "./Contract";
import { pdf } from "@react-pdf/renderer";

function ContractPopup({
  show,
  setShow,
  data,
  setContractSigned,
  contractText,
  sign,
}) {
  const [error, setError] = React.useState(false);
  const [name, setName] = React.useState("");

  async function savePdf() {
    try {
      let _data = data;
      _data.date = new Date().toLocaleString();
      const blob = await pdf(
        <Contract data={_data} html={contractText} sign={sign} />
      ).toBlob();
      setContractSigned(blob);
      setShow(false);
    } catch (err) {
      console.log(err);
      snackbar({
        message:
          "Some error occured while submitting contract. Please try again",
        color: "danger",
      });
      setShow(false);
    }
  }
  const contractRef = React.useRef(null);
  const htmlStringToPdf = async (_htmlString) => {
    let htmlString = contractText;

    let iframe = document.createElement("iframe");
    iframe.style.visibility = "hidden";
    document.body.appendChild(iframe);
    let iframedoc = iframe.contentDocument || iframe.contentWindow.document;
    iframedoc.body.innerHTML = htmlString;

    let canvas = await html2canvas(iframedoc.body, {});

    // Convert the iframe into a PNG image using canvas.
    let imgData = canvas.toDataURL("image/png");

    // Create a PDF document and add the image as a page.
    const doc = new jsPDF({
      format: "a4",
      unit: "mm",
    });
    doc.addImage(imgData, "PNG", 0, 0, 210, 297);
    doc.save("test.pdf");
    // Get the file as blob output.
    let blob = doc.output("blob");

    // Remove the iframe from the document when the file is generated.
    //  document.body.removeChild(iframe);
  };

  return (
    <Modal
      show={show}
      onHide={() => setShow(false)}
      backdrop="static"
      keyboard={false}
      dialogClassName="docg-modal"
      size="xl"
      centered
    >
      <button onClick={htmlStringToPdf}>print</button>
      <div style={{ overflow: "auto" }} className="p-4" id="contract-container">
        <div className="mbsc-row">
          <div className="mbsc-col-12 d-flex w-100 justify-content-center">
            <h3>
              {" "}
              <u> Exclusive Digital Healthcare Technology License Agreement</u>
            </h3>
          </div>
        </div>
        <div className="mt-2">
          <div style={{ textAlign: "justify" }} className="">
            This Agreement, effective as of {new Date().toLocaleDateString()}{" "}
            (the “Effective Date”), is between [Contracter] (Client){" "}
            {data?.clinicName}, with a principal office at {data?.address},{" "}
            {data?.city} and [Licensee Company] (“Company”), Docg with a
            principal place of business at 24 street, Noida. R E C I T A L S
          </div>
          <br />

          <div className="mbsc-row">
            <div className="mbsc-col-12">
              <h5>
                {" "}
                <u>Client</u>{" "}
              </h5>
            </div>
            <div className="mbsc-col-4">
              <Input
                inputStyle="underline"
                labelStyle="floating"
                label="Name"
                placeholder=""
                name="Name"
                value={[data?.title, data?.firstName, data?.lastName]
                  .filter((item) => item != null)
                  .join(" ")}
                readOnly
              />
            </div>
            <div className="mbsc-col-4">
              <Input
                inputStyle="underline"
                labelStyle="floating"
                label="Clinic Name"
                placeholder=""
                name="Clinic Name"
                value={data?.clinicName}
                readOnly
              />
            </div>
            <div className="mbsc-col-4">
              <Input
                inputStyle="underline"
                labelStyle="floating"
                label="Medical Registration No."
                placeholder=""
                name="Medical Registration No."
                value={data?.registrationNumber}
                readOnly
              />
            </div>
            <div className="mbsc-col-8">
              <Input
                inputStyle="underline"
                labelStyle="floating"
                label="Address"
                placeholder=""
                name="Address"
                value={data?.clinicAddress}
                readOnly
              />
            </div>
            <div className="mbsc-col-4">
              <Input
                inputStyle="underline"
                labelStyle="floating"
                label="Zip"
                placeholder=""
                name="Zip"
                value={data?.pinCode}
                readOnly
              />
            </div>
            <div className="mbsc-col-4">
              <Input
                inputStyle="underline"
                labelStyle="floating"
                label="City"
                placeholder=""
                name="City"
                value={data?.district}
                readOnly
              />
            </div>
            <div className="mbsc-col-4">
              <Input
                inputStyle="underline"
                labelStyle="floating"
                label="state"
                placeholder=""
                name="state"
                value={data?.state}
                readOnly
              />
            </div>
            <div className="mbsc-col-4">
              <Input
                inputStyle="underline"
                labelStyle="floating"
                label="Phone Number"
                placeholder=""
                name="Phone Number"
                value={data?.phoneNumber}
                readOnly
              />
            </div>
            <div className="mbsc-col-4">
              <Input
                inputStyle="underline"
                labelStyle="floating"
                label="Email"
                placeholder=""
                name="Email"
                value={data?.email}
                readOnly
              />
            </div>
          </div>
          <br />
          <div
            ref={contractRef}
            dangerouslySetInnerHTML={{ __html: contractText }}
          />
          <div className="mbsc-col-12 d-flex align-items-start justify-content-start flex-column">
            <div className="">
              <div className="sigContainer">
                <Input
                  inputStyle="underline"
                  labelStyle="floating"
                  label="Sign Contract"
                  placeholder=""
                  name="Name"
                  value={name}
                  onChange={(e) => {
                    setName(e.target.value);
                    if (e.target.value.trim().length > 0) setError(false);
                    else setError(true);
                  }}
                  error={error}
                  errorMessage="Please sign with complete name, including title, as mentioned in client name above."
                />
                {new Date().toLocaleString()}
              </div>
            </div>
            <br />
            <table class="table table-bordered">
              <thead>
                <tr>
                  <th scope="col">Party</th>
                  <th scope="col">XYZ Healthcare limited</th>
                  <th scope="col">ABC Clinic</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td scope="row">Signature</td>
                  <td></td>
                  <td>
                    <img
                      src={sign}
                      style={{ height: "70px", width: "auto" }}
                      alt=""
                    />
                  </td>
                </tr>
                <tr>
                  <td scope="row">Name</td>
                  <td>XYZ</td>
                  <td>{data?.clinicName}</td>
                </tr>
                <tr>
                  <td scope="row">Designation</td>
                  <td></td>
                  <td></td>
                </tr>
                <tr>
                  <td scope="row">Date</td>
                  <td></td>
                  <td></td>
                </tr>
              </tbody>
            </table>
            <div className="d-flex text-center flex-column w-100">
              <b className="">
                <u>SCHEDULE 1</u>
              </b>
              <b>
                <u>Details of the clinic</u>
              </b>
            </div>
            <table class="table table-bordered">
              <thead>
                <tr>
                  <th scope="col">S. No</th>
                  <th scope="col">Particulars</th>
                  <th scope="col">Details</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td scope="row">q.</td>
                  <td>Name</td>
                  <td>{data?.clinicName}</td>
                </tr>
                <tr>
                  <td scope="row">2.</td>
                  <td>Address</td>
                  <td>{data?.clinicAddress}</td>
                </tr>
                <tr>
                  <td scope="row">3.</td>
                  <td>Email ID</td>
                  <td>{data?.email}</td>
                </tr>
                <tr>
                  <td scope="row">4.</td>
                  <td>Clinic Registration Details</td>
                  <td>{data?.registrationNumber}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="mbsc-col-12 d-flex">
            <button
              onClick={() => setShow(false)}
              className="docg-save-btn mr-4"
            >
              Cancel
            </button>
            <button
              onClick={() => {
                if (
                  name == null ||
                  name.trim().length == 0 ||
                  name !=
                    [data?.title, data?.firstName, data?.lastName]
                      .filter((item) => item != null)
                      .join(" ")
                )
                  setError(true);
                else savePdf();
              }}
              className="docg-save-btn"
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default ContractPopup;
