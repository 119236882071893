import React, { Suspense, useState, useCallback } from "react";
import { Modal } from "react-bootstrap";
import { Card, CardContent, Button } from "@mobiscroll/react";
import Avatar from "react-avatar";
import arrow from "src/assets/icons/black-arrow.svg";
import defaultPatientIcon from "src/assets/defaultPatient.svg";
import "./patient.scss"

const InsuranceDialog = React.lazy(() => import("./dialogs/InsurancePopup"));
const AllergiesDialog = React.lazy(() => import("./dialogs/AllergiesPopup"));

const PatientModal = ({ patientDetails, isDialog, closeDialog, title }) => {
  const [isAllergiesDialog, setAllergiesDialog] = useState(false);
  const [isInsuranceDialog, setInsuranceDialog] = useState(false);
  let isPersonalDialog = title == "Personal details";

  const openAllergiesDialog = useCallback(() => {
    setAllergiesDialog(true);
  }, []);

  const closeAllergiesDialog = useCallback(() => {
    setAllergiesDialog(false);
  }, []);

  const openInsuranceDialog = useCallback(() => {
    setInsuranceDialog(true);
  }, []);

  const closeInsuranceDialog = useCallback(() => {
    setInsuranceDialog(false);
  }, []);
  return (
    <>
      <Modal
        show={isDialog}
        onHide={closeDialog}
        size={"md"}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg" className="ml-3">
            <span className="ModalTitle">{title}</span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="d-flex flex-column align-items-start justify-content-center docg-patient-sidebar">
          <div className="mbsc-row mb-2 mt-1">
            {/* <div className="mbsc-col-5 mbsc-col-sm-5 mbsc-col-md-5 mbsc-col-lg-5 pl-0 mt-1"> */}
            <div className="d-flex justify-content-center align-items-center mbsc-col-12">
              <Avatar
                color="#c06c84"
                round
                size="50"
                name={patientDetails?.name}
                src={patientDetails?.profilePicture || defaultPatientIcon}
              />
              {/* </div> */}

              <div className="docg-patient-name ml-1">
                <span className="">
                  {patientDetails?.firstName || patientDetails?.lastName
                    ? `${
                        patientDetails?.title ? patientDetails?.title + " " : ""
                      }${patientDetails?.firstName} ${patientDetails?.lastName}`
                    : "-"}
                </span>{" "}
              </div>
            </div>
          </div>
          {!isPersonalDialog && (
            <>
              <div className="mbsc-row mb-2 mt-1">
                <div className="mbsc-col-12">
                  <strong className="docg-patient-name">
                    Contact Details:{" "}
                  </strong>
                  {patientDetails?.street ? `${patientDetails?.street}, ` : ""}
                  {patientDetails?.city ? `${patientDetails?.city}, ` : ""}
                  {patientDetails?.state ? `${patientDetails?.state}, ` : ""}
                  {patientDetails?.postalCode}
                </div>
              </div>
              <div className="mbsc-row mb-2">
                <div className="mbsc-col-12">
                  <strong className="docg-patient-name">Phone No.: </strong>
                  {patientDetails?.phoneNumber
                    ? patientDetails?.phoneNumber
                    : "-"}{" "}
                </div>
              </div>

              <div className="mbsc-row mb-2">
                <div className="mbsc-col-12">
                  <strong className="docg-patient-name">Email: </strong>
                  {patientDetails?.email ? patientDetails?.email : "-"}
                </div>
              </div>
            </>
          )}
          {isPersonalDialog && (
            <>
              <div className="mbsc-row mb-2">
                <div className="mbsc-col-12">
                  <strong className="docg-patient-name">Blood Group: </strong>
                  {patientDetails?.bloodGroup
                    ? patientDetails?.bloodGroup
                    : "-"}{" "}
                </div>
              </div>
              <div className="mbsc-row mb-2">
                <div className="mbsc-col-12">
                  <strong className="docg-patient-name">Age, Gender: </strong>
                  {patientDetails?.age ? patientDetails?.age : "-"}
                  {patientDetails?.gender ? `, ${patientDetails?.gender}` : ""}
                </div>
              </div>
              <div className="mbsc-row mb-2">
                <div className="mbsc-col-12">
                  <strong className="docg-patient-name">
                    Social History:{" "}
                  </strong>
                  {patientDetails?.socialHistory?.length > 0
                    ? patientDetails?.socialHistory.join(", ")
                    : "-"}
                </div>
              </div>
              <div className="mbsc-row mb-2">
                <div className="mbsc-col-12">
                  <strong className="docg-patient-name">
                    Insurance Company Name:{" "}
                  </strong>
                  {patientDetails?.insuranceCompanyName
                    ? patientDetails?.insuranceCompanyName
                    : "-"}{" "}
                </div>
              </div>
              <div className="mbsc-row mb-2">
                <div className="mbsc-col-12">
                  <strong className="docg-patient-name">
                    Insurance Number:{" "}
                  </strong>
                  {patientDetails?.insuranceNumber
                    ? patientDetails?.insuranceNumber
                    : "-"}{" "}
                </div>
              </div>
              <div className="mbsc-row mb-2">
                <div className="mbsc-col-12">
                  <button
                    className="docg-patient-name p-0"
                    onClick={() => openAllergiesDialog()}
                  >
                    <strong className="docg-patient-name pink-bold">Allergies</strong>
                    &nbsp;
                    <img src={arrow} alt="" />
                  </button>
                </div>
              </div>
            </>
          )}
        </Modal.Body>
      </Modal>
      <Modal
        size="lg"
        show={isAllergiesDialog}
        onHide={closeAllergiesDialog}
        dialogClassName="followup-modal docg-modal"
        aria-labelledby="example-custom-modal-styling-title"
        centered
      >
        {/* <Card className="docg-card"> */}
        {/* </Card> */}
        <Suspense fallback={<div>Loading...</div>}>
          <AllergiesDialog closePopup={closeAllergiesDialog} />
        </Suspense>
      </Modal>

      <Modal
        size="sm"
        show={isInsuranceDialog}
        onHide={closeInsuranceDialog}
        dialogClassName="followup-modal docg-modal"
        aria-labelledby="example-custom-modal-styling-title"
        centered
      >
        <Suspense fallback={<div>Loading...</div>}>
          <InsuranceDialog
            data={{
              insuranceNumber: patientDetails?.insuranceNumber,
              insuranceCompanyName: patientDetails?.insuranceCompanyName,
            }}
            closePopup={closeInsuranceDialog}
          />
        </Suspense>
      </Modal>
    </>
  );
};

export default PatientModal;
