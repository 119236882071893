import {
  getQueryWithParams,
  fetchQuery,
  postMutation,
} from "src/components/ApolloConnection/ApolloProviderComponent";
import {
  TOTAL_APPOINTMENTS_COUNT,
  LIST_CLINIC_PATIENT,
} from "src/components/graphql/GraphQLquery";
import { snackbar } from "@mobiscroll/react5";
import { getDoctors } from "src/components/api/index";

export async function getTotalNumberOfAppointments() {
  try {
    let doctorIds = sessionStorage.getItem("doctors-list");
    if (!doctorIds) {
      doctorIds = await getDoctors();
    }
    doctorIds = JSON.parse(doctorIds);
    doctorIds = doctorIds.map((doctor) => doctor.id);
    console.log(doctorIds);
    let response = await getQueryWithParams(
      TOTAL_APPOINTMENTS_COUNT("status=Completed"),
      doctorIds
    );
    if (typeof response == "undefined") {
      throw new Error(response);
    } else {
      if (response?.data?.listClinicAppointments?.totalCount >= 0) {
        return response.data.listClinicAppointments.totalCount;
      } else {
        throw new Error(response);
      }
    }
  } catch (error) {
    snackbar({
      message: "Error while getting Total Number of Appointments.",
      color: "danger",
    });
    console.log("ERROR at list appointments", error);
    return null;
  }
}

export async function listDoctors() {
  let doctorIds = sessionStorage.getItem("doctors-list");
  console.log(doctorIds);
  if (doctorIds == null) {
    let res = await getDoctors();
    doctorIds = res.map((doctor) => doctor.id);
    return doctorIds;
  }

  doctorIds = JSON.parse(doctorIds);
  doctorIds = doctorIds.map((doctor) => doctor.id);
  return doctorIds;
}

export async function getAppointmentsStartDate() {
  try {
    const doctorIds = await listDoctors();
    let response = await getQueryWithParams(
      TOTAL_APPOINTMENTS_COUNT("status=Completed"),
      doctorIds
    );
    if (typeof response == "undefined") {
      throw new Error(response);
    } else {
      if (response?.data?.listClinicAppointments?.data) {
        return response.data.listClinicAppointments.data;
      } else {
        throw new Error(response);
      }
    }
  } catch (error) {
    snackbar({
      message: "Error while getting Appointments Chart.",
      color: "danger",
    });
    console.log("ERROR at list appointments", error);
    return [];
  }
}

export async function listAppointmentsYearByYear() {
  try {
    const data = await getAppointmentsStartDate();
    let appointmentsYearByYear = new Object();
    data.map(function (appt) {
      let currApptDate = new Date(appt.start);
      let currYear = currApptDate.getFullYear();
      console.log(appt, currApptDate, currYear, data);
      if (appointmentsYearByYear[currYear] != undefined) {
        appointmentsYearByYear[currYear]++;
      } else {
        appointmentsYearByYear[currYear] = 1;
      }
    });
    console.log(appointmentsYearByYear);
    let arr = new Array();
    Object.keys(appointmentsYearByYear).map(function (key) {
      console.log(key, appointmentsYearByYear[key]);
      arr.push({ year: String(key), value: appointmentsYearByYear[key] });
    });
    return arr;
  } catch (err) {
    console.log("ERROR AT LIST APPOINTMENTS CHART: ", err);
  }
}

export async function getPatientsCount() {
  try {
    const doctorIds = await listDoctors();
    let response = await getQueryWithParams(
      LIST_CLINIC_PATIENT(2147483647, ""),
      doctorIds
    );
    if (typeof response == "undefined") {
      throw new Error(response);
    } else {
      if (response?.data?.listClinicPatients?.totalCount) {
        return response.data.listClinicPatients.totalCount;
      } else {
        throw new Error(response);
      }
    }
  } catch (error) {
    snackbar({
      message: "Error while getting Patients Chart.",
      color: "danger",
    });
    console.log("ERROR at list appointments", error);
    return "-";
  }
}

export async function getPatientsCreatedAt() {
  try {
    const doctorIds = await listDoctors();
    let response = await getQueryWithParams(
      LIST_CLINIC_PATIENT(2147483647, ""),
      doctorIds
    );
    if (typeof response == "undefined") {
      throw new Error(response);
    } else {
      if (response?.data?.listClinicPatients?.data) {
        return response.data.listClinicPatients.data;
      } else {
        throw new Error(response);
      }
    }
  } catch (error) {
    snackbar({
      message: "Error while getting Patients Chart.",
      color: "danger",
    });
    console.log("ERROR at list appointments", error);
    return [];
  }
}

export async function listPatientsYearByYear() {
  try {
    const data = await getPatientsCreatedAt();
    let patientsYearByYear = new Object();
    data.map(function (patient) {
      let currApptDate = new Date(patient.createdAt);
      let currYear = currApptDate.getFullYear();
      if (patientsYearByYear[currYear] != undefined) {
        patientsYearByYear[currYear]++;
      } else {
        patientsYearByYear[currYear] = 1;
      }
    });
    console.log(patientsYearByYear);
    let arr = new Array();
    Object.keys(patientsYearByYear).map(function (key) {
      console.log(key, patientsYearByYear[key]);
      arr.push({ year: String(key), value: patientsYearByYear[key] });
    });
    return arr;
  } catch (err) {
    console.log(err);
    return [];
  }
}
