import React, { useState } from "react";
import step5_doctor from "../../assets/Doctors 06.svg";
import grid from "../../assets/grid.svg";
import signin_circle from "../../assets/signin-circle.svg";
import mobiscroll, { toast, snackbar } from "@mobiscroll/react";
import save_icon from "../../assets/Save.svg";
import line from "../../assets/Lines.svg";
import { useHistory } from "react-router-dom";

const ChangePassword = () => {
  const history = useHistory();
  const [user, setUser] = useState({});
  const [formErrors, setFormErrors] = useState({});

  const minMaxLength = (text, minLength, maxLength) => {
    let result = !text || text.length < minLength;
    if (maxLength) result = result || text.length < minLength;
    return result;
  };

  const validEmail = (text) => {
    const regex = RegExp(
      /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
    );

    return !regex.test(text);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    let currentFormErrors = {};

    switch (name) {
      case "username":
        setUser({ ...user, username: value });
        if (!value || validEmail(value)) {
          currentFormErrors[name] = `Email address is invalid`;
        } else {
          delete currentFormErrors[name];
        }
        break;
      case "password":
        setUser({ ...user, password: value });
        if (minMaxLength(value, 8)) {
          currentFormErrors[name] = `Password should have minimum 8 characters`;
        } else {
          delete currentFormErrors[name];
        }
        break;
      default:
        break;
    }
    setFormErrors(currentFormErrors);
  };

  // const awsNewPasswordSetup = async() =>{
  //     try{
  //         Auth.signIn(user.email, user.password)
  //             .then(user => {
  //                 Auth.completeNewPassword(user,user.password)
  //             })
  //             .catch(err => console.log(err));
  //     }
  //     catch(err){
  //         console.log(err)
  //     }
  // }

  return (
    <>
      <div className="mbsc-grid">
        <div className="mbsc-card main-card-wrapper">
          <div className="signup-wrapper">
            <div className="mbsc-row">
              <div className="mbsc-col-lg-6 mbsc-col-md-6 mbsc-col-12">
                <div className="left-signup-div"></div>
                <img
                  src={step5_doctor}
                  className="left-signup-img left-steps-img"
                />
              </div>
              <div className="mbsc-col-lg-6 mbsc-col-md-6 mbsc-col-12 signup-right">
                <img src={grid} className="grid-top" />
                <img src={signin_circle} className="signin-circle" />
                <div className="bottom-circle" />
                <div className="top-circle" />
                <div className="right-signup-div">
                  <div className="get-started-wrapper">
                    {/* <p className="signup-link">Step 5/5</p> */}
                    <p
                      style={{ color: "#757575" }}
                      className="signup-link mt-4"
                    >
                      Please change your password
                    </p>
                    <div className="signup-wrapper-inner">
                      <mobiscroll.Input
                        inputStyle="underline"
                        labelStyle="floating"
                        placeholder="Username"
                        name="username"
                        onChange={handleChange}
                        type="email"
                        valid={!formErrors.username}
                        errorMessage={formErrors.username}
                      >
                        Username
                      </mobiscroll.Input>

                      <mobiscroll.Input
                        inputStyle="underline"
                        labelStyle="floating"
                        passwordToggle={true}
                        placeholder="Change Password"
                        name="password"
                        onChange={handleChange}
                        type="password"
                        valid={!formErrors.password}
                        errorMessage={formErrors.password}
                      >
                        Change Password
                      </mobiscroll.Input>
                      <div className="mbsc-row d-flex align-items-center justify-content-center mt-4">
                        <input
                          style={{ width: "auto" }}
                          id="tandC"
                          type="checkbox"
                        ></input>
                        <label
                          style={{ margin: "0" }}
                          for="tandC"
                          className="checbox-label ml-2"
                        >
                          Terms & Conditions
                        </label>
                      </div>
                      <br />
                      <button className="pink-button">
                        <img src={save_icon} />
                        &nbsp;&nbsp; Save Password
                      </button>
                    </div>
                  </div>
                </div>
                <img src={line} className="lines-bottom" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ChangePassword;
