import { useState } from "react";

const PDFReader = ({ pdf }) => {
  const [scale, setScale] = useState(1.0);
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [isLoading, setIsLoading] = useState(true);

  return (
    <div>
      <iframe
        title="pdf"
        src={pdf}
        style={{ width: "600px", height: "500px" }}
        frameborder="0"
      ></iframe>
    </div>
  );
};

export default PDFReader;
