import React from "react";
import verificationImg from "src/assets/verification.svg";
import leftArrow from "src/assets/leftArrow.svg";
import { Modal, Card } from "react-bootstrap";

const AddDrugPopup = ({ show, closePopup }) => {
  return (
    <Modal
      show={show}
      onHide={closePopup}
      backdrop="static"
      keyboard={false}
      dialogClassName="docg-modal"
      size="md"
      centered
    >
      <Card>
        <Card.Header
          className="w-100 text-center"
          style={{ backgroundColor: "#DEE2E6" }}
        >
          Verification Popup
        </Card.Header>
        <img src={verificationImg} alt="verification" />
        <a
          style={{
            width: "fit-content",
            position: "absolute",
            bottom: "0.8rem",
            left: "0.5rem",
            color: "#000",
          }}
          href="/"
        >
          {" "}
          <img style={{ height: "0.9rem" }} src={leftArrow} alt="" /> Home
        </a>
      </Card>
    </Modal>
  );
};

export default AddDrugPopup;
