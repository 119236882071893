import React from "react";
import { Link } from "react-router-dom";
import Avatar from "react-avatar";

const PatientNameCellRenderer = (params) => {
  console.log(params);
  return (
    <>
      <div className="docg-app-patient-name w-100 d-flex align-items-center-justify-content-start">
        <Avatar
          color="#c06c84"
          round
          size="35"
          name={params.value}
          src={params.data.profilePicture}
        />
        <span className="ml-2">{params.value}</span>
      </div>
    </>
  );
};

export default PatientNameCellRenderer;
