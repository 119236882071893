import React, { useState } from "react";
import { useFormik } from "formik";
import upload from "src/assets/upload.svg";
import { Input } from "@mobiscroll/react5";
import Loader from "src/components/ClinicDashboard/common/Loader";
import signup_icon from "src/assets/Signup icon  human.svg";
import attachmentIcon from "src/assets/Attachment-icon.svg";
import signin_circle from "src/assets/signin-circle.svg";
import doctor from "src/assets/login page doctors 02.svg";
import grid from "src/assets/grid.svg";
import cancelSign from "src/assets/close.svg";

import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { BsInfoCircleFill } from "react-icons/bs";
import * as Yup from "yup";
import { useStore } from "../store/Store";

const initialValues = {
  bankAccountNumber: "",
  ifscCode: "",
  reenterNumber: "",
  cancelledCheque: undefined,
};
const initialTouched = {
  bankAccountNumber: false,
  ifscCode: false,
  reenterNumber: false,
  cancelledCheque: false,
};

const validationSchema = Yup.object().shape({
  bankAccountNumber: Yup.string().test(
    "required",
    "Upload Cancelled Cheque or Enter Account details ",
    (value, ctx) =>
      (ctx.parent.reenterNumber && ctx.parent.ifscCode && value) ||
      Boolean(ctx.parent.cancelledCheque)
  ),
  ifscCode: Yup.string().test(
    "required",
    "Upload Cancelled Cheque or Enter Account details ",
    (value, ctx) =>
      (ctx.parent.reenterNumber && ctx.parent.bankAccountNumber && value) ||
      Boolean(ctx.parent.cancelledCheque)
  ),
  reenterNumber: Yup.string().test(
    "required",
    "Account Number does not match",
    (value, ctx) => ctx?.parent?.bankAccountNumber === value
  ),
  cancelledCheque: Yup.mixed()
    .test(
      "required",
      "Upload Cancelled Cheque or Enter Account details above",
      (value, ctx) => {
        console.log(value, ctx);
        return !(
          value == undefined &&
          !(
            ctx.parent.bankAccountNumber ||
            ctx.parent.ifscCode ||
            ctx.parent.reenterNumber
          )
        );
      }
    )
    .test("fileSize", "The file is too large", (value) => {
      if (value == undefined) return true;
      if (!value?.length) return true; // attachment is optional
      return value[0].size <= 2000000;
    }),
});

const Step2 = ({ setCurrentStep }) => {
  const [loader, setLoader] = React.useState(false);
  const { globalState, dispatch } = useStore();
  const [file, setFile] = useState(null);

  const {
    values,
    touched,
    errors,
    handleSubmit,
    handleChange,
    handleBlur,
    setFieldValue,
    handleReset,
    isValid,
  } = useFormik({
    initialValues,
    initialTouched,
    onSubmit: (values) => {
      console.log(values);
      dispatch({
        type: "CUSTOM",
        payload: {
          bankAccountNumber: values.bankAccountNumber,
          ifscCode: values.ifscCode,
          cancelledCheque: values.cancelledCheque,
        },
      });
      setCurrentStep(2);
    },
    validationSchema,
  });

  const handleFileChange = (e) => {
    setFieldValue("cancelledCheque", e.target.files[0], true);
  };

  const openContract = async (file) => {
    try {
      var blobURL = URL.createObjectURL(file);
      window.open(blobURL, "_blank");
    } catch (err) {}
  };

  const removeSign = (e) => {
    setFieldValue("cancelledCheque", null, true);
    document.getElementById("check-upload").value = "";
  };

  return (
    <>
      <Loader show={loader} />
      <div className="mbsc-grid">
        <div className="mbsc-card main-card-wrapper">
          <div className="signup-wrapper">
            <div className="mbsc-row">
              <div className="mbsc-col-xl-4 doctor-images-left mbsc-col-12">
                <div className="left-signup-div" id="reset-password-page">
                  {/* <div className="left-wrapper-image-section"></div> */}
                </div>
                <img src={doctor} className="left-signup-img left-steps-img" />
              </div>
              <div className="mbsc-col-xl-8 mbsc-col-12 signup-right">
                <img src={grid} className="grid-top" />
                <img src={signin_circle} className="signin-circle" />
                <div className="bottom-circle" />
                <div className="top-circle" />
                <div className="right-signup-div">
                  <div className="get-started-wrapper">
                    <p className="signup-link">Step 2/4</p>
                    <p
                      style={{ color: "#757575" }}
                      className="signup-link mt-4"
                    >
                      Please provide Bank Details:{" "}
                      {
                        <OverlayTrigger
                          key="bottom"
                          placement="bottom"
                          overlay={
                            <Tooltip id="tooltip-bottom">
                              Please provide Bank Details
                            </Tooltip>
                          }
                        >
                          <BsInfoCircleFill />
                        </OverlayTrigger>
                      }
                    </p>
                    <div className="signup-wrapper-inner">
                      <div className="row">
                        <div className="col-12">
                          <Input
                            inputStyle="underline"
                            labelStyle="floating"
                            label="Branch IFSC Code*"
                            type="text"
                            name="ifscCode"
                            value={values.ifscCode}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={touched.ifscCode && errors.ifscCode}
                            errorMessage={
                              touched.ifscCode ? errors.ifscCode : ""
                            }
                          />
                        </div>
                      </div>

                      <div className="row mt-3">
                        <div className="col-12">
                          <Input
                            inputStyle="underline"
                            labelStyle="floating"
                            label="Account Number*"
                            type="text"
                            name="bankAccountNumber"
                            value={values.bankAccountNumber}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={
                              touched.bankAccountNumber &&
                              errors.bankAccountNumber
                            }
                            errorMessage={
                              touched.bankAccountNumber
                                ? errors.bankAccountNumber
                                : ""
                            }
                          />
                        </div>
                      </div>

                      <div className="row mt-3">
                        <div className="col-12">
                          <Input
                            inputStyle="underline"
                            labelStyle="floating"
                            label="Re-enter Account Number*"
                            type="text"
                            name="reenterNumber"
                            value={values.reenterNumber}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={
                              touched.reenterNumber && errors.reenterNumber
                            }
                            errorMessage={
                              touched.reenterNumber ? errors.reenterNumber : ""
                            }
                          />
                        </div>
                      </div>

                      <div className="row mt-3 d-flex align-items-center justify-content-center">
                        <span>OR</span>
                        <div className="docg-signup-upload col-12 col-md-6 col-lg-12 mb-2">
                          <div className="upload-btn  purple-button">
                            <img src={upload} alt="" />
                            {values?.cancelledCheque?.name
                              ? values?.cancelledCheque?.name
                              : "Upload Cancelled Check"}
                            &nbsp; <span style={{ fontSize: 10 }}></span>
                          </div>
                          <input
                            id="check-upload"
                            type="file"
                            style={{ display: "block" }}
                            onBlur={handleBlur}
                            onChange={handleFileChange}
                            file={values.cancelledCheque}
                          />
                        </div>
                        {values?.cancelledCheque?.name && (
                          <div
                            className="logo-upload-area"
                            style={{ height: "50px", width: "56px" }}
                          >
                            <img
                              onClick={() =>
                                openContract(values?.cancelledCheque)
                              }
                              src={attachmentIcon}
                            />
                            <span className="close" onClick={removeSign}>
                              <img src={cancelSign} />
                            </span>
                          </div>
                        )}
                      </div>
                      <div className="mbsc-ios mbsc-error-message">
                        {touched.cancelledCheque ? errors.cancelledCheque : ""}
                      </div>

                      <button
                        onClick={handleSubmit}
                        className="purple-button mt-4"
                      >
                        <img src={signup_icon} style={{ marginLeft: "8%" }} />
                        &nbsp;&nbsp; Submit
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Step2;
