import React, { Fragment } from "react";
import { Helmet } from "react-helmet";
import PatientSidebar from "./PatientSidebar";
import PatientTabs from "./PatientTabs";
import {
  initialState,
  patientDetailsReducer,
} from "./store/PatientDetailsReducer";
import { StoreProvider } from "./store/Store";

const PatientDetail = (props) => {
  return (
    <Fragment>
      <StoreProvider
        initialState={initialState}
        reducer={patientDetailsReducer}
      >
        <Helmet>
          <title>Patient Detail | DOCG</title>
        </Helmet>
        <div className="patient-wrapper docg-vh-standard">
          <div className="">
            <PatientSidebar {...props} />
          </div>
          <div className="patient-tab">
            <PatientTabs {...props} />
          </div>
        </div>
      </StoreProvider>
    </Fragment>
  );
};

export default PatientDetail;
