import {
  getQueryWithParams,
  getQuery,
  postMutation,
} from "src/components/ApolloConnection/ApolloProviderComponent";
import {
  GET_PROFILE,
  LIST_SPECIALIZATIONS,
} from "src/components/graphql/GraphQLquery";
import { snackbar } from "@mobiscroll/react5";
import { UPDATE_PROFILE } from "src/components/graphql/GraphQLmutation";

export async function listSpecializations(search) {
  console.log(search);
  try {
    const response = await getQueryWithParams(
      LIST_SPECIALIZATIONS(String(search))
    );
    if (typeof response == "undefined" || response?.errors) {
      throw new Error("error", response);
    } else {
      snackbar({
        message: "Doctor's specialisations fetched successfully.",
        color: "success",
      });
      if (response?.errors) {
        throw new Error("error", response);
      } else if (response?.data?.listSpecializations?.data) {
        return response?.data?.listSpecializations?.data;
      } else {
        throw new Error("error", response);
      }
    }
  } catch (e) {
    snackbar({
      message: "Some error occured. Try again",
      color: "danger",
    });
    console.log("Error at fetching Specialisations ", e);
    return null;
  }
}

export async function getMyProfile() {
  try {
    const id = sessionStorage.getItem("clinic-id");
    const response = await getQueryWithParams(GET_PROFILE, { id });
    console.log(response);
    sessionStorage.setItem(
      "specialization",
      JSON.stringify(response.data.getClinicProfile.data.specialities)
    );
    if (typeof response === "undefined") {
      throw new Error("error", response);
    } else {
      if (
        response.data.getClinicProfile.statusCode < 300 &&
        response.data.getClinicProfile.statusCode >= 200
      ) {
        snackbar({
          message: "Profile Fetched Successfully.",
          color: "success",
        });
        return response.data.getClinicProfile.data;
      } else {
        throw new Error(response);
      }
    }
  } catch (e) {
    snackbar({
      message: "Some error occured while fetching Profile. Try again",
      color: "danger",
    });
    console.log("Error at add doctor fee: ", e.message);
    return null;
  }
}
export async function updateProfile(data) {
  console.log(data)
  try {
    const response = await postMutation(UPDATE_PROFILE, data);

    if (typeof response === "undefined") {
      throw new Error("error", response);
    } else {
      if (
        response.data.updateClinic.statusCode < 300 &&
        response.data.updateClinic.statusCode >= 200
      ) {
        // snackbar({
        //   message: "Profile Fetched Successfully.",
        //   color: "success",
        // });
        return response?.data?.updateClinic?.data;
      } else {
        throw new Error(response?.data?.updateClinic?.message);
      }
    }
  } catch (e) {
    snackbar({
      message:
        e.message || "Some error occured while updating profile. Try again",
      color: "danger",
    });
    console.log("Error at add doctor fee: ", e);
    return null;
  }
}
