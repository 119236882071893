import { Checkbox, RadioGroup, Select } from "@mobiscroll/react5";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import signup_icon from "src/assets/Signup icon  human.svg";
import grid from "src/assets/grid.svg";
import doctor from "src/assets/login page doctors 02.svg";
import signin_circle from "src/assets/signin-circle.svg";
import Loader from "src/components/ClinicDashboard/common/Loader";
import { useStore } from "../store/Store";
import { listAllRazorpayPlans } from "./api";

const Step3 = ({ setCurrentStep }) => {
  const [loader, setLoader] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState(null);
  const { globalState, dispatch } = useStore();

  const handleSubmit = async () => {
    dispatch({
      type: "CUSTOM",
      payload: { subscriptionType: selectedPlan },
    });
    setCurrentStep(3);
  };

  const { data: plans, isLoading } = useQuery(
    ["fetch subscriptions ids"],
    async () => {
      const res = await listAllRazorpayPlans();
      return res;
    }
  );

  return (
    <>
      <Loader show={loader || isLoading} />
      <div className="mbsc-grid">
        <div className="mbsc-card main-card-wrapper">
          <div className="signup-wrapper">
            <div className="mbsc-row">
              <div className="mbsc-col-xl-5 doctor-images-left mbsc-col-12">
                <div className="left-signup-div">
                  {/* <div className="left-wrapper-image-section"></div> */}
                </div>
                <img src={doctor} className="left-signup-img left-steps-img" />
              </div>
              <div className="mbsc-col-xl-7 mbsc-col-12 signup-right">
                <img src={grid} className="grid-top" />
                <img src={signin_circle} className="signin-circle" />
                <div className="bottom-circle" />
                <div className="top-circle" />
                <div className="right-signup-div">
                  <div className="get-started-wrapper">
                    <p className="signup-link">Step 3/4: Subscription</p>

                    <div className="signup-wrapper-inner">
                      <RadioGroup name="group">
                        <div className="mbsc-row d-flex flex-col justify-content-center align-items-center overflow-auto flex-wrap py-2">
                          <div className=" mbsc-col-12">
                            <Select
                              data={plans?.map((plan) => plan.subscriptionType)}
                              label="Plan Type*"
                              inputProps={{
                                inputStyle: "box",
                                labelStyle: "stacked",
                                placeholder: "Please select",
                              }}
                              touchUi={false}
                              value={selectedPlan}
                              onChange={(e) => {
                                setSelectedPlan(e.value);
                              }}
                            />
                          </div>
                          <div className="justify-content-center align-items-center w-100 d-flex mt-3">
                            {selectedPlan ? (
                              <PlanCard
                                plan={plans?.find(
                                  (itm) => itm?.subscriptionType == selectedPlan
                                )}
                                plans={plans}
                                selectedPlan={selectedPlan}
                              />
                            ) : null}
                          </div>
                        </div>
                      </RadioGroup>

                      <button
                        onClick={handleSubmit}
                        className="docg-save-btn d-flex justify-content-center mt-5"
                        disabled={!selectedPlan}
                      >
                        <img alt="" src={signup_icon} />
                        &nbsp;&nbsp; Submit
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Step3;

export const PlanCard = ({ plan, plans, selectedPlan }) => {
  const getDuration = (interval, period) => {
    let _period = "";
    switch (period) {
      case "daily":
        _period = "days";
        break;
      case "monthly":
        _period = "months";
        break;
      case "weekly":
        _period = "weeks";
        break;
      case "yearly":
        _period = "years";
        break;
      default:
        break;
    }

    return `/ ${interval} ${interval > 1 ? _period : _period.split("s")[0]}`;
  };

  const [list, setList] = useState([]);

  useEffect(() => {
    setList(plan.item.description?.split("\n").filter((itm) => itm));
  }, [plan]);

  return (
    <div className="docg-card docg-pricing docg-plan rounded p-4 border">
      <div className="d-flex upper-portion flex-column">
        <div className="d-flex align-items-start gap-2">
          <span className="pricing">
            &#8377; {Number(plan.item.amount / 100).toLocaleString("en-US")}{" "}
          </span>
          <span className="duration">
            {getDuration(plan?.interval, plan?.period)}
            {plan?.applicableTaxVerbiage
              ? " + " + plan?.applicableTaxVerbiage
              : null}
          </span>
        </div>
        {plan?.companyPercentage >= 0 ? (
          <span>
            {" "}
            + Online Payment Fee: {plan?.companyPercentage}%{" "}
            {plan?.verbiageForTransactFee}{" "}
          </span>
        ) : null}
        <span>
          {" "}
          <span className="pricing patients">
            {Number(plan.minPatientsAllowed).toLocaleString("en-US")} {" - "}
            {Number(plan.maxPatientsAllowed).toLocaleString("en-US")}{" "}
          </span>{" "}
          patients {plan?.unit}{" "}
        </span>
      </div>
      <hr style={{ borderTop: "3px solid rgba(180, 180, 180)" }} />
      <div className="d-flex description">
        <div className="d-flex flex-grow-1 h-full flex-column">
          <div
            className="flex-grow-1 text-left"
            style={{ whiteSpace: "pre-line" }}
          >
            <b>This Subscription plan includes:</b>
            {list.map((itm) => (
              <div className="custom-repeat-checkbox-cont">
                <Checkbox checked={true}>{itm}</Checkbox>
              </div>
            ))}
          </div>
          <div className="custom-repeat-radio-cont"></div>
        </div>
      </div>
    </div>
  );
};
