import { snackbar } from "@mobiscroll/react";
import { API, Auth, graphqlOperation } from "aws-amplify";
import React, { Fragment, useState } from "react";
import { Modal } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import { listAllNotifications, readNotification } from "src/api/index";
import CalculatorIcon from "src/assets/icons/Calculator.svg";
import DashobardIcon from "src/assets/icons/Dashboard.svg";
import SignOutIcon from "src/assets/icons/SignOutIcon.svg";
import notificationIcon from "src/assets/notification-tab.svg";
import walletIcon from "src/assets/wallet.svg";
import NotificationTopBar from "src/components/ClinicDashboard/NotificationTopBar/index";
import { RAZORPAY_SUBSCRIPTION_BEEP } from "src/components/graphql/GraphQLsubscription";
import { useStore } from "src/store/Store";
import { updateAllNotifications } from "src/store/notificationReducer";
import PdfViewer from "./PdfViewer";
import { subscription } from "src/components/ApolloConnection/ApolloProviderComponent";
import { signOut } from "aws-amplify/auth";

const HeaderTopClinic = ({ handleSidebar }) => {
  const history = useHistory();
  const [showNotifications, setShowNotifications] = useState(false);

  const { dispatchNotifications, globalStateNotifications, globalStateKPI } =
    useStore();

  const handleNotificationsView = React.useCallback(() => {
    setShowNotifications((prevState) => !prevState);
  }, [showNotifications]);

  async function _signOut() {
    snackbar({
      message: "You have been logged out",
      color: "danger",
    });
    try {
      await signOut({ global: true });
      localStorage.clear();
      sessionStorage.clear();
      window.location = "/";
    } catch (error) {
      console.log("error signing out: ", error);
    }
  }

  const fetchNotifications = async () => {
    const res = await listAllNotifications();
    return res;
  };

  React.useEffect(() => {
    subscription(
      RAZORPAY_SUBSCRIPTION_BEEP,
      {
        clinicId: sessionStorage.getItem("role-id") ?? null,
      },
      async (response) => {
        console.log(response);
        if (
          !(
            response.value.data.subscribeRazorpaySubscriptionsBeep.statusCode >=
              200 &&
            response.value.data.subscribeRazorpaySubscriptionsBeep.statusCode <
              300
          ) ||
          response.value.data.subscribeRazorpaySubscriptionsBeep.errors != null
        ) {
          return;
        }
        //fetch notifications
        let notifications = await fetchNotifications();
        dispatchNotifications(updateAllNotifications(notifications));

        console.log(notifications);
      },
      (error) => {
        console.log(error);
      }
    );
  }, []);

  React.useEffect(() => {
    //fetch notifications
    (async () => {
      const notifications = await fetchNotifications();
      console.log(notifications);
      dispatchNotifications(updateAllNotifications(notifications));
    })();
    return () => {};
  }, []);

  return (
    <Fragment>
      <nav className="navbar navbar-expand-lg navbar-transparent fixed-top navbar-fixed-top">
        <div className="container-fluid d-md-flex">
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            aria-controls="navigation-index"
            aria-expanded="false"
            aria-label="Toggle navigation"
            onClick={() => {
              handleSidebar(true);
            }}
          >
            <img src={DashobardIcon} alt="" />
          </button>
          {/* <button
            type="button"
            className="btn btn-link ml-3"
            onClick={() => {
              handleSidebar(true);
            }}
          >
            <img src={DashobardIcon} alt="" />
          </button> */}
          <div className="docg-top-bar-left  jc-sb">
            <div className="navbar-top-first  mbsc-align-items-center d-lg-flex d-md-none d-sm-none">
              <div className="navbar-wrapper">
                <button
                  type="button"
                  className="btn btn-link mr-1"
                  onClick={() => {
                    handleSidebar(false);
                  }}
                >
                  <img src={DashobardIcon} alt="" />
                </button>
              </div>
            </div>

            <ul className="navbar-nav  align-items-center">
              {/*   <li className="nav-item">
                <Link className="nav-link" to="/email">
                  <img src={MessageIcon} alt="" />
                  {/* <p className="d-lg-none d-md-none d-sm-none">Stats</p> */}
              {/*</Link>
              </li> */}
              <li className="nav-item doctor-logs">
                <Link className="nav-link" title="Calendar" to="/calendar">
                  <img src={CalculatorIcon} alt="" />
                  {/* <p className="d-lg-none d-md-none d-sm-none">Some Actions</p> */}
                </Link>
              </li>
              <li className="nav-item doctor-logs">
                <button
                  onClick={handleNotificationsView}
                  title="Notifications"
                  className="nav-link"
                >
                  <img src={notificationIcon} alt="" />
                  <span>{globalStateNotifications.notifications.length}</span>
                </button>
              </li>
              <li className="nav-item doctor-logs">
                <button
                  onClick={() => history.push("/subscription/history")}
                  title="subscriptions"
                  className="nav-link"
                >
                  <img style={{ height: "30px" }} src={walletIcon} alt="" />
                </button>
              </li>
              <li className="nav-item doctor-logs">
                <button onClick={_signOut} title="Logout" className="nav-link">
                  <img src={SignOutIcon} alt="" />
                </button>
              </li>
            </ul>
          </div>
        </div>
      </nav>
      <NotificationTopBar
        showNotifications={showNotifications}
        handleNotificationsView={handleNotificationsView}
        title="Notifications"
      />
      {globalStateNotifications?.notifications[0]?.metadata.updatedFile &&
      !globalStateNotifications.notifications[0].acknowledged &&
      globalStateNotifications.notifications[0].metadata.notificationType ===
        "TermsUpdatedNotification" ? (
        <Modal
          size="lg"
          show={
            !globalStateNotifications.notifications[0].acknowledged &&
            globalStateNotifications.notifications[0].metadata
              .notificationType === "TermsUpdatedNotification"
          }
          // onHide={handleNotificationsView}
          aria-labelledby="example-modal-sizes-title-lg"
        >
          <Modal.Header>
            <Modal.Title id="example-modal-sizes-title-lg" className="ml-3">
              <span className="ModalTitle">
                Updated Terms and Conditions @{" "}
                {new Date(
                  globalStateNotifications?.notifications[0]?.createdAt
                ).toLocaleString([], {
                  year: "numeric",
                  month: "long",
                  day: "numeric",
                  hour: "2-digit",
                  minute: "2-digit",
                })}
              </span>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ maxHeight: "82vh", overflow: "auto" }}>
            <PdfViewer
              pdf={
                globalStateNotifications?.notifications[0]?.metadata.updatedFile
              }
              // pdf="./na2.pdf"
            />
            <div>
              <button
                onClick={async (e) => {
                  await readNotification({
                    id: globalStateNotifications?.notifications[0].id,
                  });
                  let notifications = await fetchNotifications();
                  dispatchNotifications(updateAllNotifications(notifications));
                }}
                className="docg-save-btn"
              >
                {" "}
                Accept{" "}
              </button>
            </div>
          </Modal.Body>
        </Modal>
      ) : null}
    </Fragment>
  );
};

export default HeaderTopClinic;
