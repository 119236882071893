import React, { Fragment } from "react";

import moment from "moment";
import { useStore } from "src/store/Store";

import watchIcon from "../../../assets/watch.svg";
import "../Layouts/Card.css";
import "./NotificationTopBar.css";

function NotificationTopBar(props) {
  const { dispatchNotifications, globalStateNotifications } = useStore();

  const convertTimeStamp = React.useCallback(
    (timestamp) => {
      return moment().diff(moment(timestamp), "days") != 0
        ? `${moment().diff(moment(timestamp), "days")} days ago`
        : moment().diff(moment(timestamp), "hours") != 0
        ? `${moment().diff(moment(timestamp), "hours")} hours ago`
        : moment().diff(moment(timestamp), "minutes") != 0
        ? `${moment().diff(moment(timestamp), "minutes")} minutes ago`
        : `${moment().diff(moment(timestamp), "seconds")} seconds ago`;
    },
    [moment]
  );

  const generateTitle = (data) => {
    switch (data.notificationType) {
      case "TermsUpdatedNotification":
        return (
          <>
            <span>
              Terms and conditions{" "}
              <a
                href={data?.updatedFile}
                target="_blank"
                rel="noreferrer"
                className="text-pink"
              >
                updated
              </a>{" "}
            </span>
          </>
        );
      case "SubscriptionPaymentNotification":
        return (
          <>
            <span>
              The{" "}
              <a
                href={`https://api.razorpay.com/v1/t/subscriptions/${data.subscriptionId}`}
                target="_blank"
                rel="noreferrer"
                className="text-pink"
              >
                subscription
              </a>{" "}
              with plan is {data.status}.
            </span>
          </>
        );
      case "SubscriptionCancelledNotification":
        return (
          <>
            <span>
              The{" "}
              <a
                href={`https://api.razorpay.com/v1/t/subscriptions/${data.subscriptionId}`}
                target="_blank"
                className="text-pink"
                rel="noreferrer"
              >
                subscription
              </a>{" "}
              with plan is {data.status}.
            </span>
          </>
        );
      case "SubscriptionActiveNotification":
        return (
          <>
            <span>
              The{" "}
              <a
                href={`https://api.razorpay.com/v1/t/subscriptions/${data.subscriptionId}`}
                className="text-pink"
                target="_blank"
                rel="noreferrer"
              >
                subscription
              </a>{" "}
              with plan is {data.status}.
            </span>
          </>
        );
      case "SubscriptionPausedNotification":
        return (
          <>
            <span>
              The{" "}
              <a
                href={`https://api.razorpay.com/v1/t/subscriptions/${data.subscriptionId}`}
                target="_blank"
                className="text-pink"
                rel="noreferrer"
              >
                subscription
              </a>{" "}
              with plan is {data.status}.
            </span>
          </>
        );
      case "SubscriptionResumedNotification":
        return (
          <>
            <span>
              The{" "}
              <a
                href={`https://api.razorpay.com/v1/t/subscriptions/${data.subscriptionId}`}
                className="text-pink"
                target="_blank"
                rel="noreferrer"
              >
                subscription
              </a>{" "}
              with plan is {data.status}.
            </span>
          </>
        );
      case "SubscriptionExpiringNotification":
        return (
          <>
            <span>
              The{" "}
              <a
                href={`https://api.razorpay.com/v1/t/subscriptions/${data.subscriptionId}`}
                target="_blank"
                className="text-pink"
                rel="noreferrer"
              >
                subscription
              </a>{" "}
              with plan is Expiring soon.
            </span>
          </>
        );
      case "SubscriptionUpdatedNotification":
        return (
          <>
            <span>
              The{" "}
              <a
                className="text-pink"
                href={`https://api.razorpay.com/v1/t/subscriptions/${data.subscriptionId}`}
                target="_blank"
                rel="noreferrer"
              >
                subscription
              </a>{" "}
              with plan is {data.status}.
            </span>
          </>
        );
      case "SubscriptionFreeTrailEndNotification":
        return (
          <>
            <span>The Free trial for this subscription is ended.</span>
          </>
        );
      case "PatientLimitNotification":
        return (
          <>
            <span>
              Current allowed paients list for{" "}
              <a
                href={`https://api.razorpay.com/v1/t/subscriptions/${data.subscriptionId}`}
                target="_blank"
                className="text-pink"
                rel="noreferrer"
              >
                this subscription
              </a>
              is {data.allowedPatients} which is exceeded to{" "}
              {data.currentPatients}.
            </span>
          </>
        );
      case "PlanUpdatedNotification":
        return (
          <>
            <span>The plan is updated</span>
          </>
        );

      default:
        break;
    }
  };

  console.log(globalStateNotifications);
  return (
    <Fragment>
      <p className="card-text m-0 add-overflow-content">
        {globalStateNotifications.notifications?.length > 0 ? (
          globalStateNotifications.notifications?.map((data, index) => (
            <Fragment key={index}>
              <div className="d-flex p-3 add-color-div align-items-center">
                {/* <Avatar
                  color="#c06c84"
                  round
                  size="50"
                  name={data?.metadata?.patientName}
                  src={data?.metadata?.patientProfilePicture}
                /> */}
                <div className="ml-3 text-left w-100">
                  <div className="d-flex justify-content-between">
                    <span style={{}} className="notification-name-txt">
                      {data.title}
                      {generateTitle(data?.metadata)}
                    </span>
                    <span className="minute-txt">
                      <img src={watchIcon} className="mr-2" alt="" />
                      {convertTimeStamp(data?.createdAt)}
                    </span>
                  </div>
                  <p className="paitentName m-0">{data.message}</p>
                </div>
              </div>
            </Fragment>
          ))
        ) : (
          <p>No data</p>
        )}
      </p>
    </Fragment>
  );
}

export default NotificationTopBar;
