import React from "react";
const ActionCellRender = (props) => {
  return (
    <label class="switch">
      <input type="checkbox" />
      <span class="custom-slider slider slider-po-show round"></span>
    </label>
  );
};

export default ActionCellRender;
