import {
  getQueryWithParams,
  getQuery,
  postMutation,
} from "src/components/ApolloConnection/ApolloProviderComponent";
import {
  LIST_DOCTOR_MEMBERSHIP_PLANS,
  GET_DOCTOR_ID,
  GET_UPLOAD_MEMBERSHIP_FILE_LINK,
} from "src/components/graphql/GraphQLquery";
import { snackbar } from "@mobiscroll/react5";
import { ADD_DOCTOR_MEMBERSHIP } from "src/components/graphql/GraphQLmutation";

export async function getUploadPolicyLink(data) {
  try {
    let response = await getQueryWithParams(
      GET_UPLOAD_MEMBERSHIP_FILE_LINK,
      data
    );
    if (typeof response == "undefined") {
      throw new Error(response);
    } else {
      if (response.data.uploadMPImageUrl.data) {
        return response.data.uploadMPImageUrl.data;
      } else {
        throw new Error(response);
      }
    }
  } catch (error) {
    snackbar({
      message: "Error while Uploading Fee Policy.",
      color: "danger",
    });
    console.log("ERROR at list fee", error);
    return null;
  }
}

export async function addDoctorMembershipPlan(data) {
  try {
    const response = await postMutation(ADD_DOCTOR_MEMBERSHIP, data);

    if (typeof response === "undefined") {
      throw new Error("error", response);
    } else {
      if (
        response.data.addDoctorMembershipPlan.statusCode < 300 &&
        response.data.addDoctorMembershipPlan.statusCode >= 200
      ) {
        snackbar({
          message: "Membership Added Successfully.",
          color: "success",
        });
        return true;
      } else {
        throw new Error(response);
      }
    }
  } catch (e) {
    snackbar({
      message: "Some error occured while adding Fee. Try again",
      color: "danger",
    });
    console.log("Error at add doctor fee: ", e);
    return false;
  }
}

export async function listDoctorMembershipPlans() {
  try {
    let response = await getQuery(LIST_DOCTOR_MEMBERSHIP_PLANS);
    if (typeof response == "undefined") {
      throw new Error(response);
    } else {
      if (response.data.listDoctorMembershipPlans.data) {
        snackbar({
          message: "Membership List fetched Successfully.",
          color: "success",
        });
        return response.data.listDoctorMembershipPlans.data;
      } else {
        throw new Error(response);
      }
    }
  } catch (error) {
    snackbar({
      message: "Error while fetching Membership list.",
      color: "danger",
    });
    console.log("ERROR at listing membership", error);

    return [];
  }
}
