import { snackbar } from "@mobiscroll/react5";
import { getQueryWithParams } from "src/components/ApolloConnection/ApolloProviderComponent";
import {
  GET_APPOINTMENT_DETAILS,
  GET_PATIENT,
  LIST_PATIENT_HISTORY,
} from "src/components/graphql/GraphQLquery";

export async function getPatientBasicDetails(data) {
  try {
    const response = await getQueryWithParams(GET_PATIENT, { id: data });
    if (
      typeof response == "undefined" ||
      response?.errors ||
      !(
        response?.data?.getPatient?.statusCode >= 200 &&
        response?.data?.getPatient?.statusCode < 300
      )
    ) {
      throw new Error("error", response);
    } else {
      snackbar({
        message: "Patient Information fetched successfully.",
        color: "success",
      });
      if (response?.errors) {
        throw new Error("error", response);
      } else if (response?.data?.getPatient?.data) {
        return response?.data?.getPatient?.data;
      } else {
        throw new Error("error", response);
      }
    }
  } catch (e) {
    snackbar({
      message: "Some error occured. Try again",
      color: "danger",
    });
    console.log("Error at fetching patient information ", e);
    return null;
  }
}

export async function listPatientHistory(patientId) {
  try {
    const response = await getQueryWithParams(LIST_PATIENT_HISTORY(patientId));
    if (typeof response == "undefined" || response?.errors) {
      throw new Error("error", response);
    } else {
      snackbar({
        message: "Patient Medical History fetched successfully.",
        color: "success",
      });
      if (response?.errors) {
        throw new Error("error", response);
      } else if (response?.data?.listPatientHistory?.data) {
        return response?.data?.listPatientHistory?.data;
      } else {
        throw new Error("error", response);
      }
    }
  } catch (e) {
    snackbar({
      message: "Some error occured. Try again",
      color: "danger",
    });
    console.log("Error at fetching patient Medical History ", e);
    return [];
  }
}

export async function getAppointmentDetails(data) {
  try {
    const response = await getQueryWithParams(GET_APPOINTMENT_DETAILS, data);
    console.log(response);
    if (typeof response === "undefined" || response.errors != null) {
      throw new Error("error", response);
    } else {
      if (
        response.data.getAppointment.statusCode < 300 &&
        response.data.getAppointment.statusCode >= 200
      ) {
        return response.data.getAppointment.data;
      } else {
        throw new Error(response);
      }
    }
  } catch (e) {
    snackbar({
      message:
        "Some error occured while fetching Appointment details. Try again",
      color: "danger",
    });
    console.log("Error at add doctor fee: ", e);
    return null;
  }
}
