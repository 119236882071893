import React, { Fragment, useEffect, useState } from "react";
import mobiscroll from "@mobiscroll/react";
import { Helmet } from "react-helmet";
import {
  addDoctorMembershipPlan,
  listDoctorMembershipPlans,
  getUploadPolicyLink,
} from "./api";
import Loader from "src/components/ClinicDashboard/common/Loader";
import { Input, Select, Number } from "@mobiscroll/react5";
import DataListingCard from "./DataListingCard/DataListingCard";
import uploadIcon from "../../../assets/upload.svg";
import saveIcon from "../../../assets/Save.svg";
import Membership from "../../../assets/membership.svg";
import useForm from "../../../lib/useForm";
import "@mobiscroll/react/dist/css/mobiscroll.min.css";
import { Col, Row, Card } from "react-bootstrap";
import { snackbar } from "@mobiscroll/react5";
import axios from "axios";
import createMembershipSchema from "./validationSchema/createMembershipSchema";
import "./Index.css";

const initialValues = {
  description: "",
  price: 10,
  paymentFrequency: "Monthly",
  validityTimeUnit: "Year",
  validityTime: 1,
  name: "",
  numberOfVisits: "",
  numberOfMembers: "",
};

function Index() {
  const {
    formValues,
    handleChange,
    touched,
    handleBlur,
    errors,
    customValidate,
    customChange,
    setErrors,
    setTouched,
    validationSchema,
  } = useForm({
    initialValues,
    validationSchema: createMembershipSchema,
  });

  const [uploadFile, setUploadFile] = useState(undefined);
  const [uploadError, setUploadError] = useState("");
  const [loader, setLoader] = React.useState(false);
  const [rowData, setRowData] = useState([]);

  const fetchData = async () => {
    setLoader(true);
    const res = await listDoctorMembershipPlans();
    setRowData(res);
    setLoader(false);
  };
  React.useEffect(() => {
    fetchData();
  }, []);

  const addDoctorMembershipPlanAfterUploadPolicy = async (name) => {
    const res = await addDoctorMembershipPlan({
      ...formValues,
      uploadedFile: name,
      status: "Active",
    });
    console.log(res);
    if (res) {
      customChange(initialValues);
      setUploadFile(undefined);
      document.getElementById("uploadedFile").value = "";
      await fetchData();
    }
  };

  const onSubmitUploadPolicy = async () => {
    if (typeof uploadFile === "undefined") {
      setUploadError("Upload File is required");
      return false;
    } else {
      setUploadError("");
      const res = await getUploadPolicyLink({
        fileName: uploadFile.name,
      });
      if (res !== null) {
        const _res = await axios.put(res.url, uploadFile, {
          headers: {
            "Content-Type": "application/pdf",
          },
        });
        return _res.status >= 200 && _res.status < 300 ? res.name : false;
      } else {
        return false;
      }
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let errObj = {};
    let touchValues = {};
    Object.keys(formValues).forEach(function (key) {
      touchValues[key] = true;
    });
    setTouched(touchValues);

    validationSchema
      .validate(formValues, { abortEarly: false })
      .then(async (valid) => {
        setLoader(true);
        try {
          console.dir(valid);
          setErrors(errObj);
          const policy_name = await onSubmitUploadPolicy();
          console.log(policy_name);
          if (policy_name) {
            console.log(true);
            await addDoctorMembershipPlanAfterUploadPolicy(policy_name);
          } else {
            snackbar({
              message: "Error while Uploading Fee Policy.",
              color: "danger",
            });
            console.log(false);
          }
        } catch (err) {
          console.log(err);
        }
        setLoader(false);
      })
      .catch((err) => {
        for (let i = 0; i < err.errors.length; i++) {
          let error = err.errors[i];
          let errorKey = error.split(" ")[0];
          errObj[errorKey] = error;
        }
        console.log(errObj);
        setErrors(errObj);
      });
  };

  const handleUploadPolicy = (e) => {
    setUploadFile((prevState) => e.target.files[0]);
    console.log(e);
  };

  return (
    <Fragment>
      <Loader show={loader} />
      <Helmet>
        <title>Membership | DOCG</title>
      </Helmet>
      <div className="mbsc-col-12 mbsc-col-sm-12 mbsc-col-md-12 mbsc-col-lg-12">
        <Card className="docg-patient-form-wrapper docg-card">
          {/* <Card.Title className="justify-content-start">
            <img className="mr-2" src={Membership} alt="" />
            <span className="medical-record-title m-0">Membership</span>

          </Card.Title> */}
          <Card.Header
            className="d-flex justify-content-between customCardHeader"
            style={{ backgroundColor: "#dee2e6" }}
          >
            <Card.Title className="customCardTitle">
              <img className="mr-2" src={Membership} alt="" />
              Membership
            </Card.Title>
          </Card.Header>
          <Card.Body>
            <form className="pr-3 pl-3" onSubmit={handleSubmit} action="post">
              <div className="mbsc-grid">
                <div className="mbsc-row">
                  <div className="mbsc-col-6">
                    <Input
                      name="name"
                      labelStyle="floating"
                      inputStyle="underline"
                      type="text"
                      placeholder="Plan Type"
                      label="Plan Type"
                      value={formValues.name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.name ? errors.name : false}
                      errorMessage={touched.name ? errors.name : ""}
                    />
                  </div>
                  <div className="mbsc-col-6">
                    <Input
                      name="description"
                      labelStyle="floating"
                      inputStyle="underline"
                      type="text"
                      placeholder="Plan Description"
                      label="Plan Description"
                      value={formValues.description}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.description ? errors.description : false}
                      errorMessage={
                        touched.description ? errors.description : ""
                      }
                    />
                  </div>
                </div>

                <div className="mbsc-row">
                  <div className="mbsc-col-6">
                    <mobiscroll.Number
                      theme="ios"
                      themeVariant="light"
                      touchUi
                      display="bubble"
                      layout="fixed"
                      units={["Monthly", "Annually"]}
                      step={1}
                      min={1}
                      width={150}
                      label="Set Price"
                      labelStyle="floating"
                      value={`${formValues.price} ${formValues.paymentFrequency}`}
                      onChange={(e) => {
                        const split = e.valueText.split(" ");
                        customChange({
                          price: +split[0],
                          paymentFrequency: split[1],
                        });
                      }}
                      onBlur={(e) => {
                        customValidate({ paymentFrequency: true });
                      }}
                      error={
                        touched.paymentFrequency
                          ? errors.paymentFrequency
                          : false
                      }
                      errorMessage={
                        touched.paymentFrequency ? errors.paymentFrequency : ""
                      }
                    >
                      <Input
                        inputStyle="underline"
                        labelStyle="floating"
                        label="Set Price"
                      />
                    </mobiscroll.Number>
                  </div>
                  <div className="mbsc-col-6">
                    <mobiscroll.Number
                      theme="ios"
                      themeVariant="light"
                      touchUi
                      display="bubble"
                      layout="fixed"
                      units={["Month", "Year"]}
                      step={1}
                      min={1}
                      width={150}
                      label="Validity"
                      labelStyle="floating"
                      value={`${formValues.validityTime} ${formValues.validityTimeUnit}`}
                      onChange={(e) => {
                        const split = e.valueText.split(" ");
                        customChange({
                          validityTime: +split[0],
                          validityTimeUnit: split[1],
                        });
                      }}
                      onBlur={(e) => {
                        customValidate({ validityTimeUnit: true });
                      }}
                      error={
                        touched.validityTimeUnit
                          ? errors.validityTimeUnit
                          : false
                      }
                      errorMessage={
                        touched.validityTimeUnit ? errors.validityTimeUnit : ""
                      }
                    >
                      <Input
                        inputStyle="underline"
                        labelStyle="floating"
                        label="Validity*"
                      />
                    </mobiscroll.Number>
                  </div>
                </div>
                <div className="mbsc-row">
                  <div className="mbsc-col-6">
                    <Input
                      labelStyle="floating"
                      inputStyle="underline"
                      label="No of Visits*"
                      type="number"
                      name="numberOfVisits"
                      value={formValues.numberOfVisits}
                      onChange={(e) =>
                        customChange({ numberOfVisits: +e.target.value })
                      }
                      onBlur={handleBlur}
                      error={
                        touched.numberOfVisits ? errors.numberOfVisits : false
                      }
                      errorMessage={
                        touched.numberOfVisits ? errors.numberOfVisits : ""
                      }
                    />
                  </div>
                  <div className="mbsc-col-6">
                    <Input
                      labelStyle="floating"
                      inputStyle="underline"
                      label="No of Members*"
                      type="number"
                      name="numberOfMembers"
                      value={formValues.numberOfMembers}
                      onChange={(e) =>
                        customChange({ numberOfMembers: +e.target.value })
                      }
                      onBlur={handleBlur}
                      error={
                        touched.numberOfMembers ? errors.numberOfMembers : false
                      }
                      errorMessage={
                        touched.numberOfMembers ? errors.numberOfMembers : ""
                      }
                    />
                  </div>
                </div>
                <div className="mbsc-row">
                  <div className="mbsc-col-6">
                    <Input
                      type="file"
                      name="uploadedFile"
                      id="uploadedFile"
                      label="Upload File*"
                      labelStyle="floating"
                      onChange={handleUploadPolicy}
                      file={uploadFile}
                      error={true}
                      errorMessage={uploadError}
                      inputStyle="underline"
                    />
                  </div>
                </div>
                <div className="d-flex justify-content-end">
                  <button type="submit" className="docg-save-btn">
                    <img src={saveIcon} alt="" />
                    Save
                  </button>
                </div>
              </div>
            </form>
          </Card.Body>
        </Card>
      </div>

      <div className="mbsc-col-12 mt-4">
        <DataListingCard rowData={rowData} />
      </div>
    </Fragment>
  );
}

export default Index;
