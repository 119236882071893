import React from "react";
import Avatar from "react-avatar";
import { Link } from "react-router-dom";

const PatientNameCellRenderer = (params) => {
  return (
    <>
      <Link
        className="docg-app-patient-name w-100"
        to={`/patient-record/${params.data.id}`}
      >
        <Avatar
          color="#c06c84"
          round
          size="35"
          name={`${params.data?.firstName} ${params.data.lastName}`}
          src={params.data.profilePicture}
        />
        <span className="ml-2">
          {params.data.firstName !== undefined
            ? `${params.data.firstName} ${params.data.lastName}`
            : ""}
        </span>
      </Link>
    </>
  );
};

export default PatientNameCellRenderer;
