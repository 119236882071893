import { gql } from "@apollo/client";

export const subscribeListTimeslotBeep = /* GraphQL */ gql`
  subscription subscribeListTimeslotBeep($doctorId: String!) {
    subscribeListTimeslotBeep(doctorId: $doctorId) {
      statusCode
      doctorId
    }
  }
`;
export const subscribeListAppointmentClinicBeep = /* GraphQL */ gql`
  subscription subscribeListAppointmentClinicBeep($clinicId: String!) {
    subscribeListAppointmentClinicBeep(clinicId: $clinicId) {
      clinicId
      statusCode
    }
  }
`;

export const subscribeNotificationBeep = /* GraphQL */ gql`
  subscription subscribeNotificationBeep($identityId: String!) {
    subscribeNotificationBeep(identityId: $identityId) {
      identityId
      statusCode
    }
  }
`;

export const RAZORPAY_SUBSCRIPTION_BEEP = /* GraphQL */ gql`
  subscription MySubscription($clinicId: String!) {
    subscribeRazorpaySubscriptionsBeep(clinicId: $clinicId) {
      clinicId
      statusCode
    }
  }
`;
