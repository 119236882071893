import {
  ApolloClient,
  ApolloLink,
  InMemoryCache,
  createHttpLink,
} from "@apollo/client";
import { fetchAuthSession } from "aws-amplify/auth";
import { createAuthLink } from "aws-appsync-auth-link";
import { createSubscriptionHandshakeLink } from "aws-appsync-subscription-link";
const url = process.env.REACT_APP_AWS_APPSYNC_GRAPHQL_ENDPOINT;
const region = process.env.REACT_APP_REGION;
const auth = {
  type: process.env.REACT_APP_AWS_APPSYNC_AUTH_TYPE,
  jwtToken: async () =>
    (
      await fetchAuthSession({ forceRefresh: true })
    ).tokens?.idToken?.toString(),
};

const link = ApolloLink.from([
  // @ts-ignore
  createAuthLink({ url, region, auth }),
  // @ts-ignore
  createHttpLink({ uri: url }),
]);

const httpLink = createHttpLink({ uri: url });

const _link = ApolloLink.from([
  createAuthLink({ url, region, auth }),
  createSubscriptionHandshakeLink({ url, region, auth }, httpLink),
]);

const defaultOptions = {
  watchQuery: {
    fetchPolicy: "no-cache",
    errorPolicy: "ignore",
  },
  query: {
    fetchPolicy: "no-cache",
    errorPolicy: "all",
  },
};

const client = new ApolloClient({
  link,
  cache: new InMemoryCache({
    addTypename: false,
  }),
  defaultOptions: defaultOptions,
});
const subscriptionClient = new ApolloClient({
  link: _link,
  cache: new InMemoryCache({
    addTypename: false,
  }),
  defaultOptions: defaultOptions,
});
export async function getQuery(query, data, flag = false) {
  try {
    const res = await client.query({
      query: query,
      variables: flag ? { ...data } : { data },
    });
    return res;
  } catch (e) {
    console.log("ERROR AT QUERY " + e);
  }
}

export async function postMutation(query, data, flag = false) {
  console.log(data);
  try {
    const response = await client.mutate({
      variables: flag ? { ...data } : { data },
      mutation: query,
    });
    return response;
  } catch (e) {
    console.log("ERROR AT MUTATION " + e);
  }
}

export async function getQueryWithParams(query, data) {
  console.log(query, data);
  try {
    const response = await client.query({
      variables: { data },
      query: query,
    });
    return response;
  } catch (e) {
    console.log("ERROR AT QUERY " + e);
  }
}
export async function getQueryResponse(query, data) {
  console.log(query, data);
  try {
    const response = await client.query({
      variables: { ...data },
      query: query,
    });
    return response;
  } catch (e) {
    console.log("ERROR AT QUERY " + e);
  }
}
export default client;

export async function subscription(query, variables = {}, next, error) {
  try {
    const response = subscriptionClient
      .subscribe({ query: query, variables })
      .subscribe({
        next,
        error,
        complete() {
          console.log("subs. DONE");
        },
      });

    return response;
    // return setTimeout(response.unsubscribe(),1000);
  } catch (e) {
    console.error("Error in subscription", e);
  }
}
