import { snackbar } from "@mobiscroll/react";
import { Input, Popup, Select } from "@mobiscroll/react5";
import { useFormik } from "formik";
import { useState } from "react";
import { useQuery } from "react-query";
import signup_icon from "src/assets/Signup icon  human.svg";
import grid from "src/assets/grid.svg";
import doctor from "src/assets/login page doctors 02.svg";
import popup_doctor from "src/assets/login page doctors 03.svg";
import signin_circle from "src/assets/signin-circle.svg";
import text_box from "src/assets/text box.svg";
import { getQuery as getQueryUnAuth } from "src/components/ApolloConnection/UnauthorizedConnection/ApolloProviderSignup";
import Loader from "src/components/ClinicDashboard/common/Loader";
import {
  CHECK_CLINIC_STATUS,
  GET_FILE_FOR_DOCG,
} from "src/components/graphql/GraphQLquery";
import { useStore } from "../store/Store";
import validationSchema from "./step1ValidationSchema";

const initialValues = {
  title: "",
  firstName: "",
  lastName: "",
  phoneNumber: "",
  email: "",
  clinicName: "",
  clinicAddress: "",
  registrationNumber: "",
  pinCode: "",
  district: "",
  state: "",
  paymentGatewayType: "Razorpay",
  termsAndConditionAccepted: false,
};
const initialTouched = {
  title: false,
  firstName: false,
  lastName: false,
  phoneNumber: false,
  email: false,
  clinicName: false,
  clinicAddress: false,
  registrationNumber: false,
  pinCode: false,
  district: false,
  state: false,
  termsAndConditionAccepted: false,
};

const Step1 = ({ setCurrentStep }) => {
  const [popupDisplay, setPopupDisplay] = useState(false);
  const [loader, setLoader] = useState(false);
  const { globalState, dispatch } = useStore();
  const tnc_file = process.env.REACT_APP_TNC;

  const onSubmit = async (values) => {
    setLoader(true);
    let res = await getQueryUnAuth(CHECK_CLINIC_STATUS, {
      email: values.email,
    });
    if (res.data.checkClinicExist.statusCode == 200) {
      snackbar({ message: res.data.checkClinicExist.message, color: "danger" });
    } else {
      dispatch({
        type: "CUSTOM",
        payload: { ...values, phoneNumber: `+91${values.phoneNumber}` },
      });
      setCurrentStep(1);
    }
    setLoader(false);
  };

  const closePopup = () => {
    setPopupDisplay(false);
  };

  const {
    values,
    touched,
    errors,
    handleSubmit,
    handleChange,
    handleBlur,
    setFieldValue,
  } = useFormik({
    initialValues,
    initialTouched,
    onSubmit,
    validationSchema,
  });

  const findAndSetStateCity = async (pincode) => {
    try {
      let data = await fetch(`https://api.postalpincode.in/pincode/${pincode}`);
      data = await data.json();
      data = data[0];
      if (data.Status === "Success") {
        let city = data.PostOffice[0].District;
        let state = data.PostOffice[0].State;
        setFieldValue("district", city, true);
        setFieldValue("state", state, true);
      } else {
        snackbar({
          message: `${data.Message} For this Postal Code ${pincode}`,
          color: "danger",
        });
      }
    } catch (err) {}
  };

  const home = () => {
    window.location = "/";
  };

  const { data } = useQuery(["get docg file"], async function ({ queryKey }) {
    try {
      const res = await getQueryUnAuth(GET_FILE_FOR_DOCG, {
        fileType: "TermsAndConditionsForClinic",
      });
      return res?.data?.getFileForDocg?.data?.url ?? null;
    } catch (err) {
      console.log(err);
      return null;
    }
  });

  return (
    <>
      <Loader show={loader} />
      <div className="mbsc-grid">
        <div className="mbsc-card main-card-wrapper">
          <div className="signup-wrapper">
            <div className="mbsc-row">
              <div className="mbsc-col-xl-4 doctor-images-left mbsc-col-12">
                <div className="left-signup-div" id="reset-password-page">
                  {/* <div className="left-wrapper-image-section"></div> */}
                </div>
                <img
                  src={doctor}
                  className="left-signup-img left-steps-img"
                  alt=""
                />
              </div>
              <div className="mbsc-col-xl-8 mbsc-col-12 signup-right">
                <img src={grid} alt="" className="grid-top" />
                <img src={signin_circle} alt="" className="signin-circle" />
                <div className="bottom-circle" />
                <div className="top-circle" />
                <div className="right-signup-div">
                  <div className="get-started-wrapper">
                    <p className="signup-link">Step 1/4</p>
                    <br />
                    <form action="" method="post" onSubmit={handleSubmit}>
                      <div className="signup-wrapper-inner">
                        <div style={{ gap: "12px 0" }} className="mbsc-row">
                          <div className="mbsc-col-4 mbsc-col-md-2">
                            <Select
                              data={[
                                { text: "Dr.", value: "Dr." },
                                { text: "Mr.", value: "Mr." },
                                { text: "Ms.", value: "Ms." },
                                { text: "Mrs.", value: "Mrs." },
                              ]}
                              label="Title*"
                              inputProps={{
                                inputStyle: "box",
                                labelStyle: "floating",
                              }}
                              touchUi={false}
                              value={values.title}
                              onBlur={handleBlur}
                              onChange={(e) => {
                                setFieldValue("title", e.value, true);
                              }}
                              error={touched.title && errors.title}
                              errorMessage={touched.title ? errors.title : ""}
                            />
                          </div>
                          <div className="mbsc-col-8 mbsc-col-md-5">
                            <Input
                              inputStyle="underline"
                              labelStyle="floating"
                              label="First Name*"
                              type="text"
                              name="firstName"
                              onChange={(e) => {
                                let value = e.target.value;
                                value =
                                  value.substring(0, 1).toUpperCase() +
                                  value.substring(1);
                                setFieldValue("firstName", value, true);
                              }}
                              value={values.firstName}
                              onBlur={handleBlur}
                              error={
                                touched.firstName && errors.firstName
                                  ? true
                                  : false
                              }
                              errorMessage={
                                touched.firstName ? errors.firstName : ""
                              }
                            />
                          </div>
                          <div className=" mbsc-col-12 mbsc-col-md-5">
                            <Input
                              inputStyle="underline"
                              labelStyle="floating"
                              label="Last Name*"
                              type="text"
                              name="lastName"
                              onChange={(e) => {
                                let value = e.target.value;
                                value =
                                  value.substring(0, 1).toUpperCase() +
                                  value.substring(1);
                                setFieldValue("lastName", value, true);
                              }}
                              value={values.lastName}
                              onBlur={handleBlur}
                              error={touched.lastName}
                              errorMessage={
                                touched.lastName ? errors.lastName : ""
                              }
                            />
                          </div>
                          <div className="mbsc-col-12 mbsc-col-md-6">
                            <Input
                              inputStyle="underline"
                              labelStyle="floating"
                              label="Mobile Number*"
                              type="text"
                              name="phoneNumber"
                              onChange={handleChange}
                              value={values.phoneNumber}
                              onBlur={handleBlur}
                              error={touched.phoneNumber}
                              errorMessage={
                                touched.phoneNumber ? errors.phoneNumber : ""
                              }
                            />
                          </div>
                          <div className="mbsc-col-12 mbsc-col-md-6">
                            <Input
                              inputStyle="underline"
                              labelStyle="floating"
                              label="Email*"
                              type="text"
                              name="email"
                              onChange={handleChange}
                              value={values.email}
                              onBlur={handleBlur}
                              error={touched.email}
                              errorMessage={touched.email ? errors.email : ""}
                            />
                          </div>
                          <div className="mbsc-col-12 mbsc-col-md-12">
                            <Input
                              inputStyle="underline"
                              labelStyle="floating"
                              label="Clinic Name*"
                              type="text"
                              name="clinicName"
                              onChange={handleChange}
                              value={values.clinicName}
                              onBlur={handleBlur}
                              error={touched.clinicName}
                              errorMessage={
                                touched.clinicName ? errors.clinicName : ""
                              }
                            />
                          </div>
                          <div className="mbsc-col-12 mbsc-col-md-12">
                            {" "}
                            <Input
                              inputStyle="underline"
                              labelStyle="floating"
                              label="Clinic Address*"
                              type="text"
                              name="clinicAddress"
                              onChange={handleChange}
                              value={values.clinicAddress}
                              onBlur={handleBlur}
                              error={touched.clinicAddress}
                              errorMessage={
                                touched.clinicAddress
                                  ? errors.clinicAddress
                                  : ""
                              }
                            />
                          </div>
                          <div className="mbsc-col-12 mbsc-col-md-4">
                            {" "}
                            <Input
                              inputStyle="underline"
                              labelStyle="floating"
                              label="Pin Code*"
                              type="number"
                              name="pinCode"
                              onChange={(e) => {
                                setFieldValue("pinCode", +e.target.value, true);
                                if (e.target.value.length >= 6)
                                  findAndSetStateCity(+e.target.value);
                              }}
                              value={values.pinCode}
                              onBlur={handleBlur}
                              error={touched.pinCode}
                              errorMessage={
                                touched.pinCode ? errors.pinCode : ""
                              }
                            />
                          </div>
                          <div className="mbsc-col-12 mbsc-col-md-4">
                            {" "}
                            <Input
                              inputStyle="underline"
                              labelStyle="floating"
                              label="City*"
                              type="text"
                              name="district"
                              value={values.district}
                              onBlur={handleBlur}
                              error={touched.district}
                              errorMessage={
                                touched.district ? errors.district : ""
                              }
                              onChange={(e) => {
                                setFieldValue("district", e.target.value, true);
                              }}
                            />
                          </div>
                          <div className="mbsc-col-12 mbsc-col-md-4">
                            {" "}
                            <Input
                              inputStyle="underline"
                              labelStyle="floating"
                              label="State*"
                              type="text"
                              name="state"
                              onChange={(e) => {
                                setFieldValue("state", e.target.value, true);
                              }}
                              value={values.state}
                              onBlur={handleBlur}
                              error={touched.state}
                              errorMessage={touched.state ? errors.state : ""}
                            />
                          </div>
                          <div className="mbsc-col-12 mbsc-col-md-12">
                            {" "}
                            <Input
                              inputStyle="underline"
                              labelStyle="floating"
                              label="Medical Registration Number*"
                              name="registrationNumber"
                              onChange={handleChange}
                              value={values.registrationNumber}
                              onBlur={handleBlur}
                              error={touched.registrationNumber}
                              errorMessage={
                                touched.registrationNumber
                                  ? errors.registrationNumber
                                  : ""
                              }
                            />
                          </div>
                        </div>
                        <div className="mbsc-row d-flex align-items-center justify-content-center mt-3">
                          <input
                            style={{ width: "auto" }}
                            type="checkbox"
                            id="confirmation-checkbox"
                            onChange={(e) =>
                              setFieldValue(
                                "termsAndConditionAccepted",
                                e.target.checked,
                                true
                              )
                            }
                            value={values.termsAndConditionAccepted}
                            onBlur={handleBlur}
                          ></input>
                          <label
                            for="confirmation-checkbox"
                            className="checbox-label"
                            style={{ margin: "0" }}
                          >
                            &nbsp;{" "}
                            <a
                              style={{ color: "inherit" }}
                              href={tnc_file}
                              target="_blank"
                              rel="noreferrer"
                            >
                              I agree to both DocG's Terms of Use and Privacy
                              Policy
                            </a>
                          </label>
                        </div>
                        <div className="mbsc-error-message mbsc-ios">
                          {touched.termsAndConditionAccepted
                            ? errors.termsAndConditionAccepted
                            : ""}
                        </div>
                        <button className="purple-button mt-4" type="submit">
                          <img
                            src={signup_icon}
                            style={{ marginLeft: "8%" }}
                            alt=""
                          />
                          &nbsp;&nbsp; Sign up
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Popup
        isOpen={popupDisplay}
        display="center"
        fullScreen={false}
        height={"65vh"}
        onClose={closePopup}
      >
        <div className="mbsc-grid-fixed verification-popup">
          <div className="popup-card-header">Verification Popup</div>
          <div className="popup-card-body">
            <div className="mbsc-grid">
              <div className="mbsc-row">
                <div className="mbsc-col-md-7 mbsc-col-lg-7 mbsc-col-12">
                  <div
                    className="popup-card-text"
                    style={{
                      backgroundImage: `url("${text_box}")`,
                      backgroundSize: "cover",
                      backgroundRepeat: "no-repeat",
                    }}
                  >
                    <p>
                      Thanks for applying we are verifying your details and
                      Medicare team will send a verification link to your
                      registered emil address to start your free trial & setup
                      login
                    </p>
                  </div>
                  <button className="back_btn" onClick={home}>
                    <span className="mbsc-ic mbsc-ic-arrow-left2"></span> Home
                  </button>
                </div>
                <div className="mbsc-col-md-5 mbsc-col-lg-5 mbsc-col-12">
                  <img src={popup_doctor} alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Popup>
    </>
  );
};

export default Step1;
