import { Switch } from "@mobiscroll/react";
import { SegmentedGroup, SegmentedItem } from "@mobiscroll/react5";
import { AgGridColumn, AgGridReact } from "ag-grid-react";
import _debounce from "lodash/debounce";
import React, { Fragment, useCallback, useState } from "react";
import Avatar from "react-avatar";
import { Button, Card, Image } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import AllPatient from "src/assets/icons/All Patient Record.svg";
import { setLoading } from "src/store/GlobalReducer";
import { useStore } from "src/store/Store";
import ActionCellRender from "../Layouts/ActionCellRender";
import PatientNameCellRenderer from "./PatientNameCellRenderer";
import DoctorNameCellRenderer from "./doctorNameRender";

//api
import { getDoctors } from "src/components/api/index";
import { listClinicPatients } from "./api/index";
import { isMobileOnly } from "react-device-detect";

const { Header: CardHeader, Body: CardBody, Title: CardTitle } = Card;

const options = {
  year: "numeric",
  month: "short",
  day: "numeric",
};

function PatientList() {
  const { globalState, dispatch } = useStore();
  const [displayRecords, setDisplayRecords] = useState("10");
  const [totalRecords, setTotalRecords] = useState(0);
  const [selectedDoctors, setSelectedDoctors] = useState([]);
  const [docdata, setdocdata] = useState([]);
  const history = useHistory();
  const [rowData, setRowData] = useState([]);
  const [inputSearch, setInputSearch] = useState("");
  const [flag, setFlag] = useState(true);

  React.useEffect(async () => {
    let res = JSON.parse(sessionStorage.getItem("doctors-list"));
    if (!res) {
      res = await getDoctors();
    }
    res = res.map((item) => {
      return { ...item, checked: true };
    });
    setFlag(true);
    console.log(res);
    setRowData(res);
  }, []);

  // eslint-disable-next-line
  const [gridApi, setGridApi] = useState(null);
  // eslint-disable-next-line
  const [gridColumnApi, setGridColumnApi] = useState(null);

  const onGridReady = (params) => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
  };

  const routeChange = () => {
    history.push("add-patient");
  };

  const defaultColDef = {
    sortable: true,
    resizable: false,
    filter: true,
    flex: 1,
    minWidth: 50,
  };
  // if we had column groups, we could provide default group items here
  const defaultColGroupDef = {};
  const handleChange = (e) => {
    let res = rowData;
    res = res.map((doctor) => {
      return doctor.id === e.target.name
        ? { ...doctor, checked: e.target.checked }
        : doctor;
    });
    setFlag(true);
    setRowData(res);
  };

  const fetchPatients = async (displayRecords, search) => {
    dispatch(setLoading(true));
    try {
      let doctorIds = [];
      let response;
      rowData.forEach((doctor) => {
        doctor.checked && doctorIds.push(doctor.id);
      });
      console.log(doctorIds);
      if (doctorIds.length > 0) {
        response = await listClinicPatients(
          Number(displayRecords),
          search,
          doctorIds
        );
      } else {
        response = { data: [] };
      }
      console.log(displayRecords, rowData, doctorIds, search, response);
      let value =
        response?.data.length < 10
          ? `0${response?.data.length}`
          : response.data.length;
      setDisplayRecords(value);
      setdocdata(response.data);
      setFlag(false);
      setTotalRecords(response.totalCount);
    } catch (error) {
      console.log(error);
    }
    dispatch(setLoading(false));
  };
  const debounceFn = useCallback(_debounce(fetchPatients, 1000), [
    rowData,
    listClinicPatients,
  ]);

  const handleSearch = (e) => {
    console.log(e);
    setInputSearch(e.target.value);
    debounceFn(10, e.target.value);
  };

  React.useEffect(async () => {
    console.log(flag, rowData);
    await fetchPatients(displayRecords, inputSearch);
  }, [rowData, inputSearch]);

  const onPageSizeChanged = async (e) => {
    if (e.valueText != displayRecords) {
      listClinicPatients(e.valueText, inputSearch);
    }
    setDisplayRecords(e.valueText);
  };

  function StatusCellRenderer(params) {
    const width = window.innerWidth;
    return (
      <div className="w-100 d-flex justify-content-center align-items-center">
        <Switch
          className="docg-table-switch"
          checked={params.value}
          theme="auto"
          position="start"
          onChange={(ev) => console.log(ev)}
          color="success"
          name="noti2"
          id={`patient-${params.rowIndex}`}
        />
      </div>
    );
  }

  return (
    <Fragment>
      <div className="mbsc-col-12">
        <Card className="docg-card docg-appointments docg-vh-standard">
          <CardHeader
            className="d-flex justify-content-between customCardHeader"
            style={{ backgroundColor: "#dee2e6" }}
          >
            <CardTitle
              style={{ minWidth: "fit-content" }}
              className="customCardTitle d-flex"
            >
              <Image src={AllPatient} alt="" className="mr-2" /> Patients
            </CardTitle>
            <SegmentedGroup
              className="doctor-list d-flex mr-3 ml-3"
              select="multiple"
              style={{ padding: "0 2px" }}
              onChange={handleChange}
            >
              {rowData.map((doctor) => {
                return (
                  <SegmentedItem
                    className="doctor-detail"
                    name={doctor.id}
                    id={doctor.id}
                    value={JSON.stringify(doctor)}
                    checked={doctor?.checked}
                    style={{ padding: "0", margin: "0" }}
                  >
                    <Avatar
                      color="#c06c84"
                      round
                      size="32"
                      name={`${doctor?.firstName} ${doctor?.lastName}`}
                      src={doctor.profilePicture}
                    />
                    <span className="md-header-filter-name md-header-filter-name-1">
                      {doctor.firstName} {doctor.lastName}
                    </span>
                  </SegmentedItem>
                );
              })}
            </SegmentedGroup>
            {!isMobileOnly && (
              <div className="d-flex justify-content-center align-items-center">
                <div className="docg-table-search">
                  <input
                    type="text"
                    onInput={handleSearch}
                    id="quickFilter"
                    placeholder="Search..."
                  />
                </div>
                <Button className="theme-button btn-xs " onClick={routeChange}>
                  <span className="material-icons pr-1">
                    add_circle_outline
                  </span>{" "}
                  Add
                </Button>
              </div>
            )}
          </CardHeader>
          <CardBody className="docg-card-body docg-h-standard pb-4">
            {/* <div className="records-display">
              <span>Showing&nbsp;</span>
              <label className="record-selection-dropdown" htmlFor="">
                <mobiscroll.Select
                  touchUi={false}
                  onChange={(e) => {
                    onPageSizeChanged(e);
                  }}
                  value={displayRecords}
                >
                  {Array.from(Array(totalRecords).keys()).map((index) => {
                    let value = index + 1 < 10 ? `0${index + 1}` : index + 1;
                    return (
                      <option key={value} value={value}>
                        {value}
                      </option>
                    );
                  })}
                </mobiscroll.Select>
                <i class="fas fa-chevron-down"></i>
              </label>
              <span>&nbsp;Records</span>
            </div> */}
            <div
              className="ag-theme-alpine"
              style={{
                height: "100%",
                width: "100%",
              }}
            >
              <AgGridReact
                rowData={docdata}
                defaultColDef={defaultColDef}
                frameworkComponents={{
                  actionCellRender: ActionCellRender,
                  statusCellRenderer: StatusCellRenderer,
                  patientNameCellRenderer: PatientNameCellRenderer,
                  doctorNameCellRenderer: DoctorNameCellRenderer,
                }}
                defaultColGroupDef={defaultColGroupDef}
                enableRangeSelection={true}
                pagination={true}
                onGridReady={onGridReady}
                paginationPageSize={displayRecords}
                domLayout={"autoHeight"}
                animateRows={true}
                enableBrowserTooltips={true}
              >
                <AgGridColumn
                  headerName="Doctor Name"
                  headerTooltip="Doctor Name"
                  field="doctor"
                  cellRenderer="doctorNameCellRenderer"
                  minWidth={200}
                  valueGetter={(params) => {
                    return `${params.data.doctor.firstName} ${params.data.doctor.lastName}`;
                  }}
                  tooltipValueGetter={(params) => {
                    return `${params.data.doctor.firstName} ${params.data.doctor.lastName}`;
                  }}
                ></AgGridColumn>
                <AgGridColumn
                  headerName="Patient Name"
                  headerTooltip="Patient Name"
                  cellRenderer="patientNameCellRenderer"
                  valueGetter={(params) => {
                    return `${params.data.firstName} ${params.data.lastName}`;
                  }}
                  tooltipValueGetter={(params) => {
                    return `${params.data.firstName} ${params.data.lastName}`;
                  }}
                  minWidth={200}
                ></AgGridColumn>
                <AgGridColumn
                  headerName="Reffered By"
                  headerTooltip="Reffered By"
                  field="referredDoctor"
                  valueGetter={(params) => {
                    if (params.data.referredDoctor) {
                      return `${params.data.referredDoctor.firstName} ${params.data.referredDoctor.lastName}`;
                    }
                    return "-";
                  }}
                  tooltipValueGetter={(params) => {
                    if (params.data.referredDoctor) {
                      return `${params.data.referredDoctor.firstName} ${params.data.referredDoctor.lastName}`;
                    }
                    return "-";
                  }}
                ></AgGridColumn>
                <AgGridColumn
                  headerName="Daignose"
                  headerTooltip="Daignose"
                  field="diagnosis"
                  valueGetter={(params) => {
                    if (params.data.diagnosis) {
                      return `${params.data.diagnosis}`;
                    }
                    return "-";
                  }}
                  tooltipValueGetter={(params) => {
                    if (params.data.diagnosis) {
                      return `${params.data.diagnosis}`;
                    }
                    return "-";
                  }}
                ></AgGridColumn>
                <AgGridColumn
                  headerName="Last Visit"
                  headerTooltip="Last Visit"
                  field="lastVisit"
                  valueFormatter={(params) => {
                    if (params.value)
                      return new Date(params.value).toLocaleDateString(
                        "en-US",
                        options
                      );
                    return "-";
                  }}
                  tooltipValueGetter={(params) => {
                    if (params.value)
                      return new Date(params.value).toLocaleDateString(
                        "en-US",
                        options
                      );
                    return "-";
                  }}
                ></AgGridColumn>
                <AgGridColumn
                  headerName="Follow Up"
                  headerTooltip="Follow Up"
                  field="followUp"
                  valueFormatter={(params) => {
                    if (params.value)
                      return new Date(params.value).toLocaleDateString(
                        "en-US",
                        options
                      );
                    return "-";
                  }}
                  tooltipValueGetter={(params) => {
                    if (params.value)
                      return new Date(params.value).toLocaleDateString(
                        "en-US",
                        options
                      );
                    return "-";
                  }}
                ></AgGridColumn>
                <AgGridColumn
                  headerName="Active"
                  field="isActive"
                  cellRenderer="statusCellRenderer"
                ></AgGridColumn>
              </AgGridReact>
            </div>
          </CardBody>
        </Card>
      </div>
    </Fragment>
  );
}

export default PatientList;
