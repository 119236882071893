import {
  ApolloClient,
  ApolloLink,
  InMemoryCache,
  createHttpLink,
} from "@apollo/client";
import { createAuthLink } from "aws-appsync-auth-link";
import AWS from "aws-sdk";

AWS.config.region = "ap-south-1";
let accessKeyId;
let secretAccessKey;
let sessionToken;

// Configure the credentials provider to use your identity pool
AWS.config.credentials = new AWS.CognitoIdentityCredentials({
  IdentityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID,
  RoleArn: process.env.REACT_APP_ROLE_ARN,
});

// Make the call to obtain credentials
AWS.config.credentials.get(Credentials_unauthorized());

async function Credentials_unauthorized() {
  accessKeyId = AWS.config.credentials.accessKeyId;
  secretAccessKey = AWS.config.credentials.secretAccessKey;
  sessionToken = AWS.config.credentials.sessionToken;
}

const url = process.env.REACT_APP_AWS_APPSYNC_GRAPHQL_ENDPOINT;
const region = process.env.REACT_APP_REGION;
// const creds = new AWS.Credentials(accessKeyId, secre~tAccessKey, sessionToken)
const auth = {
  type: "AWS_IAM",
  credentials: () => AWS.config.credentials,
};
const link = ApolloLink.from([
  // @ts-ignore
  createAuthLink({ url, region, auth }),
  // @ts-ignore
  createHttpLink({ uri: url }),
]);
const client = new ApolloClient({
  link,
  cache: new InMemoryCache(),
});

export async function getQuery(query, data) {
  try {
    const res = await client.query({ query: query, variables: { ...data } });
    return res;
  } catch (e) {
    console.log("ERROR AT QUERY " + e);
  }
}

export async function postMutationUnAuth(query, data) {
  try {
    const response = await client.mutate({
      variables: { data },
      mutation: query,
    });
    console.log(response);
    return response;
  } catch (e) {
    console.log("ERROR AT MUTATION " + e);
  }
}

export default client;
