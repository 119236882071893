import { snackbar } from "@mobiscroll/react5";
import { useEffect, useState } from "react";
import {
  getQuery,
  postMutation,
} from "src/components/ApolloConnection/ApolloProviderComponent";
import { CREATE_INVOICE_PDF } from "src/components/graphql/GraphQLmutation";
import {
  GET_INVOICE_DATA,
  INVOICE_PDF_DOWNLOAD_DATA,
} from "src/components/graphql/GraphQLquery";

export default function useInvoicePdf(invoiceId) {
  const [invoiceData, setInvoiceData] = useState(null);
  const [invoiceBase64, setInvoiceBase64] = useState(null);
  const [isInvoiceLoading, setLoading] = useState(true);
  const [subTotal, setSubTotal] = useState(0);
  const [total, setTotal] = useState(0);
  const [tax, setTax] = useState(0);
  const pdfURL =
    "https://hnusdzf9sh.execute-api.ap-south-1.amazonaws.com/dev/pdf/invoice";

  const fetchPdfBase64 = async (data) => {
    try {
      const response = await postMutation(CREATE_INVOICE_PDF, data);
      if (response?.data?.createInvoicePdf?.base64) {
        setInvoiceBase64(
          `data:application/pdf;base64,${response?.data?.createInvoicePdf?.base64}`
        );
      } else {
        snackbar({
          message: "Unable to prepare invoice Pdf",
          color: "danger",
        });
      }
    } catch (error) {
      snackbar({
        message: "Unable to prepare invoice Pdf",
        color: "danger",
      });
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const fetchInvoiceData = async () => {
    if (invoiceId === null) {
      setLoading(false);
      return;
    }
    setLoading(true);
    const res = await getQuery(GET_INVOICE_DATA(invoiceId));
    console.log(res.data.getInvoice);
    if (res.data?.getInvoice?.data) {
      let itemData = res.data.getInvoice.data.items;
      let subTotal = 0;
      let tax = 0;
      let total = 0;
      let cgst = 0;
      let sgst = 0;
      itemData.forEach((item) => {
        subTotal += item.amount;
        tax += item.cgst + item.sgst;
        total =
          total +
          (subTotal +
            (subTotal * item.cgst) / 100 +
            (subTotal * item.sgst) / 100);
        cgst += item.cgst;
        sgst += item.sgst;
      });
      setSubTotal(subTotal);
      setTax(tax);
      setTotal(total);

      setInvoiceData(res.data.getInvoice.data);

      let clinicId = sessionStorage.getItem("clinic-id");

      const pdfRes = await getQuery(
        INVOICE_PDF_DOWNLOAD_DATA(
          res.data.getInvoice.data.appointmentId,
          res.data.getInvoice.data.doctorId,
          res.data.getInvoice.data.patientId,
          clinicId
        )
      );
      if (pdfRes.data !== null) {
        const temaplateData = {
          invoice: res.data.getInvoice.data,
          appointmentData: pdfRes.data.getAppointment.data,
          doctorData: pdfRes.data.getDoctor.data,
          clinicData: pdfRes.data.getClinicProfile.data,
          patientData: pdfRes.data.getPatient.data,
        };
        console.log(".........TEMPLATE DATA >>>>>>>>>>>", temaplateData);
        await fetchPdfBase64(temaplateData);
      }
    }

    setLoading(false);
  };

  const downloadInvoice = async () => {
    if (invoiceBase64) {
      const link = document.createElement("a");
      const linkSource = invoiceBase64;
      const file_name =
        "Invoice_" +
        invoiceData?.to +
        new Date(invoiceData.createdAt).toLocaleDateString([], {
          year: "numeric",
          month: "short",
          day: "numeric",
        }) +
        ".pdf";

      link.href = linkSource;
      link.download = file_name;
      link.click();
      link.remove();

      snackbar({
        message: "Invoice Downloaded Successfully",
        color: "success",
      });
    } else {
      snackbar({
        message: "Please Wait.. Loading..",
        color: "danger",
      });
    }
  };

  //   printPdf = async () => {
  //     const { invoiceBase64 } = this.state;
  //       if (invoiceBase64) {
  //         const storeFilePath =
  //           RNFetchBlob.fs.dirs.CacheDir +
  //           "/Invoice" +
  //           this.props.invoiceId.slice(0, 6).toUpperCase() +
  //           ".pdf";

  //         RNFetchBlob.fs
  //           .writeFile(storeFilePath, invoiceBase64, "base64")
  //           .then(async () => {
  //             await RNPrint.print({
  //               filePath: storeFilePath,
  //             });
  //           })
  //           .catch((err) => {
  //             console.log("Error in saving for Print", err);
  //           });
  //       }
  //   };

  return {
    downloadInvoice,
    isInvoiceLoading,
    invoiceData,
    subTotal,
    invoiceBase64,
    fetchInvoiceData,
    total,
    tax,
    invoiceFileName:
      sessionStorage.getItem("clinicName") +
      "_Invoice_" +
      invoiceData?.to +
      "_" +
      new Date(invoiceData?.createdAt).toLocaleDateString([], {
        year: "numeric",
        month: "short",
        day: "numeric",
      }) +
      ".pdf",
  };
}
