import * as Yup from "yup";

const createFeeSchema = Yup.object({
  cancelationIn24Hours: Yup.boolean().required(),
  cancellationAllowed: Yup.boolean().required(),
  cashPaymentAccepted: Yup.boolean().required(),
  fee: Yup.number().required(),
  followUpExpiry: Yup.number().required(),
  followUpExpiryUnits: Yup.string().required(),
  planName: Yup.string().required(),
  planType: Yup.string().required(),
  reschedulingAllowed: Yup.number().required(),
  reschedulingUnits: Yup.string().required(),
  allowedFreeFollowUpVisits: Yup.number().required(),
});

export default createFeeSchema;