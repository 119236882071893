import React from "react";

import { snackbar } from "@mobiscroll/react5";
import { getQuery } from "src/components/ApolloConnection/ApolloProviderComponent";
import { USER_DATA } from "src/components/graphql/GraphQLquery";
import fileToBase64 from "src/lib/fileToBase64";
import LeftPanel from "./LeftPanel";
import RightPanel from "./RightPanel";

import "./Profile.scss";

import Loader from "src/commons/Loader";
import { getMyProfile, updateProfile } from "./api";

const Profile = () => {
  const [data, setData] = React.useState(null);
  const [edit, setEdit] = React.useState(null);
  const [edited, setEdited] = React.useState(null);
  const [loader, setLoader] = React.useState(false);
  const [touched, setTouched] = React.useState({});

  const reloadData = React.useCallback(async () => {
    setLoader(true);
    let res = await getMyProfile();

    delete res?.__typename;
    delete res?.doctorProfile?.__typename;
    setData(res);
    setLoader(false);
  }, [getMyProfile]);

  React.useEffect(async () => {
    try {
      reloadData();
    } catch (err) {}
  }, []);

  const cancelOnClick = React.useCallback((e) => {
    e.preventDefault();
    setEdited(null);
    setEdit(false);
  }, []);

  const editOnClick = React.useCallback(
    (e) => {
      e.preventDefault();
      setEdited(data);
      setEdit(true);
    },
    [data]
  );

  const saveOnClick = React.useCallback(
    async (e) => {
      setLoader(true);
      e.preventDefault();
      let _data = {};
      Object.keys(edited).map((item) => {
        if (touched[item]) {
          _data[item] = edited[item];
        }
      });
      delete _data.id;
      console.log(_data);
      const res = await updateProfile(_data);
      delete res.__typename;
      setData(res);
      setEdited(null);
      setTouched({});
      setEdit(false);
      setLoader(false);
    },
    [data, edited]
  );

  function _renderAction() {
    return (
      <React.Fragment>
        <div className="form-statusbar">
          {edit ? (
            <React.Fragment>
              <div className="d-flex">
                <button className="docg-save-btn" onClick={saveOnClick}>
                  Save
                </button>
                <button
                  className="docg-save-btn bg-purple"
                  onClick={cancelOnClick}
                  style={{ marginLeft: "8px" }}
                >
                  Cancel
                </button>
              </div>
            </React.Fragment>
          ) : (
            <button className="docg-save-btn" onClick={editOnClick}>
              Edit
            </button>
          )}
        </div>
      </React.Fragment>
    );
  }
  const handleChange = (e) => {
    setEdited((prev) => {
      return { ...prev, [e.target.name]: e.target.value };
    });
    setTouched((prev) => {
      return { ...prev, [e.target.name]: true };
    });
  };

  const handleImageChange = React.useCallback(
    (keyword, e) => {
      setLoader(true);
      try {
        if (e.target.files[0]) {
          let file = e.target.files[0];
          fileToBase64(file)
            .then(async (result) => {
              const s = result.split(",");
              file["base64"] = result;
              const res = await updateProfile({
                [keyword]: result.split(",")[1],
              });
              delete res.__typename;
              delete res.doctorProfile?.__typename;
              setData(res);
              const doctorVerified = sessionStorage.getItem("doctorVerified");
              if (keyword == "signature" && doctorVerified == "false") {
                let response = await getQuery(USER_DATA);
                sessionStorage.setItem(
                  "doctorVerified",
                  response.data.getUserInfo.data.doctorVerified
                );
                if (response.data.getUserInfo.data.doctorVerified) {
                  snackbar({
                    message: "Doctor is verified Successfully.",
                    color: "success",
                  });
                } else {
                  snackbar({
                    message:
                      "Please add fee policy from Fee section for getting started.",
                    color: "success",
                  });
                }
              }
            })
            .catch((err) => {
              console.log(err);
            });
        }
      } catch (err) {
        console.log(err);
      }
      setLoader(false);
    },
    [data]
  );

  const handleClinicLogoHeaderChange = React.useCallback(
    async(e) => {
      console.log(e);
      setLoader(true);
      try {
        if (e?.target?.files[0]) {
          let file = e.target.files[0];
          fileToBase64(file).then(async (result) => {
            var extension = file.name.split(".").pop();
            file["base64"] = result;
            const res = await updateProfile({
              clinicLogoHeader: {
                fileValue: result.split(",")[1],
                extension: extension,
              },
            });
            delete res.__typename;
            delete res.doctorProfile?.__typename;
            setData(res);
          });
        }
        else{
          const res = await updateProfile({
            clinicLogoHeader: null,
          });
          delete res.__typename;
          delete res.doctorProfile?.__typename;
          setData(res);
        }
      } catch (err) {
        console.log(err);
      }
      setLoader(false);
    },
    [data]
  );
  const handleImageUpload = React.useCallback(
    async (keyword, file) => {
      setLoader(true);
      try {
        const res = await updateProfile({
          id: data.id,
          [keyword]: file.split(",")[1],
        });
        delete res.__typename;
        delete res.doctorProfile?.__typename;
        setData(res);
        const doctorVerified = sessionStorage.getItem("doctorVerified");
        if (keyword === "signature" && doctorVerified === "false") {
          let response = await getQuery(USER_DATA);
          sessionStorage.setItem(
            "doctorVerified",
            response.data.getUserInfo.data.doctorVerified
          );
          if (response.data.getUserInfo.data.doctorVerified) {
            snackbar({
              message: "Doctor is verified Successfully.",
              color: "success",
            });
          } else {
            snackbar({
              message:
                "Please add fee policy from Fee section for getting started.",
              color: "success",
            });
          }
        }
      } catch (err) {
        console.log(err);
      }
      setLoader(false);
    },
    [data]
  );

  return (
    <>
      <Loader show={loader} />
      <div className=" mbsc-col-12 mbsc-col-md-4 mbsc-col-lg-3">
        <LeftPanel
          edit={edit}
          setEdit={setEdit}
          data={data}
          setData={setData}
          edited={edited}
          setEdited={setEdited}
          setTouched={setTouched}
          cancelOnClick={cancelOnClick}
          editOnClick={editOnClick}
          _renderAction={_renderAction}
          handleChange={handleChange}
          handleProfileChange={handleImageChange}
          handleImageUpload={handleImageUpload}
        />
      </div>
      <div className="mbsc-col-lg-9 mbsc-col-md-8 mbsc-col-12">
        <RightPanel
          edit={edit}
          setEdit={setEdit}
          data={data}
          setData={setData}
          edited={edited}
          setEdited={setEdited}
          setTouched={setTouched}
          cancelOnClick={cancelOnClick}
          editOnClick={editOnClick}
          _renderAction={_renderAction}
          handleChange={handleChange}
          handleImageChange={handleImageChange}
          handleClinicLogoHeaderChange={handleClinicLogoHeaderChange}
          reloadData={reloadData}
        />
      </div>
    </>
  );
};

export default Profile;
