import React from "react";
import { Input } from "@mobiscroll/react5";

const InputFloating = () => {
  return (
    <Input
      inputStyle="underline"
      labelStyle="floating"
      label="Label"
      placeholder="Username"
      name="username"
    />
  );
};

export default InputFloating;
