import _debounce from "lodash/debounce";
import React, { Fragment, useCallback, useState } from "react";
import AllPatient from "../../../assets/icons/All Patient Record.svg";

// import "./PatientList.css";
import { Switch } from "@mobiscroll/react";
import { AgGridColumn, AgGridReact } from "ag-grid-react";
import { Button, Card, Image } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import Loader from "src/components/ClinicDashboard/common/Loader";
import ActionCellRender from "../Layouts/ActionCellRender";
import PatientNameCellRenderer from "./EmployeeRenderer";
//api
import {
  activateEmployee,
  deactivateEmployee,
  listEmployeesClinic,
} from "./api";
import { isMobileOnly } from "react-device-detect";

const { Header: CardHeader, Body: CardBody, Title: CardTitle } = Card;

const options = {
  year: "numeric",
  month: "short",
  day: "numeric",
};

export default function EmployeeList() {
  const history = useHistory();

  // eslint-disable-next-line
  const [gridApi, setGridApi] = useState(null);
  // eslint-disable-next-line
  const [gridColumnApi, setGridColumnApi] = useState(null);
  const [listData, setListData] = useState([]);
  const [displayRecords, setDisplayRecords] = useState("10");
  const [totalRecords, setTotalRecords] = useState(0);
  const [loader, setLoader] = useState(false);
  const [inputSearch, setInputSearch] = useState("");
  const [flag, setFlag] = useState(true);

  const fetchEmployees = async (displayRecords, search) => {
    setLoader(true);
    const res = await listEmployeesClinic(+displayRecords, search);
    if (res?.data) {
      setRowData(res.data);
      let value =
        res?.data.length < 10 ? `0${res?.data.length}` : res.data.length;
      setDisplayRecords(value);
      setFlag(false);
      setTotalRecords(res.totalCount);
    }
    setLoader(false);
  };

  const debounceFn = useCallback(_debounce(fetchEmployees, 1000), []);

  function StatusCellRenderer(params) {
    console.log(params);
    const [checked, setChecked] = React.useState(params.value === "Active");
    const handleChange = async (e) => {
      setLoader(true);
      const data = {
        employeeId: params.data.id,
      };
      try {
        if (e.target.checked) {
          if (await activateEmployee(data)) setFlag(true);
        } else {
          if (await deactivateEmployee(data)) setFlag(true);
        }
      } catch (err) {
        console.log(err);
      }
      setLoader(false);
    };
    return (
      <>
        <div className="w-100 d-flex align-items-center justify-content-center">
          <Switch
            className="docg-table-switch"
            onChange={handleChange}
            checked={checked}
            theme="auto"
            position="start"
            color="success"
            name="noti2"
            id={`patient-${params.rowIndex}`}
          />
        </div>
      </>
    );
  }

  const onGridReady = (params) => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
  };

  const onPageSizeChanged = (e) => {
    setFlag(true);
    setDisplayRecords(e.valueText);
    gridApi.paginationSetPageSize(Number(e.valueText));
  };

  const handleSearch = (e) => {
    setInputSearch(e.target.value);
    debounceFn(10, e.target.value);
  };

  const routeChange = () => {
    history.push("add-employee");
  };
  const [rowData, setRowData] = useState([]);

  React.useEffect(async () => {
    if (flag) await fetchEmployees(displayRecords, inputSearch);
  }, [displayRecords, flag]);

  const defaultColDef = {
    sortable: true,
    //resizable: false,
    filter: true,
    flex: 1,
    minWidth: 200,
  };
  // if we had column groups, we could provide default group items here
  const defaultColGroupDef = {};

  const [success, setSuccess] = useState(true);

  const patientNameValueGetter = (params) => {
    let name = [params.data.title, params.data.firstName, params.data.lastName];
    return name.filter((el) => el !== null && el !== undefined).join(" ");
  };

  const roleValueGetter = (params) => {
    console.log(params);
    return params.data.roles
      .filter((el) => el !== null && el !== undefined)
      .join(", ");
  };

  return (
    <Fragment>
      <Loader show={loader} />

      <div className="mbsc-col-12">
        <Card className="docg-card docg-vh-standard">
          <CardHeader
            className="d-flex justify-content-between customCardHeader"
            style={{ paddingTop: "0", paddingBottom: "0" }}
          >
            <CardTitle className="docg-card-title customCardTitle">
              <Image src={AllPatient} alt="" className="mr-2" /> All Employees
              Record
            </CardTitle>
            {!isMobileOnly && (
              <div className="d-flex align-items-center">
                <div className="docg-table-search">
                  <input
                    type="text"
                    onInput={handleSearch}
                    id="quickFilter"
                    placeholder="Search..."
                  />
                </div>
                <Button className="theme-button btn-xs " onClick={routeChange}>
                  <span className="material-icons pr-1">
                    add_circle_outline
                  </span>{" "}
                  Add
                </Button>
              </div>
            )}
          </CardHeader>
          <CardBody className="docg-card-body pb-4">
            {/* <div className="records-display">
              <span>Showing&nbsp;</span>
              <label className="record-selection-dropdown" htmlFor="">
                <mobiscroll.Select
                  touchUi={false}
                  onChange={(e) => {
                    onPageSizeChanged(e);
                  }}
                  value={displayRecords}
                >
                  {Array.from(Array(totalRecords).keys()).map((index) => {
                    let value = index + 1 < 10 ? `0${index + 1}` : index + 1;
                    return (
                      <option key={value} value={value}>
                        {value}
                      </option>
                    );
                  })}
                </mobiscroll.Select>
                <i class="fas fa-chevron-down"></i>
              </label>
              <span>&nbsp;Records</span>
            </div> */}
            <div
              className="ag-theme-alpine"
              style={{
                height: "100%",
                width: "100%",
              }}
            >
              <AgGridReact
                rowData={rowData}
                defaultColDef={defaultColDef}
                frameworkComponents={{
                  actionCellRender: ActionCellRender,
                  statusCellRenderer: StatusCellRenderer,
                  patientNameCellRenderer: PatientNameCellRenderer,
                }}
                defaultColGroupDef={defaultColGroupDef}
                enableRangeSelection={true}
                pagination={true}
                onGridReady={onGridReady}
                paginationPageSize={displayRecords}
                domLayout={"autoHeight"}
                animateRows={true}
              >
                <AgGridColumn
                  headerName="Name"
                  headerTooltip="Name"
                  field="PatientName"
                  tooltipField="PatientName"
                  valueGetter={patientNameValueGetter}
                  tooltipValueGetter={patientNameValueGetter}
                  initialPinned="left"
                  initialSort="asc"
                  cellRenderer="patientNameCellRenderer"
                ></AgGridColumn>
                <AgGridColumn
                  headerName="Employee ID"
                  headerTooltip="Employee ID"
                  field="uhid"
                  tooltipField="uhid"
                ></AgGridColumn>
                <AgGridColumn
                  headerName="Email"
                  headerTooltip="Email"
                  field="email"
                  tooltipField="email"
                ></AgGridColumn>
                <AgGridColumn
                  headerName="Mobile"
                  headerTooltip="Mobile"
                  field="phoneNumber"
                  tooltipField="phoneNumber"
                ></AgGridColumn>
                <AgGridColumn
                  valueGetter={roleValueGetter}
                  tooltipValueGetter={roleValueGetter}
                  headerName="Role"
                  headerTooltip="Role"
                  field="roles"
                ></AgGridColumn>
                <AgGridColumn
                  headerName=""
                  field="accountStatus"
                  cellRenderer="statusCellRenderer"
                  maxWidth={80}
                ></AgGridColumn>
              </AgGridReact>
            </div>
          </CardBody>
        </Card>
      </div>
    </Fragment>
  );
}
