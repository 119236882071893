import React, { Fragment, useState } from "react";

// import Waiting from "../../../assets/icons/Waiting.svg";
import "./Card.css";
import "react-circular-progressbar/dist/styles.css";
import DashobardIcon from "src/assets/icons/Untitled.png";
import DashobardIconRotate from "src/assets/icons/Untitled(1).png";
import CardExpanded from "src/shared/KPI/CardExpanded";
import { Row, Col } from "react-bootstrap";
import CardTitle from "src/shared/cardTitle";

const Card = ({ cards }) => {
  const [lgShow, setLgShow] = useState(false);
  const [toggle, setToggle] = useState(true);

  const toggleChange = async (e) => {
    setToggle(!e);
  };

  return (
    <Fragment>
      {/* <CardExpanded
        className={"card-title text-white add-radius text-center"}
        title="Title"
      /> */}
      <Row className="d-flex w-100 justify-content-center align-content-center flex-row">
        {cards.map((item, index) => {
          return (
            <Col key={index} xs={12} sm={6} md={6} lg={6} xl={6}>
              <CardExpanded item={item} />
            </Col>
          );
        })}
      </Row>
    </Fragment>
  );
};
export default Card;
