import { useCallback, useState } from "react";

const useForm = ({ initialValues, validationSchema }) => {
  const [formValues, setFormValues] = useState(initialValues);
  const [errors, setErrors] = useState(initialValues);

  const touchedObj = useCallback(() => {
    let obj = {};
    for (let key in initialValues) {
      obj[key] = false;
    }
    return obj;
  }, [initialValues]);

  const [touched, setTouched] = useState(touchedObj);

  const handleChange = (e) => {
    let updatedTouched = Object.assign({});
    updatedTouched[e.target.name] = true;
    setTouched((prevState) => {
      return { ...prevState, ...updatedTouched };
    });
    const updatedValues = Object.assign({}, formValues);
    updatedValues[e.target.name] = e.target.value;
    setFormValues(updatedValues);
  };

  const handleBlur = (e) => {
    const updatedTouched = Object.assign({}, touched);
    updatedTouched[e.target.name] = true;
    setTouched(updatedTouched);
    let errObj = {};
    validationSchema
      .validate(formValues, { abortEarly: false })
      .then((valid) => setErrors({}))
      .catch((err) => {
        for (let i = 0; i < err.errors.length; i++) {
          let error = err.errors[i];
          let errorKey = error.split(" ")[0];
          errObj[errorKey] = error;
        }
        setErrors(errObj);
      });
  };

  const customValidate = (validationObj) => {
    const updatedTouched = Object.assign({}, touched, validationObj);
    setTouched(updatedTouched);
    let errObj = {};
    validationSchema
      .validate(formValues, { abortEarly: false })
      .then((valid) => setErrors({}))
      .catch((err) => {
        for (let i = 0; i < err.errors.length; i++) {
          let error = err.errors[i];
          let errorKey = error.split(" ")[0];
          errObj[errorKey] = error;
        }
        setErrors(errObj);
      });
  };

  const customChange = (customObj) => {
    let updatedTouched = Object.assign({});
    Object.keys(customObj).forEach((obj) => (updatedTouched[obj] = true));
    setTouched((prevState) => {
      return { ...prevState, ...updatedTouched };
    });
    const updatedFormValues = Object.assign({}, formValues, customObj);
    setFormValues(updatedFormValues);
  };

  return {
    formValues,
    handleChange,
    handleBlur,
    touched,
    errors,
    customValidate,
    customChange,
    setErrors,
    setTouched,
    validationSchema,
  };
};
export default useForm;