import mobiscroll from "@mobiscroll/react";
import "@mobiscroll/react/dist/css/mobiscroll.min.css";
import { Select } from "@mobiscroll/react5";
import { AgGridColumn, AgGridReact } from "ag-grid-react";
import React, { Fragment, useState } from "react";
import { Card, Col, Image, Row } from "react-bootstrap";
import FeeImage from "../../../assets/Fee_image.svg";
import EditSvg from "../../../assets/icons/policy_image.svg";
import StatusCellRenderer from "./StatusCellRenderer";
import { useStore } from "./store/Store";
mobiscroll.settings = {
  theme: "ios",
  themeVariant: "light",
};

const iconRender = (params) => {
  return params?.value ? (
    <a href={params.value} target="_blank" rel="noreferrer">
      <img style={{ filter: "contrast(0)" }} src={EditSvg} alt="edit" />
    </a>
  ) : (
    "No Policy"
  );
};

const { Header: CardHeader, Body: CardBody, Title: CardTitle } = Card;

const Feecard = (props) => {
  const { globalState } = useStore();
  // eslint-disable-next-line
  const [gridApi, setGridApi] = useState(null);
  // eslint-disable-next-line
  const [gridColumnApi, setGridColumnApi] = useState(null);
  const [displayRecords, setDisplayRecords] = useState("00");
  const [totalRecords, setTotalRecords] = useState(0);
  const [data, setData] = useState(["00"]);

  React.useEffect(() => {
    let value =
      globalState?.fees.length < 10
        ? `0${globalState?.fees.length}`
        : globalState.fees.length;
    setData((prevProps) => {
      let arr = [];
      for (let index = 0; index <= globalState?.fees.length / 5; index++) {
        let i = index * 5;
        let value = i < 10 ? `0${i}` : i;
        if (
          !(
            value === globalState?.fees.length &&
            index === globalState?.fees.length / 5
          )
        )
          arr.push(value);
      }
      if (
        globalState.fees.length < arr[arr.length - 1] ||
        globalState.fees.length < 5
      ) {
        let value =
          globalState.fees.length < 10
            ? `0${globalState.fees.length}`
            : globalState.fees.length;
        arr.push(value);
      }
      return arr;
    });
    setDisplayRecords(value >= "05" ? "05" : value);
    setTotalRecords(globalState?.fees.length);
  }, [globalState.fees]);

  const onGridReady = (params) => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
  };

  const onQuickFilterChanged = () => {
    gridApi.setQuickFilter(document.getElementById("quickFilter").value);
  };

  const onPageSizeChanged = (e) => {
    setDisplayRecords(e.valueText);
    gridApi.paginationSetPageSize(Number(e.valueText));
  };

  // changes, needs to be state
  // eslint-disable-next-line

  const defaultColDef = {
    sortable: true,
    resizable: false,
    filter: true,
    flex: 1,
    minWidth: 100,
  };

  // if we had column groups, we could provide default group items here
  const defaultColGroupDef = {};

  return (
    <Fragment>
      <Card className="docg-card docg-appointment patient-wrapper card-wrapper">
        <div className="records-display-new">
          <span>Showing&nbsp;</span>
          <label className="record-selection-dropdown" htmlFor="">
            {totalRecords >= 0 && (
              <Select
                touchUi={false}
                onChange={onPageSizeChanged}
                value={displayRecords}
                data={data}
              />
            )}
          </label>
          <span>&nbsp;Records</span>
        </div>
        <CardHeader
          className="d-flex justify-content-between customCardHeader"
          style={{ backgroundColor: "#dee2e6" }}
        >
          <CardTitle className="customCardTitle">
            <Image src={FeeImage} alt="" className="mr-2" /> Fee Details
          </CardTitle>
          <div className="docg-table-search">
            <input
              className="input_search_box"
              type="text"
              onInput={() => onQuickFilterChanged()}
              id="quickFilter"
              placeholder="Search..."
            />
          </div>
        </CardHeader>
        <CardBody className="docg-card-body p-0">
          <Row>
            <Col>
              <div className="ag-theme-alpine">
                <AgGridReact
                  frameworkComponents={{
                    statusCellRender: StatusCellRenderer,
                    iconRender: iconRender,
                  }}
                  rowData={globalState.fees}
                  defaultColDef={defaultColDef}
                  defaultColGroupDef={defaultColGroupDef}
                  enableRangeSelection={true}
                  pagination={true}
                  onGridReady={onGridReady}
                  paginationPageSize={displayRecords}
                  domLayout={"autoHeight"}
                  animateRows={true}
                >
                  <AgGridColumn
                    headerName="Plan Name"
                    headerTooltip="Plan Name"
                    tooltipField="planName"
                    field="planName"
                    initialPinned="left"
                  ></AgGridColumn>
                  <AgGridColumn
                    headerName="Plan Type"
                    headerTooltip="Plan Type"
                    field="planType"
                    valueFormatter={({ value }) => {
                      return value === "Face2Face"
                        ? "In Person at Clinic"
                        : value;
                    }}
                    tooltipValueGetter={({ value }) => {
                      return value === "Face2Face"
                        ? "In Person at Clinic"
                        : value;
                    }}
                  ></AgGridColumn>
                  <AgGridColumn
                    headerTooltip="Fee"
                    headerName="Fee"
                    tooltipField="fee"
                    field="fee"
                  ></AgGridColumn>
                  <AgGridColumn
                    headerName="Cancellation"
                    headerTooltip="Cancellation"
                    field="cancellationAllowed"
                    valueGetter={(params) => {
                      console.log(params);
                      return params.data.cancellationAllowed ? "Yes" : "No";
                    }}
                    tooltipValueGetter={(params) => {
                      return params.data.cancellationAllowed ? "Yes" : "No";
                    }}
                  ></AgGridColumn>
                  <AgGridColumn
                    headerName="Free Follow up"
                    headerTooltip="Free Follow up"
                    valueGetter={(params) =>
                      params.data.allowedFreeFollowUpVisits + " days"
                    }
                    tooltipValueGetter={(params) =>
                      params.data.allowedFreeFollowUpVisits + " days"
                    }
                  ></AgGridColumn>
                  <AgGridColumn
                    headerName="Reschedule"
                    field="reschedule"
                    headerTooltip="Reschedule"
                    valueGetter={(params) =>
                      params.data.reschedulingAllowed +
                      " " +
                      params.data.reschedulingUnits
                    }
                    tooltipValueGetter={(params) =>
                      params.data.reschedulingAllowed +
                      " " +
                      params.data.reschedulingUnits
                    }
                  ></AgGridColumn>
                  <AgGridColumn
                    headerName="Offline Payment"
                    headerTooltip="Offline Payment"
                    tooltipField="cashPaymentAccepted"
                    field="cashPaymentAccepted"
                    tooltipValueGetter={({ value }) => {
                      return value ? "Yes" : "No";
                    }}
                  ></AgGridColumn>
                  <AgGridColumn
                    headerTooltip="Policy"
                    header="Policy"
                    field="policy"
                    cellRenderer="iconRender"
                  ></AgGridColumn>
                  <AgGridColumn
                    header=" "
                    field="status"
                    cellRenderer="statusCellRender"
                    minWidth={130}
                    initialSort="asc"
                  ></AgGridColumn>
                </AgGridReact>
              </div>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </Fragment>
  );
};

export default Feecard;
