import { NavItem, TabNav } from "@mobiscroll/react";
import { SegmentedGroup, SegmentedItem } from "@mobiscroll/react5";
import React, { useState } from "react";
import Avatar from "react-avatar";
import { Card } from "react-bootstrap";
import { Helmet } from "react-helmet";
import AddPatient from "./Tab2/AddPatient";

import addPatient from "src/assets/patient/addPatient.svg";

import "./addpatient.scss";
//api
import { getDoctors } from "src/components/api/index";

const { Header: CardHeader, Body: CardBody, Title: CardTitle } = Card;

const AddPatientPage = () => {
  const [tab, setTab] = useState(2);
  const [doctorData, setDoctorData] = useState([]);
  const [selectedDoctor, setSelectedDoctor] = useState(null);

  React.useEffect(async () => {
    let res = JSON.parse(sessionStorage.getItem("doctors-list"));
    if (!res) {
      res = await getDoctors();
    }
    console.log(res);
    res = res.map((item) => {
      return { ...item, checked: false };
    });
    setDoctorData(res);
  }, []);

  const handleChange = (e) => {
    console.log(e);
    let res = doctorData;
    const doctor_data = JSON.parse(e.target.value);
    res = res.map((doctor) => {
      return doctor.id === doctor_data.id
        ? { ...doctor, checked: e.target.checked }
        : { ...doctor, checked: false };
    });
    setSelectedDoctor(doctor_data.id);
    setDoctorData(res);
  };

  const onQuickFilterChanged = () => {};

  return (
    <>
      <Helmet>
        <title>Add Patient | DOCG</title>
      </Helmet>
      <Card className="docg-card docg-appointments docg-vh-standard docg-add-patient">
        <CardHeader className="d-flex justify-content-between customCardHeader pb-0 pt-0">
          <TabNav
            style={{ width: "fit-content" }}
            className="docg-tabs d-inline-flex"
            theme="windows"
            context="#tab-content"
          >
            <NavItem selected={tab === 2} onClick={() => setTab(2)}>
              <img src={addPatient} alt="" />
              Add Patient
            </NavItem>
          </TabNav>
          <div className="h-100 ml-2"></div>
          <SegmentedGroup
            className="doctor-list d-flex mr-3 ml-3"
            style={{ padding: "0 2px" }}
            onChange={handleChange}
          >
            {doctorData.map((doctor) => {
              return (
                <SegmentedItem
                  className="doctor-detail"
                  name={doctor.id}
                  id={doctor.id}
                  value={JSON.stringify(doctor)}
                  checked={doctor?.checked}
                  style={{ padding: "0", margin: "0" }}
                >
                  <Avatar
                    color="#c06c84"
                    round
                    size="35"
                    name={`${doctor?.firstName} ${doctor.lastName}`}
                    src={doctor.profilePicture}
                  />
                  <span className="md-header-filter-name md-header-filter-name-1 ml-1">
                    {doctor.firstName} {doctor.lastName}
                  </span>
                </SegmentedItem>
              );
            })}
          </SegmentedGroup>
        </CardHeader>

        <CardBody className="docg-card-body add-employee-tab w-100 p-0 overflow-auto">
          {selectedDoctor == null ? (
            <div className="d-flex w-100 h-100 justify-content-center align-items-center">
              <h4 className="get-txt">Select doctor to add patient</h4>
            </div>
          ) : (
            <AddPatient selectedDoctor={selectedDoctor} />
          )}
        </CardBody>
      </Card>
    </>
  );
};

export default AddPatientPage;
