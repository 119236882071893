import React, { Suspense, lazy } from "react";
import { Modal } from "react-bootstrap";

const OpenModal = lazy(() => import("./OpenModal"));
const StatusBookedModal = (props) => {
  const { isOpen, closeModal, data } = props;
  console.log(data);
  return (
    <Modal
      size="lg"
      show={isOpen}
      onHide={closeModal}
      dialogClassName="docg-modal"
      aria-labelledby="example-custom-modal-styling-title"
      centered
      backdrop="static"
      keyboard={false}
    >
      {/* <Card className="docg-card"> */}
      {/* </Card> */}
      <Suspense fallback={<div>Loading...</div>}>
        <OpenModal data={data} closePopup={closeModal} goBack={props.goBack} />
      </Suspense>
    </Modal>
  );
};

export default StatusBookedModal;
