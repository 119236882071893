import React from "react";
import { Button, Image } from "react-bootstrap";
import Clock from "../../../assets/icons/Clock.svg";
import RedCross from "../../../assets/Red-cross.svg";

const ActionCellRender = (props) => {
  return (
    <div className="docg-table-action">
      <Button variant="link">
        <Image src={Clock} alt="Patient Detail" />
      </Button>
      <Button variant="link">
        <Image src={RedCross} alt="Patient" />
      </Button>
    </div>
  );
};

export default ActionCellRender;
