import React, { Fragment, useState, useEffect } from "react";
import { Card, Row, Col, Image } from "react-bootstrap";
import "../Layouts/Card.css";
import "./symptom.css";
import SymptomIcon from "src/assets/Symptom.svg";
import upload from "src/assets/upload-purple.svg";
import file from "src/assets/Attachment-icon.svg";
import AddIcon from "src/assets/icons/plusWhite.svg";
import saveIcon from "src/assets/icons/save.svg";

import mobiscroll, { Switch } from "@mobiscroll/react";
import { Input } from "@mobiscroll/react5";
import "@mobiscroll/react/dist/css/mobiscroll.min.css";
import useForm from "src/lib/useForm";
import createSymptomSchema from "./validationSchema/createSymptomSchema";
import { SegmentedGroup, SegmentedItem } from "@mobiscroll/react5";
import UserTwo from "../../../assets/icons/UserTwo.png";
const { Header: CardHeader, Body: CardBody, Title: CardTitle } = Card;

function Symptom() {
  const {
    formValues,
    handleChange,
    touched,
    handleBlur,
    errors,
    customValidate,
    customChange,
  } = useForm({
    initialValues: {
      question1: "",
      question2: "",
      question3: "",
      question4: "",
    },
    validationSchema: createSymptomSchema,
  });
  const [rowData, setRowData] = useState([
    {
      id: 1,
      doctorName: { name: "DR. Singh" },
      lastVisit: "Nov 14, 2021",
      gender: "M",
      nextVisit: "Nov 11, 2021",
      PatientName: { name: "Kate Barke", status: "High" },
      ApptTime: "12:40 PM",
      Disease: "Fever",
      ConslType: "F2F",
      RoomNo: "232",
    },
    {
      id: 2,
      doctorName: { name: "DR. Singh" },
      lastVisit: "Nov 14, 2021",
      gender: "M",
      nextVisit: "Nov 11, 2021",
      PatientName: { name: "Kate Barke", status: "Low" },
      ApptTime: "12:40 PM",
      Disease: "Fever",
      ConslType: "F2F",
      RoomNo: "232",
    },
    {
      id: 3,
      doctorName: { name: "DR. Sanjay" },
      lastVisit: "Nov 14, 2021",
      gender: "M",
      nextVisit: "Nov 11, 2021",
      PatientName: { name: "Kate Barke", status: "Medium" },
      ApptTime: "12:40 PM",
      Disease: "Fever",
      ConslType: "F2F",
      RoomNo: "232",
    },
    {
      id: 4,
      doctorName: { name: "DR. Sanjay" },
      lastVisit: "Nov 14, 2021",
      gender: "M",
      nextVisit: "Nov 11, 2021",
      PatientName: { name: "Kate Barke", status: "High" },
      ApptTime: "12:40 PM",
      Disease: "Fever",
      ConslType: "F2F",
      RoomNo: "232",
    },
    {
      id: 1,
      doctorName: { name: "DR. Arpita" },
      lastVisit: "Nov 14, 2021",
      gender: "M",
      nextVisit: "Nov 11, 2021",
      PatientName: { name: "Kate Barke", status: "Medium" },
      ApptTime: "12:40 PM",
      Disease: "Fever",
      ConslType: "F2F",
      RoomNo: "232",
    },
    {
      id: 2,
      doctorName: { name: "DR. Singh" },
      lastVisit: "Nov 14, 2021",
      gender: "M",
      nextVisit: "Nov 11, 2021",
      PatientName: { name: "Kate Barke", status: "High" },
      ApptTime: "12:40 PM",
      Disease: "Fever",
      ConslType: "F2F",
      RoomNo: "232",
    },
    {
      id: 3,
      doctorName: { name: "DR. Singh" },
      lastVisit: "Nov 14, 2021",
      gender: "M",
      nextVisit: "Nov 11, 2021",
      PatientName: { name: "Kate Barke", status: "Low" },
      ApptTime: "12:40 PM",
      Disease: "Fever",
      ConslType: "F2F",
      RoomNo: "232",
    },
    {
      id: 4,
      doctorName: { name: "DR. Vishnu" },
      lastVisit: "Nov 14, 2021",
      gender: "M",
      nextVisit: "Nov 11, 2021",
      PatientName: { name: "Kate Barke", status: "Low" },
      ApptTime: "12:40 PM",
      Disease: "Fever",
      ConslType: "F2F",
      RoomNo: "232",
    },
    {
      id: 1,
      doctorName: { name: "DR. Arpita" },
      lastVisit: "Nov 14, 2021",
      gender: "M",
      nextVisit: "Nov 11, 2021",
      PatientName: { name: "Kate Barke", status: "High" },
      ApptTime: "12:40 PM",
      Disease: "Fever",
      ConslType: "F2F",
      RoomNo: "232",
    },
    {
      id: 2,
      doctorName: { name: "DR. Singh" },
      lastVisit: "Nov 14, 2021",
      gender: "M",
      nextVisit: "Nov 11, 2021",
      PatientName: { name: "Kate Barke", status: "High" },
      ApptTime: "12:40 PM",
      Disease: "Fever",
      ConslType: "F2F",
      RoomNo: "232",
    },
    {
      id: 3,
      doctorName: { name: "DR. Vishnu" },
      lastVisit: "Nov 14, 2021",
      gender: "M",
      nextVisit: "Nov 11, 2021",
      PatientName: { name: "Kate Barke", status: "High" },
      ApptTime: "12:40 PM",
      Disease: "Fever",
      ConslType: "F2F",
      RoomNo: "232",
    },
    {
      id: 4,
      doctorName: { name: "DR. Vishnu" },
      lastVisit: "Nov 14, 2021",
      gender: "M",
      nextVisit: "Nov 11, 2021",
      PatientName: { name: "Kate Barke", status: "High" },
      ApptTime: "12:40 PM",
      Disease: "Fever",
      ConslType: "F2F",
      RoomNo: "232",
    },
    {
      id: 1,
      doctorName: { name: "DR. Vishnu" },
      lastVisit: "Nov 14, 2021",
      gender: "M",
      nextVisit: "Nov 11, 2021",
      PatientName: { name: "Kate Barke", status: "High" },
      ApptTime: "12:40 PM",
      Disease: "Fever",
      ConslType: "F2F",
      RoomNo: "232",
    },
    {
      id: 2,
      doctorName: { name: "DR. Sanjay" },
      lastVisit: "Nov 14, 2021",
      gender: "M",
      nextVisit: "Nov 11, 2021",
      PatientName: { name: "Kate Barke", status: "High" },
      ApptTime: "12:40 PM",
      Disease: "Fever",
      ConslType: "F2F",
      RoomNo: "232",
    },
    {
      id: 3,
      doctorName: { name: "DR. Arpita" },
      lastVisit: "Nov 14, 2021",
      gender: "M",
      nextVisit: "Nov 11, 2021",
      PatientName: { name: "Kate Barke", status: "High" },
      ApptTime: "12:40 PM",
      Disease: "Fever",
      ConslType: "F2F",
      RoomNo: "232",
    },
    {
      id: 4,
      doctorName: { name: "DR. Arpita" },
      lastVisit: "Nov 14, 2021",
      gender: "M",
      nextVisit: "Nov 11, 2021",
      PatientName: { name: "Kate Barke", status: "High" },
      ApptTime: "12:40 PM",
      Disease: "Fever",
      ConslType: "F2F",
      RoomNo: "232",
    },
    {
      id: 1,
      doctorName: { name: "DR. Sanjay" },
      lastVisit: "Nov 14, 2021",
      gender: "M",
      nextVisit: "Nov 11, 2021",
      PatientName: { name: "Kate Barke", status: "High" },
      ApptTime: "12:40 PM",
      Disease: "Fever",
      ConslType: "F2F",
      RoomNo: "232",
    },
    {
      id: 2,
      doctorName: { name: "DR. Arpita" },
      lastVisit: "Nov 14, 2021",
      gender: "M",
      nextVisit: "Nov 11, 2021",
      PatientName: { name: "Kate Barke", status: "High" },
      ApptTime: "12:40 PM",
      Disease: "Fever",
      ConslType: "F2F",
      RoomNo: "232",
    },
    {
      id: 3,
      doctorName: { name: "DR. Sanjay" },
      lastVisit: "Nov 14, 2021",
      gender: "M",
      nextVisit: "Nov 11, 2021",
      PatientName: { name: "Kate Barke", status: "Low" },
      ApptTime: "12:40 PM",
      Disease: "Fever",
      ConslType: "F2F",
      RoomNo: "232",
    },
    {
      id: 4,
      doctorName: { name: "DR. Vishnu" },
      lastVisit: "Nov 14, 2021",
      gender: "M",
      nextVisit: "Nov 11, 2021",
      PatientName: { name: "Kate Barke", status: "High" },
      ApptTime: "12:40 PM",
      Disease: "Fever",
      ConslType: "F2F",
      RoomNo: "232",
    },
    {
      id: 4,
      doctorName: { name: "DR. Vishnu" },
      lastVisit: "Nov 14, 2021",
      gender: "M",
      nextVisit: "Nov 11, 2021",
      PatientName: { name: "Kate Barke", status: "High" },
      ApptTime: "12:40 PM",
      Disease: "Fever",
      ConslType: "F2F",
    },
    {
      id: 4,
      doctorName: { name: "DR. Singh" },
      lastVisit: "Nov 14, 2021",
      gender: "M",
      nextVisit: "Nov 11, 2021",
      PatientName: { name: "Kate Barke", status: "Low" },
      ApptTime: "12:40 PM",
      Disease: "Fever",
      ConslType: "F2F",
      RoomNo: "232",
    },
    {
      id: 4,
      doctorName: { name: "DR. Singh" },
      lastVisit: "Nov 14, 2021",
      gender: "M",
      nextVisit: "Nov 11, 2021",
      PatientName: { name: "Kate Barke", status: "High" },
      ApptTime: "12:40 PM",
      Disease: "Fever",
      ConslType: "F2F",
      RoomNo: "232",
    },
  ]);

  // let initial = rowData[0].doctorName.name;
  const [selectedDoctor, setSelectedDoctor] = React.useState({
    "DR. Singh": true,
  });
  const [selectedDoctorData, setSelectedDoctorData] = React.useState(
    rowData.filter((d) => d.doctorName.name === "DR. Singh")
  );

  const defaultColDef = {
    sortable: true,
    resizable: false,
    filter: true,
    flex: 1,
    minWidth: 100,
  };

  let doctorList = [];
  rowData.forEach((data) => {
    if (!doctorList.includes(data.doctorName.name)) {
      doctorList.push(data.doctorName.name);
    }
  });

  const filter = (event) => {
    const value = event.target.value;
    const checked = event.target.checked;
    selectedDoctor[value] = checked;
    setSelectedDoctor(selectedDoctor);
    // setSelectedDoctorData(rowData.filter((d) => d.doctorName.name === value));
    let ev = [];
    for (let i = 0; i < rowData.length; ++i) {
      const item = rowData[i];
      if (selectedDoctor[item.doctorName.name]) {
        ev.push(item);
      }
    }
    setSelectedDoctorData(ev);
  };

  const handleClick = (e) => {
    e.preventDefault();
    customValidate({
      question1: true,
      question2: true,
      question3: true,
      question4: true,
    });
  };

  const [switchOn, setSwitchOn] = useState(true);

  const changeSwitchOn = (ev) => {
    setSwitchOn(ev.target.checked);
  };

  return (
    <Fragment>
      <div className="mbsc-col-12 mbsc-col-sm-12 mbsc-col-md-12 mbsc-col-lg-12">
        <Card className="docg-patient-form-wrapper docg-vh-standard">
          {/* <Card.Title className="justify-content-start">
            <img src={SymptomIcon} alt="" />
            Symptom Checker
          </Card.Title> */}
          {/* <CardHeader
            className="docg-card-header"
            style={{ paddingTop: "0", paddingBottom: "0", backgroundColor:"#dee2e6" }}
          >
            <Row
              className="d-flex justify-content-between align-items-center"
              style={{ padding: "0" }}
            >
              <Col
                xs={12}
                sm={12}
                md={4}
                lg={3}
                xl={3}
                style={{ padding: "0" }}
              >
                <CardTitle className="justify-content-start"  style={{height:"70px", alignItems:"center", justifyContent:"center"}}>
                  <Image src={SymptomIcon} alt="" />
                  &nbsp; Symptom Checker
                </CardTitle>
              </Col>
              <Col
                className="doctor-segment"
                style={{ overflow: "auto", padding: "0" }}
                xs={12}
                sm={12}
                md={4}
                lg={5}
                xl={6}
              >
               
              </Col>
            </Row>
          </CardHeader> */}
          <Card.Header
          className="d-flex justify-content-between customCardHeader"
          style={{ backgroundColor:"#dee2e6" }}
        >
          <Card.Title
            className="customCardTitle"
          >
          <Image src={SymptomIcon} alt="" />
                  &nbsp; Symptom Checker
        </Card.Title>
        </Card.Header>
          <Card.Body className="docg-h-standard">
            <div className="mbsc-grid">
              <div className="mbsc-row">
                <div className="mbsc-col-12 mbsc-col-sm-12 mbsc-col-md-12 mbsc-col-lg-12">
                  <mobiscroll.FormGroup className="d-flex justify-content-between">
                    <Input
                      name="question"
                      defaultValue={formValues.question1}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      inputStyle="underline"
                      labelStyle="floating"
                      label="What symptom is bothering you the most?"
                      placeholder="Question: Type question here"
                      errorMessage={touched.question1 ? errors.question1 : ""}
                      className="symptom-input"
                    />
                    <Switch
                      defaultChecked={switchOn}
                      onChange={changeSwitchOn}
                    ></Switch>
                  </mobiscroll.FormGroup>
                </div>
                <div className="mbsc-col-12 mbsc-col-sm-12 mbsc-col-md-12 mbsc-col-lg-12">
                  <mobiscroll.FormGroup className="d-flex justify-content-between align-items-center">
                    <Input
                      name="question"
                      defaultValue={formValues.question1}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      inputStyle="underline"
                      labelStyle="floating"
                      label="Upload a File (optional)"
                      placeholder="Example: A photo of your skin Condition"
                      errorMessage={touched.question1 ? errors.question1 : ""}
                      className="symptom-input"
                    />
                    <img
                      style={{
                        maxHeight: "1.75rem",
                        maxWidth: "1.75rem",
                        height: "1.75rem",
                        width: "1.75rem",
                      }}
                      src={upload}
                      alt="h-100"
                    />
                  </mobiscroll.FormGroup>
                </div>
                <div className="mbsc-col-12 mbsc-col-sm-12 mbsc-col-md-12 mbsc-col-lg-12">
                  <mobiscroll.FormGroup className="d-flex justify-content-between align-items-center">
                    <Input
                      name="question"
                      defaultValue={formValues.question1}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      inputStyle="underline"
                      labelStyle="floating"
                      label="Add medical Report (optional)"
                      placeholder="Add medications, allergies, past conditions etc."
                      errorMessage={touched.question1 ? errors.question1 : ""}
                      className="symptom-input"
                    />
                    <img
                      style={{
                        maxWidth: "1.75rem",
                        width: "1.75rem",
                      }}
                      src={file}
                      alt="h-80"
                      className=""
                    />
                  </mobiscroll.FormGroup>
                </div>
                <div
                  style={{ position: "relative", bottom: 0 }}
                  className="mbsc-row w-100"
                >
                  <div className="mbsc-col-12 d-flex align-items-center justify-content-between">
                    <button className="docg-save-btn">
                      <img src={AddIcon} alt="" /> Add more Questions
                    </button>
                    <button className="docg-save-btn">
                      <img src={saveIcon} alt="" />
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Card.Body>
        </Card>
      </div>
    </Fragment>
  );
}

export default Symptom;
