import {
  getQueryWithParams,
  getQuery,
  postMutation,
} from "src/components/ApolloConnection/ApolloProviderComponent";
import { LIST_DOCTORS } from "src/components/graphql/GraphQLquery";
import { snackbar } from "@mobiscroll/react5";

export async function getDoctors() {
  try {
    const response = await getQuery(LIST_DOCTORS);
    if (typeof response == "undefined") {
      throw new Error("error", response);
    } else {
      snackbar({
        message: "List of Doctors fetched successfully.",
        color: "success",
      });
      if (response?.errors) {
        throw new Error("error", response);
      } else if (response?.data?.listDoctors?.data) {
        console.log(response?.data?.listDoctors?.data);
        return response?.data?.listDoctors?.data;
      } else {
        throw new Error("error", response);
      }
    }
  } catch (e) {
    snackbar({
      message: "Some error occured while fetching doctor list, Please Refresh.",
      color: "danger",
    });
    console.log("Error at List Patients: ", e);
    return [];
  }
}
