import moment from "moment";
import React from "react";
import { useHistory } from "react-router-dom";
import oopsImage from "src/assets/oops.svg";
import { getMyProfile } from "src/components/ClinicDashboard/Profile/api";
import Loader from "src/components/ClinicDashboard/common/Loader";
import { setSubscription } from "src/store/GlobalReducer";
import { useStore } from "src/store/Store";
import {
  getQuery,
  getQueryWithParams,
} from "../../components/ApolloConnection/ApolloProviderComponent";
import {
  GET_RAZORPAY_SUBSCRIPTION,
  USER_DATA,
} from "../../components/graphql/GraphQLquery";

export default function Subscription() {
  const { globalState, dispatch } = useStore();
  const history = useHistory();
  const getUserInfo = async () => {
    const response = await getQuery(USER_DATA);
    const res = response.data.getUserInfo.data;
    dispatch(setSubscription(res.subscriptionStatus));
  };
  const [loader, setLoader] = React.useState(false);
  const [startTime, setStartTime] = React.useState(null);
  const [options, setOptions] = React.useState({
    key: "rzp_test_FAmWQmMl46gSc2",
    name: "Inteliyo",
    description: "Monthly Test Plan",
    image: "/docg.png",
    handler: async function (response) {
      console.log(response);
      await getUserInfo();
      history.push("/dashboard");
    },
    notes: {
      note_key_1: "Inteliyo Subscription",
    },
    theme: {
      color: "#c06c84",
    },
  });

  const handlePay = (e) => {
    var rzp1 = new window.Razorpay(options);
    rzp1.open();
    e.preventDefault();
  };

  const fetchSubscriptionDetails = async () => {
    setLoader(true);
    try {
      let user = await getMyProfile();
      let response = await getQueryWithParams(
        GET_RAZORPAY_SUBSCRIPTION,
        user.id
      );
      const time = moment
        .unix(response?.data?.getRazorpaySubscription?.data?.startAt)
        .local()
        .format("MMMM DD, YYYY hh:mm a");
      setStartTime(time);
      let subscription_id =
        response?.data?.getRazorpaySubscription?.data?.subscriptionId;
      setOptions((prev) => ({
        ...prev,
        subscription_id,
        prefill: {
          name: user.name,
          email: user.mail,
          contact: user.helplineNumber,
        },
      }));
    } catch (err) {
      console.log(err);
    }
    setLoader(false);
  };

  React.useEffect(() => {
    fetchSubscriptionDetails();
  }, []);
  console.log(true);
  return (
    <>
      <Loader show={loader} />
      <div className="d-flex justify-content-center align-items-center flex-column w-100 docg-vh-standard">
        <img style={{ maxWidth: 600 }} src={oopsImage} alt="" />
        <br />
        <br />
        <p className="h5 text-center">
          {" "}
          Please note: The payment will start at <u>{startTime}</u> i.e only
          after end of your free trial.
          <br />
          Click on the subscribe button to start your clinic account.
        </p>
        <br />
        <br />
        <button onClick={handlePay} className="docg-save-btn">
          Subscribe
        </button>
      </div>
    </>
  );
}
