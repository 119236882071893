import React from "react";
import Fee from "./Fee";
import { Helmet } from "react-helmet";
import { StoreProvider } from "./store/Store";
import { initialState, feeReducer } from "./store/FeeReducer";

const Schedule = () => {
  return (
    <>
      <Helmet>
        <title>Fee | DOCG</title>
      </Helmet>
      <StoreProvider initialState={initialState} reducer={feeReducer}>
        <Fee />
      </StoreProvider>
    </>
  );
};

export default Schedule;
