import React, { useState } from "react";
import LeftSidebar from "./LeftSidebarClinic";
import { snackbar } from "@mobiscroll/react";
import Loader from "src/components/ClinicDashboard/common/Loader";
import HeaderTop from "./HeaderTopClinic";
import { Redirect } from "react-router-dom";
import { useStore } from "src/store/Store";
import IdleTimer from "./IdleTimer";
import { signOut, fetchMFAPreference } from "aws-amplify/auth";

const ClinicTemplate = (props) => {
  // if we were using auth0
  const [isSideBarVisible, setIsSideBarVisible] = useState(true);
  const { globalState } = useStore();
  // const user = myContext.loginStatus;
  const status = sessionStorage.getItem("loginStatus");

  async function _signOut() {
    snackbar({
      message: "You have been logged out",
      color: "danger",
    });
    try {
      await signOut({ global: true });
      localStorage.clear();
      sessionStorage.clear();
      window.location = "/";
    } catch (error) {
      console.log("error signing out: ", error);
    }
  }
  React.useEffect(() => {
    const timer = new IdleTimer({
      timeout: 15 * 60, //expire after 15 mins
      onTimeout: async () => {
        await _signOut();
      },
      onExpired: async () => {
        //do something if expired on load
        await _signOut();
      },
    });
    if (window.innerWidth <= 1200) {
      setIsSideBarVisible(false);
    }
    return () => {
      timer.cleanUp();
    };
  }, []);
  // const roles = sessionStorage.getItem("userRole");
  // if there is not user, redirect to the home page
  if (!status) {
    return <Redirect to="/" />;
  }

  const handleSidebar = (...props) => {
    const currentSidebarState = isSideBarVisible;
    setIsSideBarVisible(!currentSidebarState);
  };

  return (
    <>
      <Loader show={globalState.loading} />
      <div className="wrapper">
        <LeftSidebar
          isSideBarVisible={isSideBarVisible}
          handleSidebar={handleSidebar}
        />
        <div className={`main-panel${isSideBarVisible ? "" : " min-sidebar"}`}>
          <HeaderTop
            appoinment={false}
            online={false}
            handleSidebar={handleSidebar}
          />
          <div className="content">
            <div className="mbsc-grid">
              <div className="mbsc-row">{props.children}</div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ClinicTemplate;
