import { Fragment } from "react";
import { Col } from "react-bootstrap";
import { Helmet } from "react-helmet";
import TotalPaitent from "../../assets/icons/TotalPatient.svg";
import Appoinments from "./Appoinments/Appoinments";
import "./Dashboard.scss";
import StaffRooster from "./StaffRooster/StaffRooster";
const tableArr = [
  {
    icon: TotalPaitent,
    title: "Registerd Patients",
    Circular: "proggressBar",
    percentage: 100,
    growthSince: "Since May 2021",
    showModal: true,
  },
  {
    icon: TotalPaitent,
    title: "Total Billing",
    Circular: "proggressBar pateintsPer",
    percentage: 80,
    growthSince: "Since May 2021",
    showModal: true,
  },
  {
    icon: TotalPaitent,
    title: "New Patient",
    Circular: "proggressBar",
    percentage: 10,
  },
  {
    icon: TotalPaitent,
    title: "Total Staff",
    Circular: "proggressBar PendingTask",
    percentage: 60,
  },
];

function Dashboard() {
  return (
    <Fragment>
      <Helmet>
        <title>Dashboard | DOCG</title>
      </Helmet>
      {/* <button onClick={displayRazorpay} className="docg-save-btn">
        Pay
      </button> */}
      {/* <Col className="d-flex" xs={12} sm={12} md={12} lg={12} xl={12}>
        <Card tableArr={tableArr} />
      </Col> */}

      <Col>
        <Appoinments />
      </Col>
      <Col
        style={{ marginTop: "10px" }}
        xs={12}
        sm={12}
        md={12}
        lg={12}
        xl={12}
      >
        <StaffRooster />
      </Col>
    </Fragment>
  );
}

export default Dashboard;
