import React, { Fragment, useState } from "react";
import _signOutIcon from "src/assets/icons/SignoutWhite.svg";
import Avatar from "react-avatar";
import { NavLink } from "react-router-dom";

//api
import { getMyProfile } from "src/components/ClinicDashboard/Profile/api";

import DashobardIcon from "src/assets/icons/Dashboard.svg";
import getImageBlobFromUrl from "src/lib/getImageBlobFromUrl";
import feeImg from "../assets/Fee.svg";
import deletionRequestImg from "../assets/deletionRequest.svg";
import invoice from "../assets/Invoice-white.svg";
import calender from "../assets/icons/Calender.svg";
import walletIcon from "src/assets/walletWhite.svg";
import dashboard from "../assets/icons/DashboardIcon.svg";
import RepotingIcon from "../assets/icons/RepotingIcon.svg";
import Server from "../assets/icons/Server.svg";
import SettingIcon from "../assets/icons/SettingIcon.svg";
import RemoveIcon from "../assets/icons/circle-minus.png";
import AddIcon from "../assets/icons/circle-plus.png";
import schedule from "../assets/icons/schedule.svg";
import LogoImage from "../assets/plus-logo.svg";
import tncIcon from "../assets/tnc.svg";
import { snackbar } from "@mobiscroll/react";
import { signOut } from "aws-amplify/auth";
const LeftSidebarClinic = ({
  isSideBarVisible,
  side,
  Patient,
  handleSidebar,
}) => {
  const [ShowDropDown, setShowDropDown] = useState(false);
  const [ShowSettingDrop, setShowSettingDrop] = useState(false);
  const [showStaffList, setShowStaffList] = useState(false);
  const [user, setUser] = useState({ name: "-", profilePicture: null });

  React.useEffect(async () => {
    let userData = sessionStorage.getItem("user-data");
    if (userData) {
      userData = JSON.parse(userData);
    } else {
      userData = await getMyProfile();
      sessionStorage.setItem("user-data", JSON.stringify(userData));
      sessionStorage.setItem("clinicName", userData?.name);
      const logoLink = await getImageBlobFromUrl(userData?.logoLink);
      sessionStorage.setItem("logoLink", logoLink);
    }
    console.log(userData);
    setUser({ profilePicture: userData?.logoLink, name: userData?.name });

    if (side) {
      setShowSettingDrop(side);
    }
    if (Patient) {
      setShowDropDown(Patient);
    }
    // eslint-disable-next-line
  }, []);
  const SideBarVisible = () => {
    if (isSideBarVisible) {
      return;
    }
  };

  async function _signOut() {
    snackbar({
      message: "You have been logged out",
      color: "danger",
    });
    try {
      await signOut({ global: true });
      localStorage.clear();
      sessionStorage.clear();
      window.location = "/";
    } catch (error) {
      console.log("error signing out: ", error);
    }
  }

  return (
    <Fragment>
      {isSideBarVisible === true ? (
        <>
          <div className="sidebar-backdrop"></div>

          <div className="sidebar" id="sidebar-docg" data-id={isSideBarVisible}>
            <button
              type="button"
              className="btn btn-link mr-1 bg-white d-block d-xl-none"
              style={{
                width: "fit-content",
                position: "absolute",
                top: "10px",
                right: "10px",
              }}
              onClick={handleSidebar}
            >
              <img src={DashobardIcon} alt="" />
            </button>
            <div className="sidebar-logged d-flex justify-content-center align-content-center flex-wrap mt-4 pb-4">
              <Avatar
                color="#c06c84"
                round
                size="4.5rem"
                name={user.name}
                src={user?.profilePicture}
              />
              <h3 className="text-center text-white mt-3 DoctorName">
                {user?.name}
              </h3>
            </div>
            <div className="sidebar-wrapper">
              <ul className="nav">
                <li className="nav-item">
                  <NavLink
                    to="/dashboard"
                    isActive={(match, location) => {
                      if (!match) {
                        if (
                          [
                            "/patient-record",
                            "/add-patient",
                            "/patient-history",
                            "/patient-list",
                          ].includes(location.pathname) ||
                          location.pathname.includes("/patient-record")
                        ) {
                          setShowDropDown(true);
                        } else if (
                          [
                            "/profile",
                            "/invoice",
                            "/create-invoice",
                            "/fee",
                            "/membership",
                            "/symptom",
                          ].includes(location.pathname) ||
                          location.pathname.includes("/invoice-details")
                        ) {
                          setShowSettingDrop(true);
                        } else if (
                          ["/employee-list", "/add-employee"].includes(
                            location.pathname
                          )
                        ) {
                          setShowStaffList(true);
                        }
                        return false;
                      }
                      return true;
                    }}
                    exact
                    className="nav-link navLink"
                  >
                    <img className="addIcon" src={dashboard} alt="" />
                    <p className="">Dashboard</p>
                  </NavLink>
                </li>

                <li className="nav-item">
                  <NavLink to="/schedule" className="nav-link">
                    <img
                      className="addIcon"
                      src={schedule}
                      alt=""
                      style={{ width: "10%" }}
                    />
                    <p>Schedule</p>
                  </NavLink>
                </li>

                <li className="nav-item">
                  <NavLink to="/calendar" className="nav-link">
                    <img className="addIcon" src={calender} alt="" />
                    <p>Calendar</p>
                  </NavLink>
                </li>
                <li
                  className="nav-item"
                  onClick={() => {
                    setShowDropDown((preMode) => !preMode);
                    SideBarVisible();
                  }}
                >
                  {/* eslint-disable-next-line */}
                  <a className="nav-link d-flex">
                    <img className="addIcon" src={Server} alt="" />
                    <p className="">Patient records</p>
                    {ShowDropDown ? (
                      <div className="addIconWrap">
                        <img
                          onClick={() => setShowDropDown(true)}
                          style={{ cursor: "grab" }}
                          className="addIcon"
                          src={RemoveIcon}
                          alt=""
                        />
                      </div>
                    ) : (
                      <div className="addIconWrap">
                        <img
                          onClick={() => setShowDropDown(false)}
                          style={{ cursor: "grab" }}
                          className="addIcon "
                          src={AddIcon}
                          alt=""
                        />
                      </div>
                    )}
                  </a>
                </li>
                {ShowDropDown && (
                  <>
                    <div
                      className="dropdown-menu-container"
                      id="patient-records-dropdown"
                    >
                      <li className="nav-item dropdownLi">
                        <NavLink
                          to="/patient-list"
                          isActive={(match, location) => {
                            if (!match) {
                              if (
                                ["/patient-record", "/patient-list"].includes(
                                  location.pathname
                                ) ||
                                location.pathname.includes("/patient-record")
                              ) {
                                return true;
                              }
                              return false;
                            }

                            // only consider an event active if its event id is an odd number
                            setShowDropDown(true);
                            return true;
                          }}
                          className="nav-link"
                        >
                          <i className="fa fa-users text-white"></i>
                          <p className="mr-2">Patient List</p>
                        </NavLink>
                      </li>
                      <li className="nav-item dropdownLi">
                        <NavLink to="/add-patient" className="nav-link">
                          <i className="fa fa-user-plus text-white"></i>
                          <p className="mr-2">Add Patient</p>
                        </NavLink>
                      </li>
                    </div>
                  </>
                )}
                <li
                  className="nav-item "
                  onClick={() => {
                    setShowStaffList((preMode) => !preMode);
                    SideBarVisible();
                  }}
                >
                  {/* eslint-disable-next-line */}
                  <a className="nav-link d-flex">
                    <img className="addIcon" src={Server} alt="" />
                    <p className="">Staff</p>
                    {showStaffList ? (
                      <div className="addIconWrap">
                        <img
                          style={{ cursor: "grab" }}
                          className="addIcon StISpace"
                          src={RemoveIcon}
                          alt=""
                        />
                      </div>
                    ) : (
                      <div className="addIconWrap">
                        <img
                          style={{ cursor: "grab" }}
                          className="addIcon StISpace"
                          src={AddIcon}
                          alt=""
                        />
                      </div>
                    )}
                  </a>
                </li>
                {showStaffList && (
                  <>
                    <div
                      className="dropdown-menu-container"
                      id="patient-records-dropdown"
                    >
                      <li className="nav-item dropdownLi">
                        <NavLink to="/employee-list" className="nav-link">
                          <i className="fa fa-users text-white"></i>
                          <p className="mr-2">Employees List</p>
                        </NavLink>
                      </li>
                      <li className="nav-item dropdownLi">
                        <NavLink to="/add-employee" className="nav-link">
                          <i className="fa fa-user-plus text-white"></i>
                          <p className="mr-2">Add Employee</p>
                        </NavLink>
                      </li>
                    </div>
                  </>
                )}

                <li className="nav-item ">
                  {/* eslint-disable-next-line */}
                  <NavLink className="nav-link" to="/reporting">
                    <img className="addIcon" src={RepotingIcon} alt="" />
                    <p>Reporting</p>
                  </NavLink>
                </li>
                <li className="nav-item ">
                  {/* eslint-disable-next-line */}
                  <NavLink className="nav-link" to="/subscription/history">
                    <img className="addIcon" src={walletIcon} alt="" />
                    <p>Subscriptions</p>
                  </NavLink>
                </li>

                {/* Setting */}

                <li
                  className="nav-item "
                  onClick={() => {
                    setShowSettingDrop((preMode) => !preMode);
                    SideBarVisible();
                  }}
                >
                  {/* eslint-disable-next-line */}
                  <a className="nav-link d-flex">
                    <img className="addIcon" src={SettingIcon} alt="" />
                    <p className="">Settings</p>
                    {ShowSettingDrop ? (
                      <div className="addIconWrap">
                        <img
                          style={{ cursor: "grab" }}
                          className="addIcon StISpace"
                          src={RemoveIcon}
                          alt=""
                        />
                      </div>
                    ) : (
                      <div className="addIconWrap">
                        <img
                          style={{ cursor: "grab" }}
                          className="addIcon StISpace"
                          src={AddIcon}
                          alt=""
                        />
                      </div>
                    )}
                  </a>
                </li>
                {ShowSettingDrop && (
                  <>
                    <div
                      className="dropdown-menu-container"
                      id="patient-records-dropdown"
                    >
                      <li className="nav-item dropdownLi">
                        <NavLink to="/tnc" className="nav-link">
                          <img src={tncIcon} alt="" />
                          <p className="mr-2">Terms and Policies</p>
                        </NavLink>
                      </li>
                      <li className="nav-item dropdownLi">
                        <NavLink to="/invoice" className="nav-link">
                          <img src={invoice} alt="" />
                          <p className="mr-2">Invoice</p>
                        </NavLink>
                      </li>

                      <li className="nav-item dropdownLi">
                        <NavLink to="/profile" className="nav-link">
                          <i className="fa fa-user text-white"></i>
                          <p className="mr-2">Profile</p>
                        </NavLink>
                      </li>

                      <li className="nav-item dropdownLi">
                        <NavLink to="/fee" className="nav-link">
                          <img src={feeImg} alt="" />
                          <p className="mr-2">Fee</p>
                        </NavLink>
                      </li>
                      <li className="nav-item dropdownLi">
                        <NavLink
                          data-toggle="tooltip"
                          data-placement="right"
                          title="Invite Patient(s)"
                          to="/invite-patient"
                          className="nav-link"
                        >
                          <i className="fa fa-user-plus text-white" />
                          <p className="mr-2">Invite Patient(s)</p>
                        </NavLink>
                      </li>
                      <li className="nav-item dropdownLi">
                        <NavLink
                          data-toggle="tooltip"
                          data-placement="right"
                          title="Requests"
                          to="/clinic-actions"
                          className="nav-link"
                        >
                          <img src={deletionRequestImg} alt="" />
                          <p className="mr-2">Requests</p>
                        </NavLink>
                      </li>

                      {/* <li className="nav-item dropdownLi">
                      <NavLink to="/clinic/notification" className="nav-link">
                        <i className="fa fa-envelope text-white"></i>
                        <p className="mr-2">Notification</p>
                      </NavLink>
                    </li>
                    <li className="nav-item dropdownLi">
                      <NavLink to="/clinic/payment" className="nav-link">
                        <i className="fa fa-rupee-sign text-white"></i>
                        <p className="mr-2">Payment</p>
                      </NavLink>
                    </li> */}
                      {/* <li className="nav-item dropdownLi">
                      <NavLink exact to="/membership" className="nav-link">
                        <img src={membershipImg} alt="" />
                        <p className="mr-2">Membership</p>
                      </NavLink>
                    </li>
                    <li className="nav-item dropdownLi">
                      <NavLink to="/symptom" className="nav-link">
                        <img src={symptomIcon} alt="" />
                        <p className="mr-2">Symptom checker</p>
                      </NavLink>
                    </li> */}
                    </div>
                  </>
                )}
                <li onClick={_signOut} className="nav-item">
                  {/* eslint-disable-next-line */}
                  <a className="nav-link d-flex">
                    <img src={_signOutIcon} alt="" />
                    <p>Logout</p>
                  </a>
                </li>
              </ul>
            </div>
            <div
              className="sidebar-footer d-flex align-items-center justify-content-center flex-column"
              style={{ marginBottom: "0" }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <div className="d-flex justify-content-center flex-column">
                  <h3 className="m-0">Powered by: </h3>
                  <span
                    style={{
                      color: "#fff",
                      border: "2px solid #fff",
                      borderRadius: "20px",
                      textAlign: "center",
                    }}
                  >
                    BETA
                  </span>
                </div>
                <img style={{ width: "4rem" }} src={LogoImage} alt="" />{" "}
              </div>
            </div>
          </div>
        </>
      ) : (
        <div
          id="sidebar-docg"
          data-id={isSideBarVisible}
          className="sidebar width-decrease-sidebar docg-hide"
        >
          <div className="d-flex justify-content-center align-content-center flex-wrap mt-4 pb-4 add-border-docname">
            <Avatar
              color="#c06c84"
              round
              size="4.5rem"
              name={user.name}
              src={user?.profilePicture}
            />{" "}
          </div>
          <div className="sidebar-wrapper width-decrease-sidebar">
            <ul className="nav">
              <li className="nav-item def-div-content ">
                <NavLink
                  data-toggle="tooltip"
                  data-placement="right"
                  title="Dashboard"
                  to="/dashboard"
                  exact
                  isActive={(match, location) => {
                    if (!match) {
                      if (
                        [
                          "/patient-record",
                          "/add-patient",
                          "/patient-history",
                          "/patient-list",
                        ].includes(location.pathname)
                      ) {
                        setShowDropDown(true);
                      } else if (
                        [
                          "/profile",
                          "/invoice",
                          "/create-invoice",
                          "/fee",
                        ].includes(location.pathname) ||
                        location.pathname.includes("/invoice-details")
                      ) {
                        setShowSettingDrop(true);
                      }
                      return false;
                    }
                    return true;
                  }}
                  className="nav-link navLink "
                >
                  <img className="addIcon" src={dashboard} alt="" />
                </NavLink>
              </li>
              <li className="nav-item  def-div-content ">
                <NavLink
                  data-toggle="tooltip"
                  data-placement="right"
                  title="Schedule"
                  to="/schedule"
                  className="nav-link pl-0"
                >
                  <img className="addIcon" src={schedule} alt="" />
                </NavLink>
              </li>
              <li className="nav-item  def-div-content ">
                <NavLink
                  data-toggle="tooltip"
                  data-placement="right"
                  title="Calendar"
                  to="/calendar"
                  className="nav-link pl-0"
                >
                  <img className="addIcon" src={calender} alt="" />
                </NavLink>
              </li>
              <li
                className="nav-item def-div-content position-relative"
                onClick={() => {
                  setShowDropDown((preMode) => !preMode);
                  SideBarVisible();
                }}
              >
                {/* eslint-disable-next-line */}
                <a
                  data-toggle="tooltip"
                  data-placement="right"
                  title="Patient Records"
                  className="nav-link d-flex pl-0"
                >
                  <img className="addIcon" src={Server} alt="" />

                  {ShowDropDown ? (
                    <img
                      onClick={() => setShowDropDown(true)}
                      style={{ cursor: "grab" }}
                      className="addIcon width-decrease-icon"
                      src={RemoveIcon}
                      alt=""
                    />
                  ) : (
                    <img
                      onClick={() => setShowDropDown(false)}
                      style={{ cursor: "grab" }}
                      className="addIcon width-decrease-icon "
                      src={AddIcon}
                      alt=""
                    />
                  )}
                </a>
              </li>
              {ShowDropDown && (
                <>
                  <div
                    className="dropdown-menu-container"
                    id="patient-records-dropdown"
                  >
                    <li className="nav-item dropdownLi">
                      <NavLink
                        data-toggle="tooltip"
                        data-placement="right"
                        title="Patient List"
                        to="/patient-list"
                        isActive={(match, location) => {
                          if (!match) {
                            if (
                              ["/patient-record", "/patient-list"].includes(
                                location.pathname
                              )
                            ) {
                              return true;
                            }
                            return false;
                          }

                          // only consider an event active if its event id is an odd number
                          setShowDropDown(true);
                          return true;
                        }}
                        className="nav-link"
                      >
                        <i className="fa fa-users text-white"></i>
                      </NavLink>
                    </li>
                    <li className="nav-item dropdownLi">
                      <NavLink
                        data-toggle="tooltip"
                        data-placement="right"
                        title="Add Patient"
                        to="/add-patient"
                        className="nav-link"
                      >
                        <i className="fa fa-user-plus text-white"></i>
                      </NavLink>
                    </li>
                  </div>
                </>
              )}

              <li
                className="nav-item "
                onClick={() => {
                  setShowStaffList((preMode) => !preMode);
                  SideBarVisible();
                }}
              >
                <a
                  data-toggle="tooltip"
                  data-placement="right"
                  title="Staff Records"
                  className="nav-link d-flex pl-0"
                >
                  <img className="addIcon" src={Server} alt="" />

                  {showStaffList ? (
                    <img
                      onClick={() => setShowDropDown(true)}
                      style={{ cursor: "grab" }}
                      className="addIcon width-decrease-icon"
                      src={RemoveIcon}
                      alt=""
                    />
                  ) : (
                    <img
                      onClick={() => setShowDropDown(false)}
                      style={{ cursor: "grab" }}
                      className="addIcon width-decrease-icon "
                      src={AddIcon}
                      alt=""
                    />
                  )}
                </a>
              </li>
              {showStaffList && (
                <>
                  <div
                    className="dropdown-menu-container"
                    id="patient-records-dropdown"
                  >
                    <li className="nav-item dropdownLi">
                      <NavLink to="/employee-list" className="nav-link">
                        <i className="fa fa-users text-white"></i>
                      </NavLink>
                    </li>
                    <li className="nav-item dropdownLi">
                      <NavLink to="/add-employee" className="nav-link">
                        <i className="fa fa-user-plus text-white"></i>
                      </NavLink>
                    </li>
                  </div>
                </>
              )}

              <li className="nav-item def-div-content">
                {/* eslint-disable-next-line */}
                <NavLink
                  data-toggle="tooltip"
                  data-placement="right"
                  title="Reporting"
                  className="nav-link pl-0"
                  to="/reporting"
                >
                  <img className="addIcon" src={RepotingIcon} alt="" />
                </NavLink>
              </li>
              <li
                className="nav-item def-div-content position-relative"
                onClick={() => {
                  setShowSettingDrop((preMode) => !preMode);
                  SideBarVisible();
                }}
              >
                {/* eslint-disable-next-line */}
                <a
                  data-toggle="tooltip"
                  data-placement="right"
                  title="Settings"
                  className="nav-link d-flex pl-0"
                >
                  <img className="addIcon" src={SettingIcon} alt="" />
                  {ShowSettingDrop ? (
                    <img
                      style={{ cursor: "grab" }}
                      className="addIcon width-decrease-icon"
                      src={RemoveIcon}
                      alt=""
                    />
                  ) : (
                    <img
                      style={{ cursor: "grab" }}
                      className="addIcon width-decrease-icon"
                      src={AddIcon}
                      alt=""
                    />
                  )}
                </a>
              </li>
              {ShowSettingDrop && (
                <>
                  <div
                    className="dropdown-menu-container"
                    id="patient-records-dropdown"
                  >
                    <li className="nav-item dropdownLi">
                      <NavLink
                        data-toggle="tooltip"
                        data-placement="right"
                        title="TNC"
                        to="/tnc"
                        className="nav-link"
                      >
                        <img src={tncIcon} alt="" />
                      </NavLink>
                    </li>
                    <li className="nav-item dropdownLi">
                      <NavLink
                        data-toggle="tooltip"
                        data-placement="right"
                        title="Invoice"
                        to="/invoice"
                        className="nav-link"
                      >
                        <img src={invoice} alt="" />
                      </NavLink>
                    </li>
                    <li className="nav-item dropdownLi">
                      <NavLink
                        data-toggle="tooltip"
                        data-placement="right"
                        title="Profile"
                        to="/profile"
                        className="nav-link"
                      >
                        <i className="fa fa-user text-white"></i>
                      </NavLink>
                    </li>
                    <li className="nav-item dropdownLi">
                      <NavLink
                        data-toggle="tooltip"
                        data-placement="right"
                        title="Fee"
                        to="/fee"
                        className="nav-link"
                      >
                        <img src={feeImg} alt="" />
                      </NavLink>
                    </li>
                    <li className="nav-item dropdownLi">
                      <NavLink
                        data-toggle="tooltip"
                        data-placement="right"
                        title="Invite Patient(s)"
                        to="/invite-patient"
                        className="nav-link"
                      >
                        <i className="fa fa-user-plus text-white" />
                      </NavLink>
                    </li>
                    <li className="nav-item dropdownLi">
                      <NavLink
                        data-toggle="tooltip"
                        data-placement="right"
                        title="Requests"
                        to="/clinic-actions"
                        className="nav-link"
                      >
                        <i className="fa fa-user-plus text-white" />
                      </NavLink>
                    </li>
                    {/* <li className="nav-item dropdownLi">
                      <NavLink
                        data-toggle="tooltip"
                        data-placement="right"
                        title="Notification"
                        to="/notification"
                        className="nav-link"
                      >
                        <i className="fa fa-envelope text-white"></i>
                      </NavLink>
                    </li>
                    <li className="nav-item dropdownLi">
                      <NavLink
                        data-toggle="tooltip"
                        data-placement="right"
                        title="Payment"
                        to="/payment"
                        className="nav-link"
                      >
                        <i className="fa fa-rupee-sign text-white"></i>
                      </NavLink>
                    </li> */}
                    {/* <li className="nav-item dropdownLi">
                      <NavLink
                        data-toggle="tooltip"
                        data-placement="right"
                        title="Membership"
                        exact
                        to="/membership"
                        className="nav-link"
                      >
                        <img src={membershipImg} alt="" />
                      </NavLink>
                    </li>
                    <li className="nav-item dropdownLi">
                      <NavLink
                        data-toggle="tooltip"
                        data-placement="right"
                        title="Symptom checker Q"
                        to="/symptom"
                        className="nav-link"
                      >
                        <img src={symptomIcon} alt="" />
                      </NavLink>
                    </li> */}
                  </div>
                </>
              )}
            </ul>
          </div>
          <div
            style={{ writingMode: "tb-rl", transform: "rotate(180deg)" }}
            className="sidebar-footer d-flex align-items-center justify-content-center flex-column text-center"
          >
            <h3>Powered by: </h3>
            <img
              style={{ width: "4rem", transform: "rotate(-180deg)" }}
              src={LogoImage}
              alt=""
            />{" "}
          </div>
        </div>
      )}
    </Fragment>
  );
};
export default LeftSidebarClinic;
