import React, { useCallback, useState } from "react";
import { Listview } from "@mobiscroll/react";
import { getMyProfile, listSpecializations, updateProfile } from "./api";
import { Input } from "@mobiscroll/react5";
import deleteIcon from "src/assets/icons/delete.svg";
import editIcon from "src/assets/edit.svg";
import save_pink from "src/assets/save_pink.svg";
import { Modal } from "react-bootstrap";
import Loader from "src/components/ClinicDashboard/common/Loader";
import { debounce } from "lodash";

function MyListItem(props) {
  const { handleEdit = () => {}, handleDelete = () => {} } = props;
  console.log(props);
  return (
    <li className="d-flex justify-content-between align-items-center">
      {props.item.title}
      <div className="">
        <button onClick={() => handleEdit(props.item.id)}>
          <img src={editIcon} alt="" />
        </button>{" "}
        <button
          onClick={() => {
            handleDelete(props.item.id);
          }}
        >
          <img src={deleteIcon} alt="" />
        </button>{" "}
      </div>
    </li>
  );
}

const DotorPopup = (props) => {
  console.log(props);
  const {
    show,
    closePopup,
    title,
    data,
    doctorId,
    reloadData = () => {},
  } = props;
  const [items, setItems] = React.useState([]);
  const [value, setValue] = React.useState("");
  const [error, setError] = React.useState("");
  const [loader, setLoader] = React.useState(false);

  const [specializations, setSpecializations] = useState([]);
  const [showSpecializations, setShowSpecializations] = useState(true);

  const searchSpecializations = async (search) => {
    try {
      let res = await listSpecializations(search);
      res = res.map(({ name }) => name);
      console.log(res);
      setSpecializations(res);
    } catch (e) {
      console.log(e);
    }
  };

  const debounceFn = useCallback(debounce(searchSpecializations, 1000), []);

  React.useEffect(() => {
    debounceFn(value);
  }, [value]);

  React.useEffect(() => {
    const items = [];
    if (data) {
      data.forEach((item, index) => items.push({ id: index, title: item }));
    }
    setItems(items);
  }, [data]);

  const stages = {
    left: [
      {
        percent: 25,
        key: "stage2",
        icon: editIcon,
        color: "#c06c84",
        text: "Edit",
        action: (event, inst) => {
          handleEdit(event.index);
        },
      },
    ],
    right: [
      {
        percent: -25,
        key: "stage1",
        icon: "remove",
        color: "crimson",
        text: "Remove",
        action: (event, inst) => {
          handleDelete(event.index);
        },
      },
    ],
  };

  const handleDelete = React.useCallback(
    async (id) => {
      setLoader(true);
      try {
        let arr = items.filter((item) => item.id !== id);
        arr = arr.map((item) => item.title);
        let res = await updateProfile({ specialities: arr });
        if (res) {
          reloadData();
        }
      } catch (e) {
        console.log("Error", e);
      }
      setLoader(false);
    },
    [items, value, setItems]
  );
  const handleEdit = React.useCallback(
    (index) => {
      let value = "";
      let data = items.filter((item) => {
        if (item.id != index) {
          return true;
        } else {
          value = item.title;
        }
      });
      setItems(data);
      setValue(value);
      document.getElementById("editInput").focus();
    },
    [items, setItems, setValue]
  );
  const handleChange = async () => {
    setLoader(true);

    try {
      if (value.trim() == "") {
        setError("Enter value");
      } else {
        const arr = [];
        items.forEach((item, index) => {
          arr.push(item.title);
        });
        arr.push(value);
        setValue("");
        let res = await updateProfile({ specialities: arr });
        if (res) {
          reloadData();
        }
      }
    } catch (e) {
      console.log(e);
    }
    setLoader(false);
  };

  return (
    <>
      <Loader show={loader} />
      <Modal
        show={show}
        onHide={closePopup}
        backdrop="static"
        keyboard={false}
        dialogClassName="docg-modal"
        size="md"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="mbsc-row">
            <div className="mbsc-col-12">
              <Listview
                stages={stages}
                theme="ios"
                itemType={(props) => (
                  <MyListItem
                    {...props}
                    handleEdit={handleEdit}
                    handleDelete={handleDelete}
                  />
                )}
                data={items}
              />
            </div>
            <div className="mbsc-col-12 d-flex align-items-end justify-content-center mt-2">
              <Input
                inputStyle="underline"
                labelStyle="floating"
                label={title}
                placeholder=""
                type="text"
                name="value"
                id="editInput"
                cssClass="w-100"
                value={value}
                onChange={(e) => setValue(e.target.value)}
                list="suggestions"
              />
              <datalist id="suggestions">
                {specializations.map((item) => (
                  <option
                    value={item}
                    onClick={() => {
                      setValue(item);
                      // setShowSpecializations(false);
                    }}
                  >
                    {item}
                  </option>
                ))}
              </datalist>
              <button onClick={handleChange} class="docg-save-btn m-0">
                Add
              </button>
            </div>
            <div className="mbsc-col-12">
              <span className="mbsc-error-message mbsc-ios">{error}</span>
            </div>
          </div>
          <div className="mbsc-col-12 d-flex align-items-end justify-content-center mt-2"></div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default DotorPopup;
