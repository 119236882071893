import React from "react";
import { Input } from "@mobiscroll/react5";

const PasswordFloating = () => {
  return (
    <Input
      inputStyle="underline"
      labelStyle="floating"
      label="Label"
      passwordToggle={true}
      placeholder="Username"
      name="username"
    />
  );
};

export default PasswordFloating;
