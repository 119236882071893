import React from "react";

const Switch = () => {
  return (
    <div>
      <label class="switch">
        <input type="checkbox" defaultChecked />
        <span class="slider slider-po-show round"></span>
      </label>
      <br />
      <label class="switch">
        <input type="checkbox" />
        <span class="custom-slider slider slider-po-show round"></span>
      </label>
      <br />
      <label class="switch docg-vaccination-switch">
        <input type="checkbox" defaultChecked />
        <span class="slider slider-po-show round"></span>
      </label>
    </div>
  );
};

export default Switch;
