import React from "react";
import { Textarea } from "@mobiscroll/react5";

export default function () {
  return (
    <div className="textarea-wrap">
      {/* label  */}
      <span>Label</span>
      {/* textarea */}
      <Textarea
        name="observation"
        inputStyle="underline"
        className="observation"
      ></Textarea>
    </div>
  );
}
