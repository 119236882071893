import {
  LIST_TODAY_APPOINTMENTS,
  GET_DOCTOR,
} from "src/components/graphql/GraphQLquery";
import {
  getQueryWithParams,
  getQuery,
  postMutation,
} from "src/components/ApolloConnection/ApolloProviderComponent";
import { snackbar } from "@mobiscroll/react5";

export async function listAppointments(doctorIds, take, start, end, filters) {
  console.log(doctorIds, take, start, end, filters);
  try {
    const response = await getQueryWithParams(
      LIST_TODAY_APPOINTMENTS(take, start, end, filters),
      doctorIds
    );
    // let response = await getQuery(GET_DOCTOR(false, "", "", ""));

    console.log(response);
    if (typeof response == "undefined") {
      throw new Error("error", response);
    } else {
      if (response?.errors) {
        throw new Error("error", response);
      } else if (
        response?.data?.listClinicAppointments &&
        response?.data?.listClinicAppointments?.data
      ) {
        snackbar({
          message: "List of Appointments fetched successfully.",
          color: "success",
        });
        return response?.data?.listClinicAppointments;
      } else {
        throw new Error("error", response);
      }
    }
  } catch (e) {
    // snackbar({
    //   message: "Some error occured. Try again",
    //   color: "danger",
    // });
    console.log("Error at Listing Appointments: ", e);
    return null;
  }
}
